import React, {Component} from 'react';

import DateFnsUtils from '@date-io/date-fns';
import {Card, Container, Grid, FormControl, Select, MenuItem, TextField, Button, Paper, Dialog, FormHelperText, Box} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CompareArrowsIcon from '@material-ui/icons/SwapHoriz';
import {Autocomplete} from '@material-ui/lab';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import {format} from 'date-fns';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';

import {
  CURRENCY,
  PAYMENT_TRANSFER_STATUS,
  DATE_FORMAT,
  PAYMENT_METHOD_TRANSFER,
  SIMULATION_STATUS,
  LANGUAGE,
  PLAN_CATEGORIES,
  TICKET_AMOUNT_TYPE,
  MAX_SIZE_OUTPUT,
} from '../../../common/constant';
import MessageContentModal from '../../../components/messageContentModal';
import SelectModal from '../../../components/selectModal';
import withPermissionGateway from '../../../hoc/withPermissionGateway';
import {getMemberDetailApi} from '../../../services/memberServices';
import {getNumberSubByUser, checkOwnerCard, searchListPlan, getDetailPlan} from '../../../stores/business/action';
import {registerSubscription} from '../../../stores/business/action';
import {getAllSubscriptionGeofence, getAllCountryCode} from '../../../stores/common/actions';
import {setMessageModal} from '../../../stores/modal/actions';
import {onChangeSelect, onChangeTextField, preventInvalidChars} from '../../../utils/common';
import {convertDatetimeUTC} from '../../../utils/datetime';
import {modalObj} from '../../../utils/modal';
import {isRoleGlobal} from '../../../utils/role';

const SEARCH_PLANS_PAGEABLE = {page: 0, size: MAX_SIZE_OUTPUT};

/**
 * Register subscription for users
 */
class Index extends Component {
  /**
   * constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      userId: '',
      countryId: '',
      geofenceId: '',
      planId: '',
      currency: '',
      lastName: '',
      firstName: '',
      lastNameFurigana: '',
      firstNameFurigana: '',
      birthday: new Date(),
      email: '',
      mobile: '',
      amount: '0',
      max_transfer_days: '0',
      numberOfUnit: '',
      numberOfUser: '1',
      minStartTime: new Date(),
      startTime: new Date(),
      extraFee: '0',
      familyOptionAmount: '0',
      totalAmount: '0',
      paymentMethod: '',
      paymentTransferStatus: 'NOT_TRANSFER',
      reminder_days: '0',
      limitFamilyUser: '',
      zoneId: '',
      userCode: '',
      registerTime: 0,

      plan_category: '',
      amount_type: '',
      ride_limit: '0',

      listPlan: [],
      listGeofences: [],
      isSubmitForm: false,
      openModal: false,
      loadingStatus: false,
      useTime: {
        start_time: '',
        end_time: '',
      },
      paymentMethodPlan: [],
      hasCreditCard: false,
      canTransfer: false,
      autoRenewable: '',
      autoRenewableDetail: true,
      flagErrorModal: false,
      dataErrorModal: [],
    };
    this.validator = new SimpleReactValidator();
  }

  /**
   * resetState
   */
  resetState = () => {
    this.setState({
      planId: '',
      currency: '',
      amount: '0',
      totalAmount: '0',
      limitFamilyUser: '',
      numberOfUnit: '',
      extraFee: '0',
      useTime: {
        start_time: '',
        end_time: '',
      },
      paymentMethod: '',
      paymentTransferStatus: 'NOT_TRANSFER',
      reminder_days: '0',
      max_transfer_days: '0',
      numberOfUser: '1',
      canTransfer: false,
      zoneId: '',
      paymentMethodPlan: [],
      amount_type: '',
      ride_limit: '0',
      hasCreditCard: false,
      autoRenewable: '',
    });
  }

  /**
   * onChangeCountry
   * @param {event} e
   */
  onChangeCountry = (e) => {
    this.setState({
      countryId: e.target.value,
      geofenceId: '',
      planId: '',
      plan_category: '',
      listGeofences: this.props.common?.all_subscription_geofence.filter((geofence) => geofence.country_id === e.target.value),
    });
    this.resetState();
  };

  /**
   * onChangeGeofence
   * @param {event} event
   */
  onChangeGeofence = (event) => {
    this.setState({
      geofenceId: event.target.value,
      planId: '',
    });
    this.resetState();
    if (this.state.plan_category) {
      this.props.searchListPlan({
        country_id: this.state.countryId,
        geofence_id: event.target.value,
        plan_type: this.state.plan_category,
      }, SEARCH_PLANS_PAGEABLE).then((response) => this.setState({listPlan: response?.content.filter((item) => !item.is_gift)}));
    }
  };

  /**
   * onChangePlanCategory
   * @param {event} event
   */
  onChangePlanCategory = (event) => {
    this.setState({
      plan_category: event.target.value,
      planId: '',
    });
    this.resetState();
    if (this.state.geofenceId) {
      this.props.searchListPlan({
        country_id: this.state.countryId,
        geofence_id: this.state.geofenceId,
        plan_type: event.target.value,
      }, SEARCH_PLANS_PAGEABLE).then((response) => this.setState({listPlan: response?.content.filter((item) => !item.is_gift)}));
    }
  }

  /**
   * onChangePlan
   * @param {Number} planId
   */
  onChangePlan = async (planId) => {
    this.props.getDetailPlan(planId).then(async (response) => {
      await this.props.getNumberSubByUser({
        plan_id: this.state.listPlan?.find((item) => item.id === planId)?.id,
        user_id: this.state.userId,
      }).then((res) => this.setState({registerTime: res}));
      const {registerTime} = this.state;
      const lastAmountIndex = response?.plan_prices.length - 1;
      const amount = response?.plan_prices[registerTime]?.amount || response?.plan_prices[registerTime]?.amount === 0 ?
        response?.plan_prices[registerTime]?.amount : response?.plan_prices[lastAmountIndex]?.amount;
      const extraFee = (response?.plan_prices[registerTime]?.extra_fee || response?.plan_prices[registerTime]?.extra_fee === 0) ?
        response?.plan_prices[registerTime]?.extra_fee : response?.plan_prices[lastAmountIndex]?.extra_fee;
      await this.setState({
        planId: planId,
        currency: response?.plan_prices[registerTime]?.currency_code || response?.plan_prices[lastAmountIndex]?.currency_code,
        amount,
        totalAmount: amount,
        limitFamilyUser: response.limit_family_user,
        numberOfUnit: response.number_of_unit,
        extraFee,
        useTime: response.use_time,
        paymentMethod: response.banks.length > 0 ? 2 : '',
        paymentTransferStatus: 'NOT_TRANSFER',
        max_transfer_days: response.max_transfer_days,
        reminder_days: response.reminder_days,
        numberOfUser: '1',
        canTransfer: response.banks.length > 0,
        zoneId: response.zone_id,
        paymentMethodPlan: response.payment_methods,
        amount_type: response?.ticket_data?.ticket_type,
        ride_limit: response?.ticket_data?.max_number_of_rides,
        autoRenewable: response?.auto_renew || false,
        autoRenewableDetail: response?.auto_renew || false,
      });
      if (this.state.totalAmount === 0) this.setState({paymentTransferStatus: 'TRANSFERRED'});
      this.checkOwnerCard();
    });
  };

  /**
   * onChangeNumberOfUser
   * @param {event} e
   */
  onChangeNumberOfUser = (e) => {
    const numberOfUser = e.target.value;
    let {paymentTransferStatus, extraFee, amount, limitFamilyUser} = this.state;
    if ((numberOfUser > limitFamilyUser || numberOfUser <= 0) && String(numberOfUser) !== '') return;
    const familyOptionAmount = this.customRound(numberOfUser !== '' ? (Number(numberOfUser) - 1) * Number(extraFee) : 0);
    const totalAmount = this.customRound(numberOfUser !== '' ? familyOptionAmount + Number(amount) : amount);
    paymentTransferStatus = totalAmount === 0 ? 'TRANSFERRED' : 'NOT_TRANSFER';
    this.setState({numberOfUser: Number(numberOfUser), familyOptionAmount, totalAmount, paymentTransferStatus});
  };

  /**
   * Custom round
   * @param {Number} value
   * @param {Number} digits
   * @return {Number}
   */
  customRound = (value, digits = 2) => {
    const powerOfTenStr = '1' + '0'.repeat(digits);
    const powerOfTen = Number(powerOfTenStr);
    return Math.round(value * powerOfTen) / powerOfTen;
  }

  /**
   * checkOwnerCard
   */
  checkOwnerCard = () => {
    this.props.checkOwnerCard(this.state.userId).then((response) => {
      let hasCreditCard = false;
      if (response['2c2p_cards']?.length > 0) {
        (this.state.countryId === 4) && (hasCreditCard = true); // Thẻ 2c2p dùng ở Singapore
      }
      if (response['2c2p_my_cards']?.length > 0) {
        (this.state.countryId === 5) && (hasCreditCard = true); // Thẻ 2c2p dùng ở Malaysia
      }
      if (response['veritrans_cards']?.length > 0) {
        (this.state.countryId === 2) && (hasCreditCard = true); // Thẻ veritrans chỉ dùng ở Nhật
      }
      if (response['alepay_cards']?.length > 0) {
        (this.state.countryId === 1) && (hasCreditCard = true); // Thẻ alepay chỉ dùng ở Việt Nam
      }
      this.setState({hasCreditCard});
      this.state.paymentMethodPlan.find((e) => e === PAYMENT_METHOD_TRANSFER[0].value) && hasCreditCard && this.setState({paymentMethod: 1});
    });
  };

  /**
   * checkStartTime
   * @return {Boolean}
   */
  checkStartTime = () => {
    const {startTime, useTime} = this.state;
    if (!startTime || String(startTime) === 'Invalid Date') return true;
    return format(new Date(startTime), DATE_FORMAT) <= format(new Date(useTime.end_time), DATE_FORMAT) &&
            format(new Date(startTime), DATE_FORMAT) >= format(new Date(useTime.start_time), DATE_FORMAT);
  };

  /**
   * Handle change start time
   * @param {Date} startTime
   */
  handleChangeStartTime = (startTime) => {
    if (!startTime || String(startTime) === 'Invalid Date') {
      this.setState({startTime});
      return;
    };
    const localTime = convertDatetimeUTC(startTime.toISOString(), this.state.zoneId);
    const nextStartTime = new Date(localTime);
    nextStartTime.setHours(0, 0, 0, 0);
    this.setState({startTime: nextStartTime});
  }

  /**
   * checkMinStartTime
   * @return {Boolean}
   */
  checkMinStartTime = () => {
    const {startTime} = this.state;
    if (!startTime || String(startTime) === 'Invalid Date') return true;
    if (
      parseInt(startTime.getTime()) > parseInt(new Date().getTime()) ||
      (
        startTime.getDate() === new Date().getDate() &&
        startTime.getMonth() === new Date().getMonth() &&
        startTime.getFullYear() === new Date().getFullYear()
      )
    ) return true;
    return false;
  }

  /**
   * Get plan options by language code
   * @param {String} languageCode
   * @return {Array}
   */
  getPlanOptionsByLanguage = (languageCode) => {
    return this.state.listPlan?.map(({plan_languages, id}) => {
      const languageId = LANGUAGE.find(({code}) => code === languageCode).id;
      return {
        id,
        name: plan_languages?.find(({language_id}) => language_id === languageId)?.name || '',
      };
    });
  }

  /**
   * componentDidMount
   */
  componentDidMount = async () => {
    const userId = this.props.location.search.split('=')[1];
    this.props.getAllCountryCode();
    this.props.getAllSubscriptionGeofence();
    if (userId) {
      await getMemberDetailApi(userId).then(
        (response) => {
          if (response.status === 200) {
            const user = response.result;
            this.setState({
              userId: userId,
              lastName: user.last_name,
              firstName: user.first_name,
              lastNameFurigana: user.last_name_furigana,
              firstNameFurigana: user.first_name_furigana,
              birthday: user.birthday || null,
              email: user.email,
              mobile: user.mobile,
              userCode: user.member_id,
            });
          }
        },
        () => this.props.history.goBack(),
      );
    }
    if (!isRoleGlobal()) {
      this.setState({
        countryId: this.props.common.principal?.country_id,
        listGeofences: this.props.common?.all_subscription_geofence,
      });
    }
  };

  /**
   * openConfirmModal
   */
  openConfirmModal = () => {
    if (this.state.numberOfUser === '') this.setState({numberOfUser: 1});
    this.setState({isSubmitForm: true});
    if (
      this.validator.allValid() &&
      this.state.startTime &&
      String(this.state.startTime) !== 'Invalid Date' &&
      this.checkStartTime() &&
      this.checkMinStartTime()
    ) {
      this.setState({openModal: true});
    }
  };

  /**
   * handleButtonModalOk
   */
  handleButtonModalOk = async () => {
    this.handleButtonModalCancel();
    const {startTime, paymentMethod, planId, numberOfUser, max_transfer_days, reminder_days, autoRenewable, totalAmount} = this.state;
    let {paymentTransferStatus} = this.state;
    const payload = {
      user_id: this.props.location.search.split('=')[1],
      start_time:
        startTime.getFullYear() + '-' + (startTime.getMonth() < 9 ? '0' : '') + (startTime.getMonth() + 1) + '-' + (startTime.getDate() < 10 ? '0' : '') + startTime.getDate(),
      payment_method: paymentMethod === 1 ? 'CREDIT_CARD' : 'TRANSFER',
      plan_id: planId,
      number_of_user_family: numberOfUser,
      auto_renewable: autoRenewable,
    };
    if (totalAmount === 0) paymentTransferStatus = 'TRANSFERRED';
    if (paymentMethod === 2) {
      payload.payment_transfer_status = paymentTransferStatus;
      payload.max_transfer_days = max_transfer_days;
      payload.reminder_days = reminder_days;
    }
    this.props.registerSubscription(payload, this.props).then((res) => {
      if (res?.status === 400) {
        if (res?.result) {
          this.setState({
            flagErrorModal: true,
            dataErrorModal: res?.result || [],
          });
        } else {
          this.props.setMessageModal(modalObj(true, res?.message));
        }
      }
    });
  };

  /**
   * handleButtonModalCancel
   */
  handleButtonModalCancel = () => {
    this.setState({openModal: false});
  };

  /**
   * render
   * @return {HTMLElement}
   */
  render() {
    const {t, common, business} = this.props;
    const {plan_category} = this.state;

    return (
      <LoadingOverlay active={this.state.loadingStatus || common?.isLoading || business?.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
        <Card className="main_card_min_size">
          <Container maxWidth="xl">
            <Grid container className="page_header">
              <Grid container alignItems="center" item xs={6}>
                <h3>{t('route.registerSubscription')}</h3>
              </Grid>
            </Grid>
          </Container>
          <br></br>
          <Container maxWidth="xl">
            <Card raised>
              <Container maxWidth="xl">
                <br></br>
                <Paper className="search_table">
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={12} lg={12} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                      {t('route.business_subscription')}
                    </Grid>
                  </Grid>
                  {/* Country */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('common.country')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                        <Select
                          margin="dense"
                          inputProps={{
                            name: 'countryId',
                          }}
                          displayEmpty
                          value={this.state.countryId || ''}
                          onChange={this.onChangeCountry}
                          renderValue={
                            this.state.countryId ?
                              undefined :
                              () => (
                                  <div className="font-12 color-disabled">
                                    {t('placeholder.required_select', {
                                      field: t('common.country'),
                                    })}
                                  </div>
                                )
                          }
                          disabled={!isRoleGlobal()}
                        >
                          {common?.country_code?.map((item, index) => (
                            <MenuItem value={item.id} key={index}>
                              {t(`${item.country_code}`)}
                            </MenuItem>
                          ))}
                        </Select>
                        {this.validator.message('countryId', this.state.countryId, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.countryId, 'required') && (
                          <FormHelperText id="countryId" error>
                            {t('validation.required.choose', {field: t('common.country')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Geofence */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('business_plan.geofence')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                        <Select
                          margin="dense"
                          inputProps={{
                            name: 'geofenceId',
                          }}
                          displayEmpty
                          value={this.state.geofenceId || ''}
                          onChange={this.onChangeGeofence}
                          disabled={!this.state.countryId}
                          renderValue={
                            this.state.geofenceId ?
                              undefined :
                              () => (
                                  <div className="font-12 color-disabled">
                                    {t('placeholder.required_select', {
                                      field: t('business_plan.geofence'),
                                    })}
                                  </div>
                                )
                          }
                        >
                          {this.state.listGeofences.map((item, index) => (
                            <MenuItem value={item.geofence_id} key={index} className={!item.enable ? 'disable-option-custom' : ''}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                        {this.validator.message('geofenceId', this.state.geofenceId, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.geofenceId, 'required') && (
                          <FormHelperText id="geofenceId" error>
                            {t('validation.required.choose', {field: t('business_plan.geofence')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Plan category */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('business_plan.category')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                        <Select
                          margin="dense"
                          inputProps={{
                            name: 'plan_category',
                          }}
                          displayEmpty
                          value={this.state.plan_category || ''}
                          onChange={this.onChangePlanCategory}
                          disabled={!this.state.countryId}
                          renderValue={
                            this.state.plan_category ?
                              undefined :
                              () => (
                                  <div className="font-12 color-disabled">
                                    {t('placeholder.required_select', {
                                      field: t('business_plan.category'),
                                    })}
                                  </div>
                                )
                          }
                        >
                          {PLAN_CATEGORIES.map((item, index) => (
                            <MenuItem value={item.value} key={index}>
                              {t(`${item.i18n}`)}
                            </MenuItem>
                          ))}
                        </Select>
                        {this.validator.message('plan_category', this.state.plan_category, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.plan_category, 'required') && (
                          <FormHelperText id="plan_category" error>
                            {t('validation.required.choose', {field: t('business_plan.category')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Plan Name */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('business_plan.package_name')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={2}>
                      <FormControl variant="outlined" margin="dense" fullWidth>
                        <Box display="flex" flexDirection="column">
                          <div style={{fontSize: 13, marginBottom: 8, color: '#3f51b5'}}>({t('common.ja')})</div>
                          <Autocomplete
                            options={this.getPlanOptionsByLanguage('ja')}
                            getOptionLabel={(option) => option.name}
                            getOptionSelected={(option, value) => option.id === value.id}
                            value={this.getPlanOptionsByLanguage('ja')?.find(({id}) => this.state.planId === id) || null}
                            onChange={(event, plan) => {
                              this.onChangePlan(plan.id);
                            }}
                            disabled={!this.state.geofenceId}
                            disableClearable
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant='outlined'
                                margin='dense'
                                placeholder={t('placeholder.required_select', {
                                  field: t('business_plan.package_name'),
                                })}
                              />
                            )}
                          />
                        </Box>
                      </FormControl>
                    </Grid>
                    <Grid container justify="center" alignItems="center" item xs={1}>
                      <CompareArrowsIcon className="compare_arrow_icon" style={{top: 10}}></CompareArrowsIcon>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={2}>
                      <FormControl variant="outlined" margin="dense" fullWidth>
                        <Box display="flex" flexDirection="column">
                          <div style={{fontSize: 13, marginBottom: 8, color: '#3f51b5'}}>({t('common.vi')})</div>
                          <Autocomplete
                            options={this.getPlanOptionsByLanguage('vi')}
                            getOptionLabel={(option) => option.name}
                            getOptionSelected={(option, value) => option.id === value.id}
                            value={this.getPlanOptionsByLanguage('vi')?.find(({id}) => this.state.planId === id) || null}
                            onChange={(event, plan) => {
                              this.onChangePlan(plan.id);
                            }}
                            disabled={!this.state.geofenceId}
                            disableClearable
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant='outlined'
                                margin='dense'
                                placeholder={t('placeholder.required_select', {
                                  field: t('business_plan.package_name'),
                                })}
                              />
                            )}
                          />
                        </Box>
                      </FormControl>
                    </Grid>
                    <Grid container justify="center" alignItems="center" item xs={1}>
                      <CompareArrowsIcon className="compare_arrow_icon" style={{top: 10}}></CompareArrowsIcon>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={2}>
                      <FormControl variant="outlined" margin="dense" fullWidth>
                        <Box display="flex" flexDirection="column">
                          <div style={{fontSize: 13, marginBottom: 8, color: '#3f51b5'}}>({t('common.en')})</div>
                          <Autocomplete
                            options={this.getPlanOptionsByLanguage('en')}
                            getOptionLabel={(option) => option.name}
                            getOptionSelected={(option, value) => option.id === value.id}
                            value={this.getPlanOptionsByLanguage('en')?.find(({id}) => this.state.planId === id) || null}
                            onChange={(event, plan) => {
                              this.onChangePlan(plan.id);
                            }}
                            disabled={!this.state.geofenceId}
                            disableClearable
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant='outlined'
                                margin='dense'
                                placeholder={t('placeholder.required_select', {
                                  field: t('business_plan.package_name'),
                                })}
                              />
                            )}
                          />
                        </Box>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} lg={2}></Grid>
                    <Grid item xs={6} lg={2}></Grid>
                    <Grid item xs={6} lg={9} className="grid_title_padding">
                      {this.validator.message('planId', this.state.planId, 'required')}
                      {this.state.isSubmitForm && !this.validator.check(this.state.planId, 'required') && (
                        <FormHelperText id="planId" error>
                          {t('validation.required.choose', {field: t('business_plan.package_name')})}
                        </FormHelperText>
                      )}
                    </Grid>
                  </Grid>

                  {/* Currency */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('business_plan.unit')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl variant="outlined" margin="dense">
                        <Select
                          variant="outlined"
                          className="field_size_20 field_min_size_300"
                          margin="dense"
                          inputProps={{
                            name: 'currency',
                          }}
                          displayEmpty
                          renderValue={
                            this.state.currency ?
                              undefined :
                              () => (
                                  <div className="font-12 color-disabled">
                                    {t('placeholder.required_select', {
                                      field: t('business_plan.unit'),
                                    })}
                                  </div>
                                )
                          }
                          disabled={true}
                          value={this.state.currency || ''}
                          onChange={(e) => onChangeSelect(this, e)}
                        >
                          {CURRENCY.map((item, index) => {
                            return (
                              <MenuItem value={item.id} key={index}>
                                {t(item.i18n)}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* First Name */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('sub.first_name')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl error>
                        <TextField
                          className="field_size_10 field_min_size_300"
                          margin="dense"
                          placeholder={t('placeholder.required', {field: t('sub.first_name')})}
                          variant="outlined"
                          value={this.state.firstName || ''}
                          disabled={true}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Last Name */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('sub.last_name')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl error>
                        <TextField
                          className="field_size_10 field_min_size_300"
                          margin="dense"
                          placeholder={t('placeholder.required', {field: t('sub.last_name')})}
                          variant="outlined"
                          value={this.state.lastName || ''}
                          disabled={true}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* First Name Furigana */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('sub.firstname_furigana')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl error>
                        <TextField
                          className="field_size_10 field_min_size_300"
                          margin="dense"
                          placeholder={t('placeholder.required', {field: t('sub.firstname_furigana')})}
                          variant="outlined"
                          value={this.state.firstNameFurigana || ''}
                          disabled={true}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Last Name Furigana */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('sub.lastname_furigana')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl error>
                        <TextField
                          className="field_size_10 field_min_size_300"
                          margin="dense"
                          placeholder={t('placeholder.required', {field: t('sub.lastname_furigana')})}
                          variant="outlined"
                          value={this.state.lastNameFurigana || ''}
                          disabled={true}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* birthday */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('memberManagement.birthDay')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl error margin='dense'>
                        <KeyboardDatePicker
                          className="field_min_size_300 field_size_20"
                          variant="inline"
                          disabled={true}
                          autoOk
                          inputVariant="outlined"
                          ampm={false}
                          label={t('memberManagement.birthDay')}
                          value={this.state.birthday}
                          format={DATE_FORMAT}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Mobile */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('common.phoneNumber')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl error>
                        <TextField className="field_size_10 field_min_size_300" margin="dense" disabled={true} variant="outlined" value={this.state.mobile || ''} />
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Email */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('business_plan.mail')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl error>
                        <TextField className="field_size_10 field_min_size_300" margin="dense" disabled={true} variant="outlined" value={this.state.email || ''} />
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Number of days */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('business_plan.number_day')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl error>
                        <TextField
                          className="field_size_10 field_min_size_300"
                          margin="dense"
                          inputProps={{
                            name: 'numberOfUnit',
                          }}
                          disabled={true}
                          placeholder={t('placeholder.required', {field: t('business_plan.number_day')})}
                          variant="outlined"
                          onChange={(event) => onChangeTextField(this, event)}
                          value={this.state.numberOfUnit || this.state.numberOfUnit === 0 ? this.state.numberOfUnit : ''}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Amount type */}
                  {plan_category === 'TICKET' && (
                    <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                      <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                        {t('ticket.amount_type')}
                      </Grid>
                      <Grid container alignItems="center" item xs={6} lg={4}>
                        <FormControl variant="outlined" margin="dense">
                          <Select
                            variant="outlined"
                            className="field_size_20 field_min_size_300"
                            margin="dense"
                            disabled={true}
                            displayEmpty
                            renderValue={
                              this.state.amount_type ?
                                undefined :
                                () => (
                                    <div className="font-12 color-disabled">
                                      {t('placeholder.required_select', {
                                        field: t('ticket.amount_type'),
                                      })}
                                    </div>
                                  )
                            }
                            value={this.state.amount_type || ''}
                          >
                            {TICKET_AMOUNT_TYPE.map((item, idx) => {
                              return (
                                <MenuItem value={item.value} key={idx}>
                                  {t(item.i18n)}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  )}

                  {/* Amount */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('business_plan.price')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl error>
                        <TextField
                          className="field_size_10 field_min_size_300"
                          margin="dense"
                          inputProps={{
                            name: 'amount',
                          }}
                          disabled={true}
                          placeholder={t('placeholder.required', {field: t('business_plan.price')})}
                          variant="outlined"
                          onChange={(event) => onChangeTextField(this, event)}
                          value={this.state.amount || this.state.amount === 0 ? this.state.amount : ''}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Ride limit */}
                  {plan_category === 'TICKET' && (
                    <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                      <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                        {t('ticket.ride_limit')}
                      </Grid>
                      <Grid container alignItems="center" item xs={6} lg={4}>
                        <FormControl className="field_min_size_300">
                          <TextField
                            margin="dense"
                            type="number"
                            placeholder={t('placeholder.required', {field: t('ticket.ride_limit')})}
                            variant="outlined"
                            disabled={true}
                            value={this.state.ride_limit || ''}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  )}

                  {/* Limit Family User */}
                  {plan_category === 'PLAN' && (
                    <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                      <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                        {t('sub.number_member')} <span className="font_color_red">＊</span>
                      </Grid>
                      <Grid container alignItems="center" item xs={6} lg={4}>
                        <FormControl error>
                          <TextField
                            className="field_size_10 field_min_size_300"
                            margin="dense"
                            inputProps={{
                              name: 'numberOfUser',
                              type: 'number',
                            }}
                            placeholder={t('placeholder.required', {field: t('sub.number_member')})}
                            variant="outlined"
                            onChange={this.onChangeNumberOfUser}
                            onKeyDown={preventInvalidChars}
                            value={this.state.numberOfUser || ''}
                            disabled={!this.state.planId}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  )}

                  {/* Start Time */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('sub.start_time')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            className="field_size_20 field_min_size_300"
                            margin="dense"
                            autoOk
                            label={t('sub.start_time')}
                            inputVariant="outlined"
                            format={DATE_FORMAT}
                            value={this.state.startTime || ''}
                            onChange={this.handleChangeStartTime}
                            KeyboardButtonProps={{
                              'aria-label': 'change time',
                            }}
                            minDate={new Date()}
                            invalidDateMessage={t('errorFields.invalidDateMessage')}
                            maxDateMessage={t('errorFields.maxDateMessage')}
                            minDateMessage={t('errorFields.minDateMessage')}
                            disabled={!this.state.planId}
                          />
                        </MuiPickersUtilsProvider>
                        <Grid container spacing={1}>
                          {this.state.isSubmitForm && this.state.planId && !this.checkStartTime() && (
                            <Grid container style={{paddingBottom: 0}} alignItems="center" item xs={12}>
                              <FormHelperText id="startTime" error>
                                {t('business_plan.validate_start_time_use_time')}
                              </FormHelperText>
                            </Grid>
                          )}
                        </Grid>
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Family Option Amount */}
                  {plan_category === 'PLAN' && (
                    <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                      <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                        {t('sub.family_option_amount')}
                      </Grid>
                      <Grid container alignItems="center" item xs={6} lg={4}>
                        <FormControl error>
                          <TextField
                            className="field_size_10 field_min_size_300"
                            margin="dense"
                            inputProps={{
                              name: 'familyOptionAmount',
                            }}
                            disabled={true}
                            variant="outlined"
                            value={this.state.familyOptionAmount || this.state.familyOptionAmount === 0 ? this.state.familyOptionAmount : ''}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  )}

                  {/* Total Amount */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('sub.total_amount')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl error>
                        <TextField
                          className="field_size_10 field_min_size_300"
                          margin="dense"
                          inputProps={{
                            name: 'totalAmount',
                          }}
                          disabled={true}
                          variant="outlined"
                          value={this.state.totalAmount || this.state.totalAmount === 0 ? this.state.totalAmount : ''}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Payment Method */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('common.paymentMethod')}
                      <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                        <Select
                          variant="outlined"
                          className="field_size_20 field_min_size_300"
                          margin="dense"
                          inputProps={{
                            name: 'paymentMethod',
                          }}
                          disabled={!this.state.planId}
                          displayEmpty
                          renderValue={
                            this.state.paymentMethod ?
                              undefined :
                              () => (
                                  <div className="font-12 color-disabled">
                                    {t('placeholder.required_select', {
                                      field: t('common.paymentMethod'),
                                    })}
                                  </div>
                                )
                          }
                          value={this.state.paymentMethod}
                          onChange={(event) => onChangeSelect(this, event)}
                        >
                          {PAYMENT_METHOD_TRANSFER.map((item, idx) => {
                            return (
                              ((item.id === 1 && this.state.hasCreditCard && this.state.paymentMethodPlan.find((e) => e === PAYMENT_METHOD_TRANSFER[0].value)) ||
                                (item.id === 2 && this.state.canTransfer)) && (
                                <MenuItem value={item.id} key={idx}>
                                  {t(item.i18n)}
                                </MenuItem>
                              )
                            );
                          })}
                        </Select>
                        {this.validator.message('paymentMethod', this.state.paymentMethod, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.paymentMethod, 'required') && (
                          <FormHelperText id="paymentMethod" error>
                            {t('validation.required.choose', {field: t('common.paymentMethod')})}
                          </FormHelperText>
                        )}
                        {!this.state.hasCreditCard && !this.state.canTransfer && this.state.planId && (
                          <>
                            <br />
                            <FormHelperText error>{t('messageCode.cantTransferAndNoneCreditCard')}</FormHelperText>
                          </>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  {this.state.paymentMethod === 2 && (
                    <>
                      {/* Payment Status Transfer */}
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('businessPartner.payment_transfer_status')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                            <Select
                              variant="outlined"
                              className="field_size_20 field_min_size_300"
                              margin="dense"
                              inputProps={{
                                name: 'paymentTransferStatus',
                              }}
                              disabled={!this.state.planId || (!this.state.limitFamilyUser && this.state.plan_category === 'PLAN') || this.state.totalAmount === 0}
                              displayEmpty
                              renderValue={
                                this.state.paymentTransferStatus ?
                                  undefined :
                                  () => (
                                      <div className="font-12 color-disabled">
                                        {t('placeholder.required_select', {
                                          field: t('businessPartner.payment_transfer_status'),
                                        })}
                                      </div>
                                    )
                              }
                              value={this.state.paymentTransferStatus}
                              onChange={(event) => onChangeSelect(this, event)}
                            >
                              {PAYMENT_TRANSFER_STATUS.filter((item) => item.id !== 'CANCEL' && item.id !== 'REFUNDED').map((item, idx) => {
                                return (
                                  <MenuItem value={item.id} key={idx}>
                                    {t(item.i18n)}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>

                      {/* Transfer Deadline (Max Transfer Day) */}
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('business_plan.max_transfer_days')} <span className="font_color_red">＊</span>
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <FormControl error>
                            <TextField
                              className="field_size_10 field_min_size_300"
                              margin="dense"
                              inputProps={{
                                name: 'max_transfer_days',
                              }}
                              placeholder={t('placeholder.required', {field: t('business_plan.max_transfer_days')})}
                              variant="outlined"
                              value={this.state.max_transfer_days || this.state.max_transfer_days === 0 ? this.state.max_transfer_days : ''}
                              disabled={true}
                            />
                            {this.validator.message('max_transfer_days', this.state.max_transfer_days, 'required')}
                            {this.state.isSubmitForm && !this.validator.check(this.state.max_transfer_days, 'required') && (
                              <FormHelperText id="max_transfer_days" error>
                                {t('validation.required', {field: t('business_plan.max_transfer_days')})}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                      </Grid>

                      {/* Reminder Day */}
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('sub.reminder_days')} <span className="font_color_red">＊</span>
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <FormControl error>
                            <TextField
                              className="field_size_10 field_min_size_300"
                              margin="dense"
                              inputProps={{
                                name: 'reminder_days',
                                type: 'number',
                              }}
                              placeholder={t('placeholder.required', {field: t('sub.reminder_days')})}
                              variant="outlined"
                              onChange={(e) => onChangeTextField(this, e)}
                              onKeyDown={preventInvalidChars}
                              value={this.state.reminder_days || this.state.reminder_days === 0 ? this.state.reminder_days : ''}
                              disabled={true}
                            />
                            {this.validator.message('reminder_days', this.state.reminder_days, 'required')}
                            {this.state.isSubmitForm && !this.validator.check(this.state.reminder_days, 'required') && (
                              <FormHelperText id="reminder_days" error>
                                {t('validation.required', {field: t('sub.reminder_days')})}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                      </Grid>
                    </>
                  )}

                  {/* Automatic updating */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('sub.automatic_update')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl variant="outlined" margin="dense">
                        <Select
                          variant="outlined"
                          className="field_size_20 field_min_size_300"
                          margin="dense"
                          disabled={!this.state.paymentMethod || !this.state.autoRenewableDetail}
                          value={this.state.autoRenewable}
                          onChange={(event) => onChangeSelect(this, event)}
                          name="autoRenewable"
                        >
                          {SIMULATION_STATUS.map((item, idx) => (
                            <MenuItem value={item.id} key={idx}>
                              {t(item.i18n)}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Paper>
                <br></br>
                <Grid container spacing={1}>
                  <Grid container alignItems="center" justify="flex-start" item xs={3}></Grid>
                  <Grid container alignItems="center" justify="flex-end" item xs={9}>
                    <Button color="primary" variant="contained" className="button_magin button_color_green" endIcon={<AddIcon />} onClick={() => this.openConfirmModal()}>
                      {t('common.btnRegister')}
                    </Button>
                    <Button color="primary" variant="contained" className="button_magin button_color" style={{marginLeft: 5}} onClick={() => this.props.history.goBack()}>
                      {t('common.btnReturn')}
                    </Button>
                  </Grid>
                </Grid>
                <Dialog
                  open={this.state.openModal}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: '1020',
                  }}
                >
                  <SelectModal
                    okButtonText={`${t('common.btnSend')}`}
                    cancelButtonText={`${t('common.btnReturn')}`}
                    onClickOk={this.handleButtonModalOk}
                    onClickCancel={this.handleButtonModalCancel}
                    message={t('business_plan.confirm_create')}
                  ></SelectModal>
                </Dialog>
                <Dialog
                  open={this.state.flagErrorModal}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: '1020',
                  }}
                  maxWidth={'md'}
                >
                  <MessageContentModal
                    message={'ticket.sub.different_discount_amount_header'}
                    data={this.state.dataErrorModal}
                    messageData={'ticket.sub.different_discount_amount_end'}
                    closeMessage={() => this.setState({flagErrorModal: false, data: []})}
                    isSub={true}
                  ></MessageContentModal>
                </Dialog>
              </Container>
              <br></br>
            </Card>
            <br></br>
          </Container>
          <br></br>
        </Card>
      </LoadingOverlay>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
    business: state.business,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllCountryCode: () => dispatch(getAllCountryCode()),
    getAllSubscriptionGeofence: () => dispatch(getAllSubscriptionGeofence()),
    registerSubscription: (payload, props) => dispatch(registerSubscription(payload, props)),
    getNumberSubByUser: (payload) => dispatch(getNumberSubByUser(payload)),
    checkOwnerCard: (userId) => dispatch(checkOwnerCard(userId)),
    searchListPlan: (payload, pageable) => dispatch(searchListPlan(payload, pageable)),
    getDetailPlan: (id) => dispatch(getDetailPlan(id)),
    setMessageModal: (params) => dispatch(setMessageModal(params)),
  };
};

export default withPermissionGateway(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index)));
