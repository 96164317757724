import React, {Component} from 'react';

import 'date-fns';
import {
  Card,
  Container,
  Grid,
  FormControl,
  Select,
  MenuItem,
  TextField,
  Button,
  Paper,
  Dialog,
  FormHelperText,
  FormGroup,
  Checkbox,
  FormControlLabel,
  Table,
  TableRow,
  TableContainer,
  TableHead,
  TableCell,
  TableBody,
  Modal,
  Backdrop,
  Box,
  Switch,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CancelIcon from '@material-ui/icons/Cancel';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import RemoveIcon from '@material-ui/icons/Remove';
import CompareArrowsIcon from '@material-ui/icons/SwapHoriz';
import {KeyboardDatePicker} from '@material-ui/pickers';
import {add} from 'date-fns/esm';
import moment from 'moment';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';

import SelectBanking from './banking_select/index';
import {
  ROUTE,
  PLAN_TYPE,
  CURRENCY,
  TIME_FORMAT,
  HAS_FAMILY_OPTIONS,
  FORMAT_PATTERN_DATE_PICKER,
  DATE_FORMAT,
  PAYMENT_METHOD_TRANSFER,
  PERMISSION_ACTIONS,
  RESERVATION_MOBI_TYPE,
  LANGUAGE,
  PLAN_CATEGORIES,
  TICKET_AMOUNT_TYPE,
  COMMON_STATUS,
  ROWS_PER_PAGE_OPTIONS,
  MAX_SIZE_OUTPUT,
  ZONE_ID_MINUS,
} from '../../../common/constant';
import CustomTimePicker from '../../../components/CustomTimePicker';
import ImageSelect from '../../../components/imageSelect';
import Memo from '../../../components/memo';
import OneTimeDiscount from '../../../components/OneTimeDiscount';
import SelectModal from '../../../components/selectModal';
import withPermissionGateway from '../../../hoc/withPermissionGateway';
import {searchListPlanApi, getCompaniesByCountryApi, searchReferrersApi} from '../../../services/businessServices';
import {createPlan, getDetailPlan, updatePlan} from '../../../stores/business/action';
import {getListGeofenceByCountry, getAllCountryCode, getServiceTypes, getListGeofenceDetailsByIds} from '../../../stores/common/actions';
import {getAllGeofences} from '../../../stores/geofence/action';
import {searchListImage} from '../../../stores/image/action';
import {setMessageModal} from '../../../stores/modal/actions';
// eslint-disable-next-line max-len
import {onChangeSelect, onChangeTextField, onChangeListData, preventInvalidChars, onChangeTextFieldNumber, backForwardRouter, onChangeCheckBox, onChangeNumberCurrency, formatNumber2DecimalDigits, getListAllGeofenceDetail} from '../../../utils/common';
import {formatLocalToHourMinute, formatHourMinuteToLocal, formatUtc} from '../../../utils/date_time_utils';
import {isOverlapDateRelatively, compareDateTimeRange} from '../../../utils/datetime';
import {modalObj} from '../../../utils/modal';
import './style.css';
import {isRoleBusiness, isRoleGlobal} from '../../../utils/role';

const DATA_DEFAULT_ONE_TIME_DESCRIPTION = {
  en: 'Use one ticket and get a discount for one-time paying passengers',
  vi: 'Sử dụng 1 vé và hành khách đi cùng sẽ được áp dụng giảm giá',
  jp: '1枚ご利用で、一回払いの同乗者を割引きします',
};
/**
 * BusinessPlanInformation
 */
class BusinessPlanInformation extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      country_id: !isRoleGlobal() ? props.principal.country_id : '',
      listGeofenceByCountry: [],
      company_id: '',
      list_area_id: [],
      type: PLAN_TYPE[0].id,
      code: '',
      name_jp: '',
      name_vi: '',
      name_en: '',
      number_of_unit: '',
      max_user: '',
      freeStartingTime: '',
      currency: '',
      price: [0],
      amount: 0,
      listPlanTime: [{start_time: '', end_time: ''}],
      display_times: [{start_time: '', end_time: ''}],
      overlapList: new Set(),
      overlapListDate: new Set(),
      extra_fee: [0],
      show_for_app: '',
      main_plan: '',
      memos: [],
      note: '',
      description_jp: '',
      description_vi: '',
      description_en: '',
      isShowPromoApp: false,
      promotion_title_jp: '',
      promotion_title_vi: '',
      promotion_title_en: '',
      promotion_description_jp: '',
      promotion_description_vi: '',
      promotion_description_en: '',
      one_time_discount_description_jp: DATA_DEFAULT_ONE_TIME_DESCRIPTION.jp,
      one_time_discount_description_vi: DATA_DEFAULT_ONE_TIME_DESCRIPTION.vi,
      one_time_discount_description_en: DATA_DEFAULT_ONE_TIME_DESCRIPTION.en,
      oneTimeDiscountStatus: 'DISABLE',
      isOpenModalImage: false,
      image_master_url: '',
      dataDiscount: [],
      isDisableCreate: false,
      use_time: {start_time: '', end_time: ''},
      data: null,
      max_transfer_days: '',
      reminder_days: '',
      payment_method: [1],
      serviceTypeIds: [],
      listBankAccount: [],
      bank_account_ids: [],
      dataShowMainPlan: '',
      zone_id: '',
      for_gift: false,

      list_geofences: [],
      list_companies: [],
      flg: false,
      isModalBanking: false,
      isMessageShowPlan: false,
      is_company_adding_enable: true,
      plan_category: '',
      automatic_update: true,
      amount_type: '',
      plan_languages: [],
      ride_limit: '',
      ticket_additional_ride_limit: [],
      ticket_additional_amount: [],
      plan_referrers: [],
      isSubmitModalDiscount: false,
      checkDisable: false,
      dataDetail: [],
    };
    this.validator = new SimpleReactValidator();
  }

  /**
   * componentDidMount
   */
  async componentDidMount() {
    const geofenceQueryParams = {page: 0, size: MAX_SIZE_OUTPUT};
    await this.props.getAllCountryCode();
    await this.props.getServiceTypes();
    await this.props.getAllGeofences(geofenceQueryParams);
    this.state.country_id && this.onChangeCountry(this.state.country_id);
    const queryParams = {
      name: 'one_time_discount_icon',
      currentPage: 0,
      rowsPerPage: ROWS_PER_PAGE_OPTIONS[0],
    };
    await this.props.searchListImage(queryParams).then((res) => {
      this.setState({
        image_master_url: res?.content[0]?.url,
      });
    });
    if (this.props.match.params.id) {
      this.props.getDetailPlan(this.props.match.params.id).then(
        async (result) => {
          if (result) {
            const currencyCode = CURRENCY.find((c) => c.countryId === result?.country_id)?.id;
            this.setState({
              data: result,
              country_id: result?.country_id,
              list_area_id: result?.geofence_ids,
              type: PLAN_TYPE[0]?.id,
              name_jp: result?.plan_languages?.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'ja')?.id)?.name || '',
              name_vi: result?.plan_languages?.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'vi')?.id)?.name || '',
              name_en: result?.plan_languages?.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'en')?.id)?.name || '',
              code: result?.code,
              number_of_unit: result?.number_of_unit,
              max_user: result?.limit_family_user,
              freeStartingTime: result?.trial_time,
              currency: result?.plan_prices[0]?.currency_code,
              price: result?.plan_prices?.map((item) => item.amount) || [0],
              amount: formatNumber2DecimalDigits(result?.plan_prices?.map((item) => item.amount)[0] || 0, currencyCode),
              extra_fee: result?.plan_prices?.map((item) => item.extra_fee) || [0],
              listPlanTime: result?.plan_shifts.length > 0 ? this.formatDataHourToDate(result?.plan_shifts, result?.zone_id) : [],
              show_for_app: result?.show_for_app === true ? 'true' : 'false',
              memos: result?.response_memos,
              main_plan: result?.main_plan === true ? 'true' : 'false',
              description_jp: result?.plan_languages?.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'ja')?.id)?.description || '',
              description_vi: result?.plan_languages?.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'vi')?.id)?.description || '',
              description_en: result?.plan_languages?.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'en')?.id)?.description || '',
              display_times:
                result?.display_times && result?.display_times.length > 0 ? this.formatDataDetailsUseTime(result.display_times[0], result.zone_id, true) :
                  [{start_time: '', end_time: ''}],
              use_time:
                result?.use_time && result?.use_time?.start_time && result?.use_time?.end_time ? this.formatDataDetailsUseTime(result.use_time, result.zone_id) :
                  {start_time: '', end_time: ''},
              listBankAccount: result?.banks,
              reminder_days: result?.reminder_days,
              max_transfer_days: result?.max_transfer_days,
              bank_account_ids: result?.banks?.map(({id}) => id),
              payment_method: result?.payment_methods !== null ? result?.payment_methods.map((item) => PAYMENT_METHOD_TRANSFER.find((item2) => item2.value === item)?.id) : [],
              zone_id: result?.zone_id,
              serviceTypeIds: result?.service_type_ids || [],
              is_company_adding_enable: result?.is_company_adding_enable,
              company_id: result?.company_id,
              plan_category: result?.plan_type,
              amount_type: result?.ticket_data?.ticket_type || '',
              ride_limit: result?.ticket_data?.max_number_of_rides || '',
              automatic_update: result?.auto_renew || false,
              for_gift: result?.is_gift || false,
              plan_languages: result?.plan_languages || [],
              ticket_additional_ride_limit: result?.ticket_data?.rides_additional?.map((item) => item.additional_rides) || [],
              ticket_additional_amount: result?.ticket_data?.rides_additional?.map((item) => item.additional_price) || [],
              isShowPromoApp: result?.show_promotion_for_app || false,
              promotion_title_en: result?.plan_languages?.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'en')?.id)?.promotion_title || '',
              promotion_title_jp: result?.plan_languages?.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'ja')?.id)?.promotion_title || '',
              promotion_title_vi: result?.plan_languages?.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'vi')?.id)?.promotion_title || '',
              promotion_description_en: result?.plan_languages?.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'en')?.id)?.promotion_description || '',
              promotion_description_jp: result?.plan_languages?.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'ja')?.id)?.promotion_description || '',
              promotion_description_vi: result?.plan_languages?.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'vi')?.id)?.promotion_description || '',
              one_time_discount_description_en: result?.plan_discount?.discount_description?.en || DATA_DEFAULT_ONE_TIME_DESCRIPTION.en,
              one_time_discount_description_jp: result?.plan_discount?.discount_description?.ja || DATA_DEFAULT_ONE_TIME_DESCRIPTION.jp,
              one_time_discount_description_vi: result?.plan_discount?.discount_description?.vi || DATA_DEFAULT_ONE_TIME_DESCRIPTION.vi,
              image_master_url: result?.plan_discount?.discount_icon_url || this.state.image_master_url,
              dataDiscount: result?.plan_discount?.discount_details || [],
              oneTimeDiscountStatus: result?.plan_discount ? 'ENABLE' : 'DISABLE',
              dataDetail: result,
            });
            if (isRoleBusiness()) {
              const {principal} = this.props;
              // handle hidden/show button update
              const setGeofences = new Set();
              result.geofence_ids.forEach((item) => {
                principal.geofence_ids.includes((item)) && setGeofences.add(item);
              });
              if (setGeofences.size === result.geofence_ids.length) {
                this.setState({checkDisable: false});
              } else this.setState({checkDisable: true});

              // handle logic geofence
              const listGeofences = [...new Set([...principal.geofence_ids, ...result.geofence_ids])];
              await this.props.getListGeofenceDetailsByIds({geofenceIds: listGeofences}).then((result) => {
                this.setState({listGeofenceByCountry: result || []});
              });
            }
            if (result.plan_type === 'PLAN') {
              await getCompaniesByCountryApi(result?.country_id)
                .then((response) => {
                  if (response.status === 200) {
                    this.setState({list_companies: response?.result});
                    if (result?.company_id) {
                      const company = response?.result?.find((item) => item.id === result.company_id);
                      if (company) {
                        const list_geofences = this.state.listGeofenceByCountry.filter(
                          (item) => company.geofence_list.map((item2) => item2.geofence_id).includes(item.geofence_id),
                        );
                        this.setState({list_geofences});
                      }
                    }
                  } else this.props.setMessageModal(modalObj(true, response.message_code));
                })
                .catch(() => this.props.setMessageModal(modalObj(true, this.props.t('Api.fail'))));
            }
          } else backForwardRouter(this.props, ROUTE.LAYOUT + ROUTE.BUSINESS_PLAN);
        },
      );
    }
  }

  /**
   * formatDataDetailsUseTime
   * @param {*} dates
   * @param {*} zone_id
   * @param {*} is_display_time
   * @return {Object}
   */
  formatDataDetailsUseTime = (dates, zone_id, is_display_time) => {
    // Convert start date
    const startDateString = dates.start_time;
    const startDateDay = startDateString.substr(8, 2);
    const startDateMonth = startDateString.substr(5, 2);
    const startDateYear = startDateString.substr(0, 4);

    // Month is subtract to 1 because month in Date start with 0
    let startDate = new Date(startDateYear, startDateMonth - 1, startDateDay, 0, 0, 0, 0);
    startDate = ZONE_ID_MINUS.includes(zone_id) ? startDate : add(startDate, {days: 1});

    // Convert end date
    const endDateString = dates.end_time;
    const endDateDay = endDateString.substr(8, 2);
    const endDateMonth = endDateString.substr(5, 2);
    const endDateYear = endDateString.substr(0, 4);

    let endDate = new Date(endDateYear, endDateMonth - 1, endDateDay, 23, 59, 59, 0);
    endDate = ZONE_ID_MINUS.includes(zone_id) ? add(endDate, {days: -1}) : endDate;
    return is_display_time ? [{start_time: startDate, end_time: endDate}] : {
      start_time: startDate,
      end_time: endDate,
    };
  };

  /**
   * componentDidUpdate
   * @param {*} prevProps
   * @param {*} prevState
   */
  componentDidUpdate(prevProps, prevState) {
    if (prevState.country_id !== this.state.country_id) {
      this.props.getListGeofenceByCountry(this.state.country_id);
    }
  }

  /**
   * configPlan
   * @param {String} action
   */
  configPlan = async (action = 'register') => {
    if (action !== 'register' && this.state.plan_referrers.length > 0) {
      return;
    }
    const {plan_category} = this.state;
    this.setState({
      isSubmitForm: true,
    });
    const validOperatingTimeRanges = await this.validateOperatingTimeRanges();
    const validateOperatingDateRanges = await this.validateOperatingDateRanges();
    if (
      this.validator.allValid() &&
      validOperatingTimeRanges &&
      validateOperatingDateRanges &&
      this.checkTimeRange() &&
      this.checkEndTime() &&
      this.isCheckMaxTransferAndReminder() &&
      this.isCheckMaxTransferAndNumberOfUnit() &&
      this.isCheckPaymentMethodTransfer() &&
      this.state.payment_method.length > 0 &&
      (this.checkListAmount() || plan_category === 'TICKET') &&
      (this.checkListExtraFee() || plan_category === 'TICKET') &&
      (this.checkListTicketAdditionalRideLimit() || plan_category === 'PLAN') &&
      (this.checkListTicketAdditionalAmount() || plan_category === 'PLAN') &&
      this.handleCheckDataGeofenceWithDataDiscount() &&
      this.validateOperatingTimeWithGeofenceTime() &&
      !this.handleCheckDataUserDiscount()
    ) {
      const message = action === 'register' ? 'messageCode.createConfirmPlan' : 'messageCode.updateConfirmPlan';
      this.setState({
        flg: true,
        message: message,
        extra_fee: this.state.max_user === '1' ? this.state.price?.map(() => 0) : this.state.extra_fee,
      });
    }
  };

  /**
   * formatDataUseTimeSend
   * @param {*} data_time
   * @return {*}
   */
  formatDataUseTimeSend = (data_time) => {
    let startTime = data_time.start_time;
    let endTime = data_time.end_time;
    startTime = ZONE_ID_MINUS.includes(this.state.zone_id) ? startTime : add(startTime, {days: -1});
    endTime = ZONE_ID_MINUS.includes(this.state.zone_id) ? add(endTime, {days: 1}) : endTime;

    // Add 1 because month of Date start with 0
    const start_time =
      startTime.getFullYear() +
      '-' +
      this.convertTimeNumber(startTime.getMonth() + 1) +
      '-' +
      this.convertTimeNumber(startTime.getDate()) +
      this.checkCountryTimeZone(this.state.zone_id, true);
    const end_time =
      endTime.getFullYear() +
      '-' +
      this.convertTimeNumber(endTime.getMonth() + 1) +
      '-' +
      this.convertTimeNumber(endTime.getDate()) +
      this.checkCountryTimeZone(this.state.zone_id, false);
    return {
      start_time,
      end_time,
    };
  };

  /**
   * checkCountryTimeZone
   * @param {String} zone_id
   * @param {Boolean} isStart
   * @return {String}
   */
  checkCountryTimeZone = (zone_id, isStart) => {
    switch (zone_id) {
      case 'Asia/Saigon':
        return isStart ? 'T17:00:00.000Z' : 'T16:59:59.000Z';
      case 'Asia/Singapore':
      case 'Asia/Makassar':
      case 'Asia/Kuala_Lumpur':
        return isStart ? 'T16:00:00.000Z' : 'T15:59:59.000Z';
      case 'Pacific/Guam':
        return isStart ? 'T14:00:00.000Z' : 'T13:59:59.000Z';
      case 'Pacific/Honolulu':
        return isStart ? 'T10:00:00.000Z' : 'T09:59:59.000Z';
      case 'Asia/Tokyo':
      default:
        return isStart ? 'T15:00:00.000Z' : 'T14:59:59.000Z';
    }
  };

  /**
   * convertDataPass
   * @param {*} data_time
   * @return {*}
   */
  convertDataPass = (data_time) => {
    const arrDate = [];
    for (let i = 0; i < data_time?.length; i++) {
      const data = {
        start_time: formatUtc(data_time[i].start_time, FORMAT_PATTERN_DATE_PICKER),
        end_time: formatUtc(data_time[i].end_time, FORMAT_PATTERN_DATE_PICKER),
      };
      arrDate.push(data);
    }
    return arrDate;
  };

  /**
   * formatDataDateToHour
   * @param {*} listPlanTime
   * @return {*}
   */
  formatDataDateToHour = (listPlanTime) => {
    const arrNew = [];
    for (let i = 0; i < listPlanTime.length; i++) {
      const data = {
        start_time: formatLocalToHourMinute(listPlanTime[i].start_time, this.state.zone_id),
        end_time: formatLocalToHourMinute(listPlanTime[i].end_time, this.state.zone_id),
      };
      arrNew.push(data);
    }
    return arrNew;
  };

  /**
   * formatDataHourToDate
   * @param {*} listPlanTime
   * @param {*} zone_id
   * @return {*}
   */
  formatDataHourToDate = (listPlanTime, zone_id) => {
    const arrNew = [];
    for (let i = 0; i < listPlanTime.length; i++) {
      const data = {
        start_time: formatHourMinuteToLocal(listPlanTime[i]?.start_time, zone_id),
        end_time: formatHourMinuteToLocal(listPlanTime[i]?.end_time, zone_id),
      };
      arrNew.push(data);
    }
    return arrNew;
  };

  /**
   * check number 0 -> 9 return '0{number}'
   * @param {*} time
   * @return {*}
   */
  convertTimeNumber = (time) => {
    if (time <= 9) {
      return '0' + time;
    }
    return time;
  };

  /**
   * Validate operating time ranges and return true if time ranges are all valid
   * @return {boolean}
   */
  validateOperatingTimeRanges = async () => {
    const {listPlanTime} = this.state;
    const overlapList = new Set();
    for (let i = 0; i < listPlanTime?.length - 1; i++) {
      const start1 = listPlanTime[i]?.start_time ?
        this.convertTimeNumber(listPlanTime[i]?.start_time?.getHours()) + ':' + this.convertTimeNumber(listPlanTime[i]?.start_time?.getMinutes()) :
        '';
      const end1 = listPlanTime[i]?.end_time ?
        this.convertTimeNumber(listPlanTime[i]?.end_time?.getHours()) + ':' + this.convertTimeNumber(listPlanTime[i]?.end_time?.getMinutes()) :
        '';
      for (let j = i + 1; j < listPlanTime?.length; j++) {
        const start2 = listPlanTime[j]?.start_time ?
          this.convertTimeNumber(listPlanTime[j]?.start_time?.getHours()) + ':' + this.convertTimeNumber(listPlanTime[j]?.start_time?.getMinutes()) :
          '';
        const end2 = listPlanTime[j]?.end_time ?
          this.convertTimeNumber(listPlanTime[j]?.end_time?.getHours()) + ':' + this.convertTimeNumber(listPlanTime[j]?.end_time?.getMinutes()) :
          '';
        if (isOverlapDateRelatively(start1.toString(), end1.toString(), start2.toString(), end2.toString())) {
          overlapList.add(i);
          overlapList.add(j);
        }
      }
    }
    this.setState({overlapList});
    return overlapList.size === 0;
  };

  /**
   * Validate operating time ranges and return true if time ranges are all valid
   * @return {boolean}
   */
  validateOperatingDateRanges = () => {
    const {display_times} = this.state;
    const overlapListDate = new Set();
    for (let i = 0; i < display_times?.length - 1; i++) {
      const start1 = display_times[i]?.start_time;
      const end1 = display_times[i]?.end_time;
      for (let j = i + 1; j < display_times?.length; j++) {
        const start2 = display_times[j]?.start_time;
        const end2 = display_times[j]?.end_time;
        if (isOverlapDateRelatively(start1, end1, start2, end2)) {
          overlapListDate.add(i);
          overlapListDate.add(j);
        }
      }
    }
    this.setState({overlapListDate});
    return overlapListDate.size === 0;
  };

  /**
   * validateOperatingTimeWithGeofenceTime
   * @return {boolean}
   */
  validateOperatingTimeWithGeofenceTime = () => {
    const {listGeofence} = this.props.geofence;
    const {listPlanTime} = this.state;
    const list_geofence = listGeofence?.content?.map((item) => ({
      geofence_id: item.geofence_id,
      operating_start_time: item?.operating_start_time?.slice(0, 5),
      operating_end_time: item?.operating_end_time?.slice(0, 5),
    }));
    const timeGeofence = list_geofence?.filter((item) => this.state.list_area_id.includes(item.geofence_id));
    const check = timeGeofence?.map((geofence) => {
      const arrCheck = [];
      for (let i = 0; i < listPlanTime.length; i++) {
        const plan_start1 = listPlanTime[i].start_time ?
          this.convertTimeNumber(listPlanTime[i]?.start_time?.getHours()) + ':' + this.convertTimeNumber(listPlanTime[i]?.start_time?.getMinutes()) :
          '';
        const plan_end1 = listPlanTime[i].end_time ?
          this.convertTimeNumber(listPlanTime[i]?.end_time?.getHours()) + ':' + this.convertTimeNumber(listPlanTime[i]?.end_time?.getMinutes()) :
          '';
        if (
          (geofence.operating_start_time <= plan_end1 && geofence.operating_start_time >= plan_start1) ||
          (geofence.operating_end_time <= plan_end1 && geofence.operating_end_time >= plan_start1) ||
          (plan_end1 <= geofence.operating_end_time && plan_end1 >= geofence.operating_start_time) ||
          (plan_start1 <= geofence.operating_end_time && plan_start1 >= geofence.operating_start_time)
        ) {
          arrCheck.push(i);
        }
      }
      return arrCheck.length > 0;
    });
    return check.every((item) => item === true);
  };

  /**
   * handleButtonModalOk
   */
  handleButtonModalOk = async () => {
    this.setState({flg: false, isDisableCreate: true});
    const {
      country_id,
      list_area_id,
      type,
      name_jp,
      name_vi,
      name_en,
      number_of_unit,
      max_user,
      freeStartingTime,
      currency,
      listPlanTime,
      price,
      extra_fee,
      show_for_app,
      main_plan,
      memos,
      note,
      description_jp,
      description_vi,
      description_en,
      display_times,
      use_time,
      bank_account_ids,
      max_transfer_days,
      reminder_days,
      serviceTypeIds,
      zone_id,
      company_id,
      payment_method,
      plan_category,
      amount,
      automatic_update,
      amount_type,
      ride_limit,
      ticket_additional_ride_limit,
      ticket_additional_amount,
      for_gift,
      isShowPromoApp,
      promotion_title_jp,
      promotion_title_vi,
      promotion_title_en,
      promotion_description_jp,
      promotion_description_vi,
      promotion_description_en,
      one_time_discount_description_jp,
      one_time_discount_description_vi,
      one_time_discount_description_en,
      oneTimeDiscountStatus,
      image_master_url,
      dataDiscount,
    } = this.state;
    const plan_languages = [];
    name_jp &&
      description_jp &&
      plan_languages.push({
        language_id: LANGUAGE.find((item) => item.code === 'ja')?.id,
        name: name_jp,
        description: description_jp,
        promotion_title: isShowPromoApp ? promotion_title_jp : '',
        promotion_description: isShowPromoApp ? promotion_description_jp : '',
      });
    name_vi &&
      description_vi &&
      plan_languages.push({
        language_id: LANGUAGE.find((item) => item.code === 'vi')?.id,
        name: name_vi,
        description: description_vi,
        promotion_title: isShowPromoApp ? promotion_title_vi : '',
        promotion_description: isShowPromoApp ? promotion_description_vi : '',
      });
    name_en &&
      description_en &&
      plan_languages.push({
        language_id: LANGUAGE.find((item) => item.code === 'en')?.id,
        name: name_en,
        description: description_en,
        promotion_title: isShowPromoApp ? promotion_title_en : '',
        promotion_description: isShowPromoApp ? promotion_description_en : '',
      });
    let payload = {
      plan_type: plan_category,
      country_id: country_id,
      geofence_ids: list_area_id,
      unit_type: type,
      plan_languages,
      number_of_unit: Number(number_of_unit),
      plan_shifts: this.formatDataDateToHour(listPlanTime),
      name: plan_languages[0].name,
      description: plan_languages[0].description,
      status: 'ENABLE',
      show_for_app: show_for_app === 'true',
      memos,
      display_times: await this.formatDataUseTimeSend(display_times[0]),
      zone_id: zone_id,
      bank_ids: bank_account_ids,
      max_transfer_days: max_transfer_days || max_transfer_days === 0 ? Number(max_transfer_days) : null,
      reminder_days: reminder_days || reminder_days === 0 ? Number(reminder_days) : null,
      service_type_ids: serviceTypeIds,
      payment_methods: payment_method?.map((item) => PAYMENT_METHOD_TRANSFER.find((item2) => item2.id === item)?.value),
      use_time: await this.formatDataUseTimeSend(use_time),
      main_plan: main_plan === 'true',
      auto_renew: automatic_update,
      is_gift: for_gift,
      show_promotion_for_app: isShowPromoApp,
    };
    if (plan_category === 'PLAN') {
      payload = {
        ...payload,
        limit_family_user: Number(max_user),
        plan_prices: price?.map((item, index) => {
          return {
            amount: formatNumber2DecimalDigits(item, currency),
            currency_code: currency,
            extra_fee: formatNumber2DecimalDigits(extra_fee[index], currency),
          };
        }),
        trial_time: Number(freeStartingTime),
        company_id,
      };
    } else if (plan_category === 'TICKET') {
      const rides_additional =
        ticket_additional_ride_limit.map((item, index) => {
          return {
            additional_rides: Number(item),
            additional_price: formatNumber2DecimalDigits(Number(ticket_additional_amount[index]), currency),
            currency_code: currency,
          };
        }) || [];
      const payloadDataDiscount = dataDiscount?.map((item) => ({
        geofence_id: item?.geofence_id,
        number_of_user_with_discount: item?.number_of_user_with_discount,
        child_amount: Number(item?.child_amount),
        adult_amount: Number(item?.adult_amount),
      }));
      const one_time_discount =
        oneTimeDiscountStatus === 'ENABLE' ?
          {
              discount_icon_url: image_master_url,
              discount_details: payloadDataDiscount,
              discount_description: {
                ja: one_time_discount_description_jp,
                en: one_time_discount_description_en,
                vi: one_time_discount_description_vi,
              },
            } :
          null;
      payload = {
        ...payload,
        plan_prices: [
          {
            extra_fee: 0,
            amount: formatNumber2DecimalDigits(Number(amount), currency),
            currency_code: currency,
          },
        ],
        ticket_data: {
          ticket_type: amount_type,
          max_number_of_rides: ride_limit,
          rides_additional,
        },
        plan_discount: one_time_discount,
      };
    }
    if (this.props.match.params.id) {
      payload.plan_id = this.props.match.params.id;
      payload.note = note?.trim();
      this.props.updatePlan(payload, this.props).then((res) => {
        if (res) {
          this.setState({
            isDisableCreate: false,
          });
        }
      });
      this.props.getDetailPlan(this.props.match.params.id);
    } else {
      this.props.createPlan(payload, this.props).then((res) => {
        if (res) {
          this.setState({
            isDisableCreate: false,
          });
        }
      });
    }
  };

  /**
   * handleButtonModalCancel
   */
  handleButtonModalCancel = () => {
    this.setState({
      flg: false,
    });
  };

  /**
   * changeAreaBy
   * @param {*} value
   * @param {*} item
   */
  changeAreaBy = (value, item) => {
    const list_area_id_copy = this.state.list_area_id;
    const index = list_area_id_copy.indexOf(item);
    value ? list_area_id_copy.push(item) : list_area_id_copy.splice(index, 1);
    this.setState({
      list_area_id: list_area_id_copy,
    });
  };

  /**
   * changePaymentMethod
   * @param {*} value
   * @param {*} item
   */
  changePaymentMethod = (value, item) => {
    const payment_method_copy = this.state.payment_method;
    const index = payment_method_copy.indexOf(item);
    value ? payment_method_copy.push(item) : payment_method_copy.splice(index, 1);
    this.setState({
      payment_method: payment_method_copy,
    });
  };

  /**
   * changePlanType
   * @param {*} event
   * @param {*} id
   */
  changePlanType = (event, id) => {
    let planTypeIds = this.state.serviceTypeIds;
    if (event.target.checked) {
      planTypeIds.push(id);
    } else {
      planTypeIds = planTypeIds.filter((item) => item !== id);
    }
    this.setState({
      serviceTypeIds: planTypeIds,
    });
  };

  /**
   * changeOperatingHour
   * @param {*} index
   * @param {*} value
   * @param {*} isStart
   */
  changeOperatingHour = async (index, value, isStart) => {
    const listPlanTimesTemp = this.state.listPlanTime;
    isStart ? (listPlanTimesTemp[index].start_time = value || '') : (listPlanTimesTemp[index].end_time = value || '');
    this.setState({
      listPlanTime: listPlanTimesTemp,
    });
    await this.validateOperatingTimeRanges();
  };

  /**
   * changeDisplayTime
   * @param {*} index
   * @param {*} value
   * @param {*} isStart
   */
  changeDisplayTime = async (index, value, isStart) => {
    const display_times = [...this.state.display_times];
    isStart ? (display_times[index].start_time = value || '') : (display_times[index].end_time = value || '');
    this.setState({
      display_times,
    });
    this.validateOperatingDateRanges();
  };

  /**
   * changeUseTime
   * @param {*} value
   * @param {string} key
   */
  changeUseTime = async (value, key) => {
    const use_time = {...this.state.use_time};
    const datePattern = 'yyyy-MM-DD';
    use_time[key] = value;
    let plan_referrers = [];

    if (!isNaN(+this.props.match.params.id) && this.state.for_gift) {
      // if update & plan
      const payload = {
        start_time: moment(use_time.start_time).format(datePattern),
        end_time: moment(use_time.end_time).format(datePattern),
        zone_id: this.state.zone_id,
        plan_id: +this.props.match.params.id,
        check_plan: true,
      };

      const response = await searchReferrersApi(payload);
      plan_referrers = response.result.content;
    }

    this.setState({
      use_time,
      plan_referrers,
    });
  };

  /**
   * checkEndTime
   * @return {Boolean}
   */
  checkEndTime = () => {
    const display_end_time = this.state.display_times[0].end_time;
    const use_end_time = this.state.use_time.end_time;
    display_end_time.setSeconds(59);
    use_end_time.setSeconds(59);
    return use_end_time >= display_end_time;
  };

  /**
   * isValidateTimeUse
   * @return {*}
   */
  isValidateTimeUse = () => {
    const {use_time} = this.state;
    return use_time.start_time && use_time.end_time && use_time.start_time < use_time.end_time;
  };

  /**
   * addItem
   */
  addItem = async () => {
    this.setState({
      listPlanTime: [...this.state.listPlanTime, {}],
    });
  };

  /**
   * addItemDisplayTimes
   */
  addItemDisplayTimes = async () => {
    this.setState({
      display_times: [...this.state.display_times, {}],
    });
  };

  /**
   * deleteRows
   * @param {Number} index
   */
  deleteRows = (index) => {
    const listPlanTimesTemp = this.state.listPlanTime;
    listPlanTimesTemp.splice(index, 1);
    this.setState({listPlanTime: listPlanTimesTemp});
  };

  /**
   * deleteRows
   * @param {Number} index
   */
  deleteRowsDisplayTime = (index) => {
    const display_times = [...this.state.display_times];
    display_times.splice(index, 1);
    this.setState({display_times});
  };

  /**
   * checkTimeRange
   * @return {Boolean}
   */
  checkTimeRange = () => {
    return (
      this.state.listPlanTime.every((item) => this.checkHoursUnit(item.start_time, item.end_time)) &&
      this.state.display_times.every(
        (item) => compareDateTimeRange(item.start_time, item.end_time, true) && compareDateTimeRange(this.state.use_time.start_time, this.state.use_time.end_time, true),
      )
    );
  };

  /**
   * Return true if time range is valid
   * @param {*} startTime
   * @param {*} endTime
   * @return {bool}
   */
  checkHoursUnit = (startTime, endTime) => {
    return (
      startTime &&
      endTime &&
      (endTime > startTime || startTime.getHours() < endTime.getHours() || (startTime.getHours() === endTime.getHours() && startTime.getMinutes() < endTime.getMinutes()))
    );
  };

  /**
   * onChangeCountry
   * @param {*} country_id
   */
  onChangeCountry = async (country_id) => {
    let {payment_method} = this.state;
    const country = this.props.common?.country_code?.find((item) => item.id === country_id);
    const currency = CURRENCY.find((item) => item.countryCode === country.country_code)?.id;
    await this.setState({
      country_id: country_id,
      zone_id: this.props.common?.country_code?.find((item) => item.id === country_id)?.zone_id,
      list_area_id: this.state.dataDetail?.country_id === country_id ? this.state.dataDetail?.geofence_ids : [],
      company_id: '',
      currency,
      amount: 0,
      ticket_additional_amount: [],
      price: [0],
      extra_fee: [0],
      ticket_additional_ride_limit: [],
      dataDiscount: [],
    });
    if (this.checkCountry()) !payment_method.includes(1) && payment_method.push(1);
    payment_method = country.id === 5 && this.state.for_gift === false ? [] : [1];
    this.setState({payment_method});
    this.props.getListGeofenceByCountry(country_id);
    await getCompaniesByCountryApi(country_id)
      .then((response) => {
        if (response.status === 200) {
          this.setState({list_companies: response?.result});
        } else this.props.setMessageModal(modalObj(true, response.message_code));
      })
      .catch(() => this.props.setMessageModal(modalObj(true, this.props.t('Api.fail'))));
  };

  /**
   * onChangeCompany
   * @param {*} e
   */
  onChangeCompany = async (e) => {
    const company = this.state.list_companies.find((item) => item.id === e.target.value);
    const geofence = !isRoleGlobal() ? this.state.listGeofenceByCountry : this.props.common?.geofence_by_country;
    const list_geofences = geofence.filter((item) => company.geofence_list.map((item2) => item2.geofence_id).includes(item.geofence_id));
    this.setState({
      company_id: e.target.value,
      list_area_id: [],
      list_geofences,
    });
  };

  /**
   * handleChangeMainPlan
   * @param {*} e
   */
  handleChangeMainPlan = (e) => {
    const checkMainPlan = e.target.value;
    this.setState({
      main_plan: checkMainPlan,
    });
    if (checkMainPlan === 'true') {
      searchListPlanApi({is_main_plan: checkMainPlan, geofence_id: this.state.list_area_id}, {}).then((response) => {
        this.setState({
          isMessageShowPlan: true,
          dataShowMainPlan: response.result?.content[0],
        });
      });
    } else {
      this.setState({
        isMessageShowPlan: false,
        dataShowMainPlan: null,
      });
    }
  };

  /**
   * isCheckMaxTransferAndReminder
   * @return {*}
   */
  isCheckMaxTransferAndReminder = () => {
    return this.state.listBankAccount.length === 0 || Number(this.state.max_transfer_days) > Number(this.state.reminder_days);
  };

  /**
   * isCheckMaxTransferAndNumberOfUnit
   * @return {*}
   */
  isCheckMaxTransferAndNumberOfUnit = () => {
    return this.state.listBankAccount.length === 0 || Number(this.state.max_transfer_days) <= Number(this.state.number_of_unit);
  };

  /**
   * Check Payment Method Transfer
   * @return {Boolean}
   */
  isCheckPaymentMethodTransfer = () => {
    return !this.state.payment_method?.includes(PAYMENT_METHOD_TRANSFER[1].id) || this.state.listBankAccount?.length > 0;
  };

  /**
   * checkCountry
   * @return {Boolean}
   */
  checkCountry = () => {
    const countries_id = [2]; // JP
    return countries_id.includes(Number(this.state.country_id));
  };

  /**
   * checkListAmount
   * @return {Boolean}
   */
  checkListAmount = () => {
    for (const amount of this.state.price) {
      if (amount === '') return false;
    }
    return true;
  };

  /**
   * getEmptyAmount
   * @return {String}
   */
  getEmptyAmount = () => {
    const result = [];
    for (const [index, amount] of Object.entries(this.state.price)) {
      if (amount === '') result.push(Number(index) + 1);
    }
    return result.join(', ');
  };

  /**
   * getEmptyExtraFee
   * @return {String}
   */
  getEmptyExtraFee = () => {
    const result = [];
    for (const [index, extraFee] of Object.entries(this.state.extra_fee)) {
      if (extraFee === '') result.push(Number(index) + 1);
    }
    return result.join(', ');
  };

  /**
   * checkListExtraFee
   * @return {Boolean}
   */
  checkListExtraFee = () => {
    for (const extraFee of this.state.extra_fee) {
      if (extraFee === '' && this.state.max_user !== '1') return false;
    }
    return true;
  };

  /**
   * checkListTicketAdditionalRideLimit
   * @return {Boolean}
   */
  checkListTicketAdditionalRideLimit = () => {
    for (const rideLimit of this.state.ticket_additional_ride_limit) {
      if (Number(rideLimit) === 0) return false;
    }
    return true;
  };

  /**
   * getEmptyAdditionalTicketRideLimit
   * @return {String}
   */
  getEmptyAdditionalTicketRideLimit = () => {
    const result = [];
    for (const [index, rideLimit] of Object.entries(this.state.ticket_additional_ride_limit)) {
      if (Number(rideLimit) === 0) result.push(Number(index) + 1);
    }
    return result.join(', ');
  };

  /**
   * checkListTicketAdditionalAmount
   * @return {Boolean}
   */
  checkListTicketAdditionalAmount = () => {
    for (const amount of this.state.ticket_additional_amount) {
      if (Number(amount) === 0) return false;
    }
    return true;
  };

  /**
   * getEmptyAdditionalTicketAmount
   * @return {String}
   */
  getEmptyAdditionalTicketAmount = () => {
    const result = [];
    for (const [index, amount] of Object.entries(this.state.ticket_additional_amount)) {
      if (Number(amount) === 0) result.push(Number(index) + 1);
    }
    return result.join(', ');
  };

  /**
   * closeListDiscountModal
   */
  closeListDiscountModal = () => {
    this.setState({flagListDiscount: false, isSubmitModalDiscount: true});
  };

  /**
   * handleSubmitFormDiscount
   * @param {*} data
   */
  handleSubmitFormDiscount = (data) => {
    this.setState({dataDiscount: data, flagListDiscount: false, isSubmitModalDiscount: true});
  };

  /**
   * openImageModal
   * @param {Number} index;
   */
  openImageModal = () => {
    this.setState({
      isOpenModalImage: true,
    });
  };

  /**
   * handleSelectImage
   * @param {*} value
   */
  handleSelectImage = (value) => {
    this.setState({
      image_master_url: value.url,
    });
  };

  /**
   * handleButtonOkImageModal
   */
  handleButtonOkImageModal = () => {
    this.setState({
      isOpenModalImage: false,
    });
  };

  /**
   * handleButtonCloseImageModal
   */
  handleButtonCloseImageModal = () => {
    this.setState({
      isOpenModalImage: false,
    });
  };

  /**
   * handleCheckDataGeofenceWithDataDiscount
   * @return {Boolean}
   */
  handleCheckDataGeofenceWithDataDiscount = () => {
    const geofenceDiscount = this.state.dataDiscount?.map((item) => item?.geofence_id);
    const arrCheck = this.state.list_area_id?.map((item) => geofenceDiscount?.includes(item));
    if ((this.state.plan_category === 'TICKET' && this.state.oneTimeDiscountStatus === 'DISABLE') || this.state.plan_category === 'PLAN') {
      return true;
    } else return arrCheck?.every((item) => item === true) && geofenceDiscount?.length === this.state.list_area_id?.length;
  };

  /**
   * handleCheckDataUserDiscount
   * @return {Boolean}
   */
  handleCheckDataUserDiscount = () => {
    const geofenceDiscount = this.state.dataDiscount?.map((item) => Number(item?.number_of_user_with_discount) === 0);
    if ((this.state.plan_category === 'TICKET' && this.state.oneTimeDiscountStatus === 'DISABLE') || this.state.plan_category === 'PLAN') {
      return false;
    } else return geofenceDiscount?.every((item) => item === true);
  };

  /**
   * getListAllGeofence
   * @param {String} geofence_by_country
   * @return {Array}
   */
  getListAllGeofence = (geofence_by_country) => {
    const listGeofences = this.state.company_id ?
      this.state.list_geofences :
      isRoleBusiness() && this.props.match.params.id ?
      this.state.listGeofenceByCountry :
      geofence_by_country;
    return (this.props.match.params.id && this.state.dataDetail?.country_id === this.state.country_id) ?
        getListAllGeofenceDetail(listGeofences, this.state.dataDetail?.geofence_config_infos) : listGeofences;
  }

  /**
   * Render component
   * @return {HTMLElement}
   */
  render() {
    const {t, common, business, actions} = this.props;
    const isBanking = this.state.listBankAccount.length > 0;
    const {listPlanTime, display_times, serviceTypeIds, plan_category, plan_referrers} = this.state;
    const isEditForm = !!this.props.match.params.id;
    const permission = {
      canUpdate: actions.includes(PERMISSION_ACTIONS.UPDATE),
      canSearch: true,
    };
    this.validator.purgeFields();
    const referrer_err = plan_referrers?.length > 0;

    return (
      <LoadingOverlay active={business.isLoading || common.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
        <Card className="main_card_min_size plan_form">
          <Container maxWidth="xl">
            <Grid container className="page_header">
              <Grid container alignItems="center" item xs={6}>
                <h3>{isEditForm ? t('route.business_plan_details') : t('route.business_plan_create')}</h3>
              </Grid>
            </Grid>
          </Container>
          <br></br>
          <Container maxWidth="xl">
            <Card raised>
              <Container maxWidth="xl">
                <br></br>
                <Paper className="search_table">
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={12} lg={12} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                      {isEditForm ? t('route.business_plan_details') : t('route.business_plan_create')}
                    </Grid>
                  </Grid>
                  {/* Plan Category */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('business_plan.category')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={6} className="grid_title_padding">
                      <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                        <Select
                          margin="dense"
                          inputProps={{
                            name: 'plan_category',
                          }}
                          disabled={isEditForm}
                          displayEmpty
                          value={this.state.plan_category}
                          onChange={(event) => onChangeSelect(this, event)}
                          renderValue={
                            this.state.plan_category ?
                              undefined :
                              () => (
                                  <div className="font-12 color-disabled">
                                    {t('placeholder.required_select', {
                                      field: t('business_plan.category'),
                                    })}
                                  </div>
                                )
                          }
                        >
                          {PLAN_CATEGORIES.map((item, index) => (
                            <MenuItem value={item.value} key={index}>
                              {t(`${item.i18n}`)}
                            </MenuItem>
                          ))}
                        </Select>
                        {this.validator.message('plan_category', this.state.plan_category, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.plan_category, 'required') && (
                          <FormHelperText id="plan_category" error>
                            {t('validation.required.choose', {field: t('business_plan.category')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* For gifts only */}
                  {plan_category === 'TICKET' && (
                    <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                      <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                        {t('ticket.for_gift_only')}
                      </Grid>
                      <Grid container alignItems="center" item xs={6} lg={3} className="grid_title_padding">
                        <FormControl error className="item_display_box">
                          <FormControlLabel
                            labelPlacement="end"
                            disabled={isEditForm}
                            control={
                              <Checkbox
                                checked={this.state.for_gift}
                                onChange={(event) =>
                                  this.setState({
                                    for_gift: event.target.checked,
                                    automatic_update: false,
                                    amount: 0,
                                    ticket_additional_ride_limit: [],
                                    ticket_additional_amount: [],
                                    show_for_app: 'false',
                                    main_plan: 'false',
                                    max_transfer_days: '',
                                    reminder_days: '',
                                    payment_method: this.state.country_id === 5 && event.target.checked === false ? [] : [1],
                                    listBankAccount: [],
                                    bank_account_ids: [],
                                  })
                                }
                                className="checkbox_radio"
                              />
                            }
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  )}

                  {/* Country */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('common.country')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                        <Select
                          margin="dense"
                          inputProps={{
                            name: 'country',
                          }}
                          displayEmpty
                          value={this.state.country_id}
                          disabled={!plan_category || !isRoleGlobal()}
                          onChange={(event) => this.onChangeCountry(event.target.value)}
                          renderValue={
                            this.state.country_id ?
                              undefined :
                              () => (
                                  <div className="font-12 color-disabled">
                                    {t('placeholder.required_select', {
                                      field: t('common.country'),
                                    })}
                                  </div>
                                )
                          }
                        >
                          {common?.country_code?.map((item, idx) => (
                            <MenuItem value={item.id} key={idx}>
                              {t(`${item.country_code}`)}
                            </MenuItem>
                          ))}
                        </Select>
                        {this.validator.message('country', this.state.country_id, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.country_id, 'required') && (
                          <FormHelperText id="country" error>
                            {t('validation.required.choose', {field: t('common.country')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Company */}
                  {plan_category !== 'TICKET' && (
                    <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                      <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                        {t('business_plan.company')}
                      </Grid>
                      <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                        {this.state.country_id && this.state.is_company_adding_enable && (
                          <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                            <Select
                              margin="dense"
                              inputProps={{
                                name: 'company_id',
                              }}
                              displayEmpty
                              value={this.state.company_id}
                              onChange={this.onChangeCompany}
                              renderValue={
                                this.state.company_id ?
                                  undefined :
                                  () => (
                                      <div className="font-12 color-disabled">
                                        {t('placeholder.required_select', {
                                          field: t('business_plan.company'),
                                        })}
                                      </div>
                                    )
                              }
                            >
                              {this.state.list_companies?.map((item, idx) => {
                                return (
                                  <MenuItem value={item.id} key={idx}>
                                    {t(`${item.name}`)}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        )}
                      </Grid>
                    </Grid>
                  )}

                  {/* Geofence */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue" style={{paddingBottom: 12}}>
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('business_plan.geofence')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={6} className="grid_title_padding">
                      <FormControl error>
                        <FormGroup row>
                          {this.state.country_id !== '' &&
                            this.getListAllGeofence(common.geofence_by_country).map((item, index) => {
                              const checked = this.state.list_area_id?.indexOf(item.geofence_id) > -1;
                              return (
                                <FormControlLabel
                                  key={index}
                                  labelPlacement="end"
                                  control={
                                    <Checkbox
                                      disabled={item.enable === false || item.enable_for_admin === false ||
                                        (isRoleBusiness() && !this.props.principal.geofence_ids.includes(item.geofence_id))}
                                      checked={checked}
                                      onClick={(event) => this.changeAreaBy(event.target.checked, item.geofence_id)}
                                      className="checkbox_radio"
                                    />
                                  }
                                  label={item.name}
                                />
                              );
                            })}
                        </FormGroup>
                        {this.validator.message('list_area_id', this.state.list_area_id, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.list_area_id, 'required') && (
                          <FormHelperText id="list_area_id" error>
                            {t('validation.required.choose', {field: t('business_plan.geofence')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {plan_category && (
                    <>
                      <Grid container spacing={1} className="row_form_item">
                        <Grid
                          container
                          alignItems="center"
                          item
                          xs={12}
                          className="product_group_table_header_info font_color_white"
                          style={{
                            fontSize: 16,
                            paddingTop: 15,
                            paddingBottom: 15,
                            paddingLeft: 20,
                            paddingRight: 20,
                          }}
                        >
                          {t('reservationManagement.basic_information')}
                        </Grid>
                      </Grid>

                      {/* Code */}
                      {isEditForm && (
                        <Grid container spacing={1} className="row_form_item table_background_color_aliceblue" style={{paddingTop: 12}}>
                          <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                            {t('business_plan.code')} <span className="font_color_red">＊</span>
                          </Grid>
                          <Grid container alignItems="center" item xs={6} lg={4} className="grid_title_padding">
                            <FormControl error>
                              <div className="box-group-input">
                                <TextField
                                  className="field_size_10 field_min_size_300"
                                  margin="dense"
                                  inputProps={{
                                    name: 'code',
                                  }}
                                  disabled={true}
                                  placeholder={t('placeholder.required', {field: t('business_plan.code')})}
                                  variant="outlined"
                                  onChange={(event) => onChangeTextField(this, event)}
                                  value={this.state.code}
                                />
                              </div>
                            </FormControl>
                          </Grid>
                        </Grid>
                      )}

                      {/* Plan type */}
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('common.planType')} <span className="font_color_red">＊</span>
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4} className="grid_title_padding">
                          <FormControl error className="item_display_box">
                            {common?.planTypes?.map(
                              (planType, index) =>
                                (planType.service_type === RESERVATION_MOBI_TYPE[0].id || planType.service_type === RESERVATION_MOBI_TYPE[1].id) && (
                                  <FormControlLabel
                                    labelPlacement="end"
                                    control={
                                      <Checkbox
                                        checked={serviceTypeIds?.indexOf(planType.id) > -1}
                                        onChange={(event) => this.changePlanType(event, planType.id)}
                                        className="checkbox_radio"
                                      />
                                    }
                                    label={planType.service_type === RESERVATION_MOBI_TYPE[0].id ? t(RESERVATION_MOBI_TYPE[0].i18n) : t(RESERVATION_MOBI_TYPE[1].i18n)}
                                    key={index}
                                  />
                                ),
                            )}
                            <div>
                              {this.validator.message('plan_type_ids', this.state.serviceTypeIds, 'required')}
                              {this.state.isSubmitForm && !this.validator.check(this.state.serviceTypeIds, 'required') && (
                                <FormHelperText id="plan_type_ids" error>
                                  {t('validation.required', {field: t('common.planType')})}
                                </FormHelperText>
                              )}
                            </div>
                          </FormControl>
                        </Grid>
                      </Grid>

                      {/* Plan Name */}
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('business_plan.package_name')} <span className="font_color_red">＊</span>
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          <FormControl fullWidth>
                            <Box display="flex" flexDirection="column">
                              <TextField
                                label={<span>({t('common.ja')})</span>}
                                fullWidth
                                margin="dense"
                                placeholder={t('placeholder.required', {field: t('business_plan.package_name')})}
                                rows={2}
                                multiline
                                variant="outlined"
                                inputProps={{maxLength: 50}}
                                onChange={(event) => !event.target.value.includes('\n') && this.setState({name_jp: event.target.value})}
                                value={this.state.name_jp || ''}
                              />
                              <div style={{fontSize: 14, marginBottom: 5}}>{t('validation.max.label', {value: 50})}</div>
                            </Box>
                            {this.validator.message('name_jp', this.state.name_jp.trim(), 'required')}
                            {this.state.isSubmitForm && !this.validator.check(this.state.name_jp.trim(), 'required') && (
                              <FormHelperText id="name_jp" error>
                                {t('validation.required', {field: `${t('business_plan.package_name')} (${t('common.ja')})`})}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                        <Grid container justify="center" alignItems="center" item xs={1}>
                          <CompareArrowsIcon className="compare_arrow_icon"></CompareArrowsIcon>
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          <FormControl fullWidth>
                            <Box display="flex" flexDirection="column">
                              <TextField
                                label={<span>({t('common.vi')})</span>}
                                fullWidth
                                margin="dense"
                                placeholder={t('placeholder.required', {field: t('business_plan.package_name')})}
                                rows={2}
                                multiline
                                variant="outlined"
                                inputProps={{maxLength: 50}}
                                onChange={(event) => !event.target.value.includes('\n') && this.setState({name_vi: event.target.value})}
                                value={this.state.name_vi || ''}
                              />
                              <div style={{fontSize: 14, marginBottom: 5}}>{t('validation.max.label', {value: 50})}</div>
                            </Box>
                            {this.validator.message('name_vi', this.state.name_vi.trim(), 'required')}
                            {this.state.isSubmitForm && !this.validator.check(this.state.name_vi.trim(), 'required') && (
                              <FormHelperText id="name_vi" error>
                                {t('validation.required', {field: `${t('business_plan.package_name')} (${t('common.vi')})`})}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                        <Grid container justify="center" alignItems="center" item xs={1}>
                          <CompareArrowsIcon className="compare_arrow_icon"></CompareArrowsIcon>
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          <FormControl fullWidth>
                            <Box display="flex" flexDirection="column">
                              <TextField
                                label={<span>({t('common.en')})</span>}
                                fullWidth
                                margin="dense"
                                placeholder={t('placeholder.required', {field: t('business_plan.package_name')})}
                                rows={2}
                                multiline
                                variant="outlined"
                                inputProps={{maxLength: 50}}
                                onChange={(event) => !event.target.value.includes('\n') && this.setState({name_en: event.target.value})}
                                value={this.state.name_en || ''}
                              />
                              <div style={{fontSize: 14, marginBottom: 5}}>{t('validation.max.label', {value: 50})}</div>
                            </Box>
                            {this.validator.message('name_en', this.state.name_en.trim(), 'required')}
                            {this.state.isSubmitForm && !this.validator.check(this.state.name_en.trim(), 'required') && (
                              <FormHelperText id="name_en" error>
                                {t('validation.required', {field: `${t('business_plan.package_name')} (${t('common.en')})`})}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                      </Grid>

                      {/* Description */}
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('business_plan.description')} <span className="font_color_red">＊</span>
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          <FormControl fullWidth>
                            <Box display="flex" flexDirection="column">
                              <TextField
                                label={<span>({t('common.ja')})</span>}
                                fullWidth
                                margin="dense"
                                placeholder={t('placeholder.required', {field: t('business_plan.description')})}
                                rows={7}
                                multiline
                                variant="outlined"
                                inputProps={{maxLength: 1000}}
                                onChange={(event) => this.setState({description_jp: event.target.value})}
                                value={this.state.description_jp || ''}
                              />
                              <div style={{fontSize: 14, marginBottom: 5}}>{t('validation.max.label', {value: 1000})}</div>
                            </Box>
                            {this.validator.message('description_jp', this.state.description_jp.trim(), 'required')}
                            {this.state.isSubmitForm && !this.validator.check(this.state.description_jp.trim(), 'required') && (
                              <FormHelperText id="description_jp" error>
                                {t('validation.required', {field: `${t('business_plan.description')} (${t('common.ja')})`})}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                        <Grid container justify="center" alignItems="center" item xs={1}>
                          <CompareArrowsIcon className="compare_arrow_icon"></CompareArrowsIcon>
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          <FormControl fullWidth>
                            <Box display="flex" flexDirection="column">
                              <TextField
                                label={<span>({t('common.vi')})</span>}
                                fullWidth
                                margin="dense"
                                placeholder={t('placeholder.required', {field: t('business_plan.description')})}
                                rows={7}
                                multiline
                                variant="outlined"
                                inputProps={{maxLength: 1000}}
                                onChange={(event) => this.setState({description_vi: event.target.value})}
                                value={this.state.description_vi || ''}
                              />
                              <div style={{fontSize: 14, marginBottom: 5}}>{t('validation.max.label', {value: 1000})}</div>
                            </Box>
                            {this.validator.message('description_vi', this.state.description_vi.trim(), 'required')}
                            {this.state.isSubmitForm && !this.validator.check(this.state.description_vi.trim(), 'required') && (
                              <FormHelperText id="description_vi" error>
                                {t('validation.required', {field: `${t('business_plan.description')} (${t('common.vi')})`})}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                        <Grid container justify="center" alignItems="center" item xs={1}>
                          <CompareArrowsIcon className="compare_arrow_icon"></CompareArrowsIcon>
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          <FormControl fullWidth>
                            <Box display="flex" flexDirection="column">
                              <TextField
                                label={<span>({t('common.en')})</span>}
                                fullWidth
                                margin="dense"
                                placeholder={t('placeholder.required', {field: t('business_plan.description')})}
                                rows={7}
                                multiline
                                variant="outlined"
                                inputProps={{maxLength: 1000}}
                                onChange={(event) => this.setState({description_en: event.target.value})}
                                value={this.state.description_en || ''}
                              />
                              <div style={{fontSize: 14, marginBottom: 5}}>{t('validation.max.label', {value: 1000})}</div>
                            </Box>
                            {this.validator.message('description_en', this.state.description_en.trim(), 'required')}
                            {this.state.isSubmitForm && !this.validator.check(this.state.description_en.trim(), 'required') && (
                              <FormHelperText id="description_en" error>
                                {t('validation.required', {field: `${t('business_plan.description')} (${t('common.en')})`})}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                      </Grid>

                      {/* Show promotion details for app */}
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('business_plan.show_promotion_app')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={10} className="grid_title_padding">
                          <FormControl variant="outlined" margin="dense">
                            <Checkbox
                              name="isShowPromoApp"
                              checked={this.state.isShowPromoApp}
                              value={this.state.isShowPromoApp}
                              onChange={(event) => onChangeCheckBox(this, event)}
                              className="checkbox_radio"
                            />
                          </FormControl>
                        </Grid>
                      </Grid>

                      {/* Promotion Title */}
                      {this.state.isShowPromoApp && (
                        <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                          <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                            {t('business_plan.promotion_title')} <span className="font_color_red">＊</span>
                          </Grid>
                          <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                            <FormControl fullWidth>
                              <Box display="flex" flexDirection="column">
                                <TextField
                                  label={<span>({t('common.ja')})</span>}
                                  fullWidth
                                  margin="dense"
                                  placeholder={t('placeholder.required', {field: t('business_plan.promotion_title')})}
                                  rows={2}
                                  multiline
                                  variant="outlined"
                                  inputProps={{maxLength: 50}}
                                  onChange={(event) => !event.target.value.includes('\n') && this.setState({promotion_title_jp: event.target.value})}
                                  value={this.state.promotion_title_jp || ''}
                                />
                                <div style={{fontSize: 14, marginBottom: 5}}>{t('validation.max.label', {value: 50})}</div>
                              </Box>
                              {this.validator.message('promotion_title_jp', this.state.promotion_title_jp.trim(), 'required')}
                              {this.state.isSubmitForm && !this.validator.check(this.state.promotion_title_jp.trim(), 'required') && (
                                <FormHelperText id="promotion_title_jp" error>
                                  {t('validation.required', {field: `${t('business_plan.promotion_title')} (${t('common.ja')})`})}
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Grid>
                          <Grid container justify="center" alignItems="center" item xs={1}>
                            <CompareArrowsIcon className="compare_arrow_icon"></CompareArrowsIcon>
                          </Grid>
                          <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                            <FormControl fullWidth>
                              <Box display="flex" flexDirection="column">
                                <TextField
                                  label={<span>({t('common.vi')})</span>}
                                  fullWidth
                                  margin="dense"
                                  placeholder={t('placeholder.required', {field: t('business_plan.promotion_title')})}
                                  rows={2}
                                  multiline
                                  variant="outlined"
                                  inputProps={{maxLength: 50}}
                                  onChange={(event) => !event.target.value.includes('\n') && this.setState({promotion_title_vi: event.target.value})}
                                  value={this.state.promotion_title_vi || ''}
                                />
                                <div style={{fontSize: 14, marginBottom: 5}}>{t('validation.max.label', {value: 50})}</div>
                              </Box>
                              {this.validator.message('promotion_title_vi', this.state.promotion_title_vi.trim(), 'required')}
                              {this.state.isSubmitForm && !this.validator.check(this.state.promotion_title_vi.trim(), 'required') && (
                                <FormHelperText id="promotion_title_vi" error>
                                  {t('validation.required', {field: `${t('business_plan.promotion_title')} (${t('common.vi')})`})}
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Grid>
                          <Grid container justify="center" alignItems="center" item xs={1}>
                            <CompareArrowsIcon className="compare_arrow_icon"></CompareArrowsIcon>
                          </Grid>
                          <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                            <FormControl fullWidth>
                              <Box display="flex" flexDirection="column">
                                <TextField
                                  label={<span>({t('common.en')})</span>}
                                  fullWidth
                                  margin="dense"
                                  placeholder={t('placeholder.required', {field: t('business_plan.promotion_title')})}
                                  rows={2}
                                  multiline
                                  variant="outlined"
                                  inputProps={{maxLength: 50}}
                                  onChange={(event) => !event.target.value.includes('\n') && this.setState({promotion_title_en: event.target.value})}
                                  value={this.state.promotion_title_en || ''}
                                />
                                <div style={{fontSize: 14, marginBottom: 5}}>{t('validation.max.label', {value: 50})}</div>
                              </Box>
                              {this.validator.message('promotion_title_en', this.state.promotion_title_en.trim(), 'required')}
                              {this.state.isSubmitForm && !this.validator.check(this.state.promotion_title_en.trim(), 'required') && (
                                <FormHelperText id="promotion_title_en" error>
                                  {t('validation.required', {field: `${t('business_plan.promotion_title')} (${t('common.en')})`})}
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Grid>
                        </Grid>
                      )}

                      {/* Promotion Description */}
                      {this.state.isShowPromoApp && (
                        <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                          <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                            {t('business_plan.promotion_description')} <span className="font_color_red">＊</span>
                          </Grid>
                          <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                            <FormControl fullWidth>
                              <Box display="flex" flexDirection="column">
                                <TextField
                                  label={<span>({t('common.ja')})</span>}
                                  fullWidth
                                  margin="dense"
                                  placeholder={t('placeholder.required', {field: t('business_plan.promotion_description')})}
                                  rows={7}
                                  multiline
                                  variant="outlined"
                                  inputProps={{maxLength: 255}}
                                  onChange={(event) => this.setState({promotion_description_jp: event.target.value})}
                                  value={this.state.promotion_description_jp || ''}
                                />
                                <div style={{fontSize: 14, marginBottom: 5}}>{t('validation.max.label', {value: 255})}</div>
                              </Box>
                              {this.validator.message('promotion_description_jp', this.state.promotion_description_jp.trim(), 'required')}
                              {this.state.isSubmitForm && !this.validator.check(this.state.promotion_description_jp.trim(), 'required') && (
                                <FormHelperText id="description_jp" error>
                                  {t('validation.required', {field: `${t('business_plan.promotion_description')} (${t('common.ja')})`})}
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Grid>
                          <Grid container justify="center" alignItems="center" item xs={1}>
                            <CompareArrowsIcon className="compare_arrow_icon"></CompareArrowsIcon>
                          </Grid>
                          <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                            <FormControl fullWidth>
                              <Box display="flex" flexDirection="column">
                                <TextField
                                  label={<span>({t('common.vi')})</span>}
                                  fullWidth
                                  margin="dense"
                                  placeholder={t('placeholder.required', {field: t('business_plan.promotion_description')})}
                                  rows={7}
                                  multiline
                                  variant="outlined"
                                  inputProps={{maxLength: 255}}
                                  onChange={(event) => this.setState({promotion_description_vi: event.target.value})}
                                  value={this.state.promotion_description_vi || ''}
                                />
                                <div style={{fontSize: 14, marginBottom: 5}}>{t('validation.max.label', {value: 255})}</div>
                              </Box>
                              {this.validator.message('promotion_description_vi', this.state.promotion_description_vi.trim(), 'required')}
                              {this.state.isSubmitForm && !this.validator.check(this.state.promotion_description_vi.trim(), 'required') && (
                                <FormHelperText id="promotion_description_vi" error>
                                  {t('validation.required', {field: `${t('business_plan.promotion_description')} (${t('common.vi')})`})}
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Grid>
                          <Grid container justify="center" alignItems="center" item xs={1}>
                            <CompareArrowsIcon className="compare_arrow_icon"></CompareArrowsIcon>
                          </Grid>
                          <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                            <FormControl fullWidth>
                              <Box display="flex" flexDirection="column">
                                <TextField
                                  label={<span>({t('common.en')})</span>}
                                  fullWidth
                                  margin="dense"
                                  placeholder={t('placeholder.required', {field: t('business_plan.promotion_description')})}
                                  rows={7}
                                  multiline
                                  variant="outlined"
                                  inputProps={{maxLength: 255}}
                                  onChange={(event) => this.setState({promotion_description_en: event.target.value})}
                                  value={this.state.promotion_description_en || ''}
                                />
                                <div style={{fontSize: 14, marginBottom: 5}}>{t('validation.max.label', {value: 255})}</div>
                              </Box>
                              {this.validator.message('promotion_description_en', this.state.promotion_description_en.trim(), 'required')}
                              {this.state.isSubmitForm && !this.validator.check(this.state.promotion_description_en.trim(), 'required') && (
                                <FormHelperText id="promotion_description_en" error>
                                  {t('validation.required', {field: `${t('business_plan.promotion_description')} (${t('common.en')})`})}
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Grid>
                        </Grid>
                      )}

                      {/* Amount type */}
                      {this.state.plan_category === 'TICKET' && (
                        <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                          <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                            {t('ticket.amount_type')} <span className="font_color_red">＊</span>
                          </Grid>
                          <Grid container alignItems="center" item xs={6} lg={10} className="grid_title_padding">
                            <FormControl variant="outlined" margin="dense">
                              <Select
                                variant="outlined"
                                className="field_size_20 field_min_size_300"
                                margin="dense"
                                displayEmpty
                                renderValue={
                                  this.state.amount_type ?
                                    undefined :
                                    () => (
                                        <div className="font-12 color-disabled">
                                          {t('placeholder.required_select', {
                                            field: t('ticket.amount_type'),
                                          })}
                                        </div>
                                      )
                                }
                                value={this.state.amount_type || ''}
                                onChange={(event) => this.setState({amount_type: event.target.value})}
                              >
                                {TICKET_AMOUNT_TYPE.map((item, idx) => {
                                  return (
                                    <MenuItem value={item.value} key={idx}>
                                      {t(item.i18n)}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                              {this.validator.message('amount_type', this.state.amount_type, plan_category === 'TICKET' ? 'required' : '')}
                              {this.state.isSubmitForm && !this.validator.check(this.state.amount_type, 'required') && (
                                <FormHelperText id="amount_type" error>
                                  {t('validation.required.choose', {field: t('ticket.amount_type')})}
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Grid>
                        </Grid>
                      )}

                      {/* Days */}
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue ">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('business_plan.number_day')} <span className="font_color_red">＊</span>
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          <FormControl error>
                            <TextField
                              className="field_size_10 field_min_size_300"
                              margin="dense"
                              type="number"
                              inputProps={{
                                name: 'number_of_unit',
                                min: 1,
                              }}
                              placeholder={t('placeholder.required', {field: t('business_plan.number_day')})}
                              variant="outlined"
                              onChange={(event) => (event.target.value === '' || Number(event.target.value) !== 0) && onChangeTextFieldNumber(this, event)}
                              onKeyDown={preventInvalidChars}
                              value={this.state.number_of_unit}
                            />
                            {this.validator.message('number_of_unit', this.state.number_of_unit, 'required')}
                            {this.state.isSubmitForm && !this.validator.check(this.state.number_of_unit, 'required') && (
                              <FormHelperText id="name" error>
                                {t('validation.required', {field: t('business_plan.number_day')})}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                      </Grid>

                      {plan_category === 'PLAN' && (
                        <>
                          <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                            <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                              {t('business_plan.free_time')} <span className="font_color_red">＊</span>
                            </Grid>
                            <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                              <FormControl error>
                                <TextField
                                  className="field_size_10 field_min_size_300"
                                  margin="dense"
                                  type="number"
                                  inputProps={{
                                    name: 'freeStartingTime',
                                    min: 0,
                                  }}
                                  placeholder={t('placeholder.required', {field: t('business_plan.free_time')})}
                                  variant="outlined"
                                  onChange={(event) => onChangeTextFieldNumber(this, event)}
                                  onKeyDown={preventInvalidChars}
                                  value={this.state.freeStartingTime}
                                />
                                {this.validator.message('freeStartingTime', this.state.freeStartingTime, this.state.plan_category === 'PLAN' ? 'required' : '')}
                                {this.state.isSubmitForm && !this.validator.check(this.state.freeStartingTime, 'required') && (
                                  <FormHelperText id="name" error>
                                    {t('validation.required', {field: t('business_plan.free_time')})}
                                  </FormHelperText>
                                )}
                              </FormControl>
                            </Grid>
                          </Grid>
                          <Grid container spacing={1} className="row_form_item table_background_color_aliceblue" style={{paddingBottom: 12}}>
                            <Grid container alignItems="center" item xs={6} style={{padding: 0}}>
                              <Grid container className="row_form_item table_background_color_aliceblue" style={{paddingTop: 10, paddingBottom: 10, borderBottom: 'none'}}>
                                {/* Currency */}
                                <Grid container alignItems="center" item xs={6} lg={4} className="grid_title_padding" style={{marginBottom: 10}}>
                                  {t('business_plan.unit')}
                                  <span className="font_color_red">＊</span>
                                </Grid>
                                <Grid container alignItems="center" item xs={6} lg={8} className="grid_title_padding" style={{marginBottom: 10}}>
                                  <FormControl variant="outlined" margin="dense">
                                    <Select
                                      variant="outlined"
                                      className="field_size_20 field_min_size_300"
                                      margin="dense"
                                      inputProps={{
                                        name: 'currency',
                                      }}
                                      disabled={true}
                                      displayEmpty
                                      renderValue={
                                        this.state.currency ?
                                          undefined :
                                          () => (
                                              <div className="font-12 color-disabled">
                                                {t('placeholder.required_select', {
                                                  field: t('business_plan.unit'),
                                                })}
                                              </div>
                                            )
                                      }
                                      value={this.state.currency}
                                      onChange={(event) => onChangeSelect(this, event)}
                                    >
                                      {CURRENCY.map((item, idx) => {
                                        return (
                                          <MenuItem value={item.id} key={idx}>
                                            {t(item.i18n)}
                                          </MenuItem>
                                        );
                                      })}
                                    </Select>
                                  </FormControl>
                                </Grid>

                                {/* Price */}
                                <Grid container alignItems="center" item xs={6} lg={4} className="grid_title_padding">
                                  {t('business_plan.price')} <span className="font_color_red">＊</span>
                                </Grid>
                                <Grid container alignItems="center" item xs={6} lg={8} className="grid_title_padding">
                                  <FormControl error>
                                    {this.state.price.map((item, index) => (
                                      <Box display="flex" key={index}>
                                        {this.state.price.length > 1 && <p className={'amount_number ' + (index < 9 ? 'first_nine_amount' : '')}>{index + 1}.</p>}
                                        <TextField
                                          className="field_size_20 field_min_size_300"
                                          margin="dense"
                                          placeholder={t('placeholder.required', {field: t('business_plan.price')})}
                                          variant="outlined"
                                          onChange={(event) => {
                                            const isUpdate = onChangeNumberCurrency(this, event, this.state.currency, false);
                                            if (isUpdate === false) return;
                                            const value = event.target.value;
                                            const {price} = this.state;
                                            price[index] = value;
                                            this.setState({price});
                                          }}
                                          value={item}
                                        />
                                        <Button
                                          className="btn_amount"
                                          color="primary"
                                          variant="contained"
                                          onClick={() => {
                                            if (this.state.price.length >= 36) return;
                                            const {price, extra_fee} = this.state;
                                            price.splice(index + 1, 0, 0);
                                            extra_fee.splice(index + 1, 0, 0);
                                            this.setState({price, extra_fee});
                                          }}
                                        >
                                          <AddIcon />
                                        </Button>
                                        {index !== 0 && (
                                          <Button
                                            className="btn_amount btn_remove_amount"
                                            color="primary"
                                            variant="contained"
                                            onClick={() => {
                                              if (index === 0) return;
                                              const {price, extra_fee} = this.state;
                                              price.splice(index, 1);
                                              extra_fee.splice(index, 1);
                                              this.setState({price, extra_fee});
                                            }}
                                          >
                                            <RemoveIcon />
                                          </Button>
                                        )}
                                      </Box>
                                    ))}
                                    {this.state.isSubmitForm && !this.checkListAmount() && (
                                      <FormHelperText id="price" error>
                                        {t('business_plan.validate_amount', {position: this.getEmptyAmount()})}
                                      </FormHelperText>
                                    )}
                                  </FormControl>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid container alignItems="center" item xs={5}>
                              {/* Limit family user */}
                              <Grid container spacing={1} className="row_form_item table_background_color_aliceblue" style={{borderBottom: 'none'}}>
                                <Grid container alignItems="center" justify="center" item xs={6} lg={4} className="grid_title_padding">
                                  {t('business_plan.family_option')}
                                </Grid>
                                <Grid container alignItems="center" justify="flex-end" item xs={6} lg={8} className="grid_title_padding">
                                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue" style={{borderBottom: 'none'}}>
                                    <FormControl error style={{marginBottom: 10}}>
                                      <TextField
                                        className="field_size_10 field_min_size_300"
                                        label={
                                          <span>
                                            {t('business_plan.number_of_people')} <span className="font_color_red">＊</span>
                                          </span>
                                        }
                                        margin="dense"
                                        type="number"
                                        variant="outlined"
                                        fullWidth
                                        inputProps={{
                                          name: 'max_user',
                                          min: 1,
                                        }}
                                        value={this.state.max_user}
                                        onChange={(event) => {
                                            onChangeTextFieldNumber(this, event);
                                          }
                                        }
                                        onKeyDown={preventInvalidChars}
                                      />
                                      {this.validator.message('max_user', this.state.max_user, plan_category === 'PLAN' ? 'required|between:1,7,num' : '')}
                                      {this.state.isSubmitForm && !this.validator.check(this.state.max_user, 'required') && (
                                        <FormHelperText id="max_user" error>
                                          {t('validation.required', {field: t('business_plan.number_of_people')})}
                                        </FormHelperText>
                                      )}
                                      {this.state.isSubmitForm &&
                                        this.validator.check(this.state.max_user, 'required') &&
                                        !this.validator.check(this.state.max_user, 'between:1,7,num') && (
                                          <FormHelperText id="max_user" error>
                                            {t('validation.between', {field: t('business_plan.number_of_people'), min: 1, max: 7})}
                                          </FormHelperText>
                                        )}
                                    </FormControl>
                                  </Grid>
                                  {this.state.max_user > 1 && (
                                    <Grid container spacing={1} className="row_form_item table_background_color_aliceblue" style={{borderBottom: 'none'}}>
                                      <FormControl error>
                                        {this.state.extra_fee.map((item, index) => (
                                          <Box display="flex">
                                            {this.state.extra_fee.length > 1 && <p className={'amount_number ' + (index < 9 ? 'first_nine_amount' : '')}>{index + 1}.</p>}
                                            <TextField
                                              className="field_size_10 field_min_size_300"
                                              label={
                                                <span>
                                                  {t('business_plan.extra_fee')} <span className="font_color_red">＊</span>
                                                </span>
                                              }
                                              margin="dense"
                                              variant="outlined"
                                              fullWidth
                                              value={item}
                                              onChange={(event) => {
                                                const isUpdate = onChangeNumberCurrency(this, event, this.state.currency, false);
                                                if (isUpdate === false) return;
                                                const value = event.target.value;
                                                const {extra_fee} = this.state;
                                                extra_fee[index] = value;
                                                this.setState({extra_fee});
                                              }}
                                            />
                                          </Box>
                                        ))}
                                        {this.state.isSubmitForm && !this.checkListExtraFee() && (
                                          <FormHelperText id="price" error>
                                            {t('business_plan.validate_extra_fee', {position: this.getEmptyExtraFee()})}
                                          </FormHelperText>
                                        )}
                                      </FormControl>
                                    </Grid>
                                  )}
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </>
                      )}

                      {plan_category === 'TICKET' && (
                        <>
                          {/* Ride limit */}
                          <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                            <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                              {t('ticket.ride_limit')} <span className="font_color_red">＊</span>
                            </Grid>
                            <Grid container alignItems="center" item xs={6} lg={10} className="grid_title_padding">
                              <FormControl className="field_min_size_300">
                                <TextField
                                  margin="dense"
                                  type="number"
                                  inputProps={{
                                    min: 1,
                                    name: 'ride_limit',
                                  }}
                                  placeholder={t('placeholder.required', {field: t('ticket.ride_limit')})}
                                  variant="outlined"
                                  onChange={(event) => (event.target.value === '' || Number(event.target.value) !== 0) && onChangeTextFieldNumber(this, event)}
                                  onKeyDown={preventInvalidChars}
                                  value={this.state.ride_limit || ''}
                                />
                                {this.validator.message('ride_limit', this.state.ride_limit, plan_category === 'TICKET' ? 'required' : '')}
                                {this.state.isSubmitForm && !this.validator.check(this.state.ride_limit, 'required') && (
                                  <FormHelperText id="ride_limit" error>
                                    {t('validation.required', {field: t('ticket.ride_limit')})}
                                  </FormHelperText>
                                )}
                              </FormControl>
                            </Grid>
                          </Grid>
                          {/* Currency */}
                          <Grid container spacing={1} className="row_form_item table_background_color_aliceblue ">
                            <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                              {t('business_plan.unit')} <span className="font_color_red">＊</span>
                            </Grid>
                            <Grid container alignItems="center" item xs={6} lg={10} className="grid_title_padding">
                              <FormControl variant="outlined" margin="dense">
                                <Select
                                  variant="outlined"
                                  className="field_size_20 field_min_size_300"
                                  margin="dense"
                                  disabled={true}
                                  displayEmpty
                                  renderValue={
                                    this.state.currency ?
                                      undefined :
                                      () => (
                                          <div className="font-12 color-disabled">
                                            {t('placeholder.required_select', {
                                              field: t('business_plan.unit'),
                                            })}
                                          </div>
                                        )
                                  }
                                  value={this.state.currency}
                                >
                                  {CURRENCY.map((item, idx) => {
                                    return (
                                      <MenuItem value={item.id} key={idx}>
                                        {t(item.i18n)}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                            </Grid>
                          </Grid>
                          {/* Amount */}
                          <Grid container spacing={1} className="row_form_item table_background_color_aliceblue" style={{paddingBottom: 12}}>
                            <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                              {t('business_plan.price')} <span className="font_color_red">＊</span>
                            </Grid>
                            <Grid container alignItems="center" item xs={6} lg={10} className="grid_title_padding">
                              <FormControl className="field_min_size_300">
                                <TextField
                                  margin="dense"
                                  disabled={this.state.for_gift}
                                  placeholder={t('placeholder.required', {field: t('business_plan.price')})}
                                  name="amount"
                                  variant="outlined"
                                  onChange={(event) => onChangeNumberCurrency(this, event, this.state.currency, true)}
                                  value={this.state.amount}
                                />
                                {this.validator.message('amount', this.state.amount, plan_category === 'TICKET' ? 'required' : '')}
                                {this.state.isSubmitForm && !this.validator.check(this.state.amount, 'required') && (
                                  <FormHelperText id="amount" error>
                                    {t('validation.required', {field: t('business_plan.price')})}
                                  </FormHelperText>
                                )}
                              </FormControl>
                            </Grid>
                          </Grid>
                        </>
                      )}

                      {plan_category === 'TICKET' && (
                        <>
                          <Grid container spacing={1} className="row_form_item">
                            <Grid
                              container
                              alignItems="center"
                              item
                              xs={12}
                              className="product_group_table_header_info font_color_white"
                              style={{
                                fontSize: 16,
                                paddingTop: 15,
                                paddingBottom: 15,
                                paddingLeft: 20,
                                paddingRight: 20,
                              }}
                            >
                              {t('business_plan.additional_information')}
                            </Grid>
                          </Grid>
                          {/* Additional purchase */}
                          <Grid container spacing={1} className="row_form_item table_background_color_aliceblue" style={{paddingBottom: 12, paddingTop: 12}}>
                            <Grid container className="row_form_item table_background_color_aliceblue" style={{paddingTop: 10, paddingBottom: 10, borderBottom: 'none'}}>
                              {/* Additional ride limit */}
                              <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                                {t('ticket.additional_purchase')} {this.state.ticket_additional_ride_limit.length > 0 && <span className="font_color_red">＊</span>}
                              </Grid>
                              <Grid container alignItems="center" item xs={6} lg={10} className={this.state.ticket_additional_ride_limit.length === 0 ? '' : 'grid_title_padding'}>
                                {this.state.ticket_additional_ride_limit.length === 0 && (
                                  <Button
                                    className="btn_amount"
                                    color="primary"
                                    variant="contained"
                                    disabled={this.state.for_gift}
                                    onClick={() => this.setState({ticket_additional_ride_limit: [''], ticket_additional_amount: ['']})}
                                  >
                                    <AddIcon />
                                  </Button>
                                )}
                                <Grid container spacing={1}>
                                  <Grid container alignItems="center" item xs={4}>
                                    <FormControl>
                                      {this.state.ticket_additional_ride_limit.map((item, index) => (
                                        <Box display="flex" key={index}>
                                          <p className={'amount_number ' + (index < 9 ? 'first_nine_amount' : '')}>{index + 1}.</p>
                                          <TextField
                                            className="field_size_20 field_min_size_300"
                                            margin="dense"
                                            type="number"
                                            inputProps={{
                                              min: 1,
                                            }}
                                            disabled={!this.state.ride_limit}
                                            placeholder={t('placeholder.required', {field: t('ticket.ride_limit')})}
                                            variant="outlined"
                                            onChange={(event) => {
                                              let value = event.target.value;
                                              if (event.target.value.includes('.')) value = value.split('.').join('');
                                              if (event.target.value.includes(',')) value = value.split(',').join('');
                                              const {ticket_additional_ride_limit, ride_limit} = this.state;
                                              if ((value === '' || Number(value) !== 0) && Number(value) <= Number(ride_limit)) ticket_additional_ride_limit[index] = value;
                                              this.setState({ticket_additional_ride_limit});
                                            }}
                                            onKeyDown={preventInvalidChars}
                                            value={item}
                                          />
                                          <p style={{fontSize: 18, marginLeft: 10, marginBottom: 0}}>{t('ticket.ride', {count: Number(item) || 0})}</p>
                                        </Box>
                                      ))}
                                      {this.state.isSubmitForm && !this.checkListTicketAdditionalRideLimit() && (
                                        <FormHelperText id="validate_ride_limit" error>
                                          {t('ticket.validate_ride_limit', {position: this.getEmptyAdditionalTicketRideLimit()})}
                                        </FormHelperText>
                                      )}
                                    </FormControl>
                                  </Grid>
                                  <Grid container alignItems="center" item xs={5}>
                                    {/* Additional amount */}
                                    <Grid container spacing={1} className="row_form_item table_background_color_aliceblue" style={{borderBottom: 'none'}}>
                                      {this.state.ticket_additional_amount.length > 0 && (
                                        <Grid container alignItems="center" justify="center" item xs={6} lg={4} className="grid_title_padding">
                                          {t('business_plan.price')} <span className="font_color_red">＊</span>
                                        </Grid>
                                      )}
                                      <Grid container alignItems="center" justify="flex-end" item xs={6} lg={8} className="grid_title_padding">
                                        <Grid container spacing={1} className="row_form_item table_background_color_aliceblue" style={{borderBottom: 'none'}}>
                                          <FormControl>
                                            {this.state.ticket_additional_amount.map((item, index) => (
                                              <Box display="flex" key={index}>
                                                <p className={'amount_number ' + (index < 9 ? 'first_nine_amount' : '')}>{index + 1}.</p>
                                                <TextField
                                                  className="field_size_10 field_min_size_300"
                                                  placeholder={t('placeholder.required', {field: t('business_plan.price')})}
                                                  margin="dense"
                                                  variant="outlined"
                                                  fullWidth
                                                  value={item}
                                                  onChange={(event) => {
                                                    const isUpdate = onChangeNumberCurrency(this, event, this.state.currency, false);
                                                    if (isUpdate === false) return;
                                                    const value = event.target.value;
                                                    const {ticket_additional_amount} = this.state;
                                                    if (value === '' || Number(value) !== 0) ticket_additional_amount[index] = value;
                                                    this.setState({ticket_additional_amount});
                                                  }}
                                                />
                                                <Button
                                                  className="btn_amount"
                                                  color="primary"
                                                  variant="contained"
                                                  onClick={() => {
                                                    if (this.state.ticket_additional_amount.length >= 36) return;
                                                    const {ticket_additional_ride_limit, ticket_additional_amount} = this.state;
                                                    ticket_additional_ride_limit.splice(index + 1, 0, '');
                                                    ticket_additional_amount.splice(index + 1, 0, '');
                                                    this.setState({ticket_additional_ride_limit, ticket_additional_amount});
                                                  }}
                                                >
                                                  <AddIcon />
                                                </Button>
                                                <Button
                                                  className="btn_amount btn_remove_amount"
                                                  color="primary"
                                                  variant="contained"
                                                  onClick={() => {
                                                    const {ticket_additional_ride_limit, ticket_additional_amount} = this.state;
                                                    ticket_additional_ride_limit.splice(index, 1);
                                                    ticket_additional_amount.splice(index, 1);
                                                    this.setState({ticket_additional_ride_limit, ticket_additional_amount});
                                                  }}
                                                >
                                                  <RemoveIcon />
                                                </Button>
                                              </Box>
                                            ))}
                                            {this.state.isSubmitForm && !this.checkListTicketAdditionalAmount() && (
                                              <FormHelperText id="validate_additional_amount" error>
                                                {t('business_plan.validate_amount', {position: this.getEmptyAdditionalTicketAmount()})}
                                              </FormHelperText>
                                            )}
                                          </FormControl>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                          {/* One Time Discount */}
                          <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                            <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                              {t('business_plan.one_time_discount')}
                            </Grid>
                            <Grid container alignItems="center" className="grid_title_padding" item xs={6} lg={9}>
                              <FormControl variant="outlined" margin="dense">
                                <Select
                                  variant="outlined"
                                  className="field_size_20 field_min_size_300"
                                  margin="dense"
                                  inputProps={{
                                    name: 'oneTimeDiscountStatus',
                                  }}
                                  displayEmpty
                                  renderValue={
                                    this.state.oneTimeDiscountStatus !== '' ?
                                      undefined :
                                      () => (
                                          <div className="font-12 color-disabled">
                                            {t('placeholder.required_select', {
                                              field: t('common.status'),
                                            })}
                                          </div>
                                        )
                                  }
                                  value={this.state.oneTimeDiscountStatus}
                                  onChange={(event) => onChangeSelect(this, event)}
                                >
                                  {COMMON_STATUS.map((item, idx) => {
                                    return (
                                      <MenuItem value={item.id} key={idx}>
                                        {t(item.i18n)}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                              {this.state.oneTimeDiscountStatus === 'ENABLE' && (
                                <Button color="primary" variant="contained" className="margin-left-24 margin-right-24" onClick={() => this.setState({flagListDiscount: true})}>
                                  {t('business_plan.list_detail')}
                                </Button>
                              )}
                              {this.state.oneTimeDiscountStatus === 'ENABLE' && this.validator.message('dataDiscount', this.state.dataDiscount, 'required')}
                              {this.state.oneTimeDiscountStatus === 'ENABLE' && this.state.isSubmitForm && !this.validator.check(this.state.dataDiscount, 'required') && (
                                <FormHelperText id="one_time_discount" error>
                                  {t('validation.required', {field: t('business_plan.one_time_discount')})}
                                </FormHelperText>
                              )}
                              {this.state.oneTimeDiscountStatus === 'ENABLE' &&
                                this.state.isSubmitForm &&
                                !this.handleCheckDataGeofenceWithDataDiscount() &&
                                this.validator.check(this.state.dataDiscount, 'required') && (
                                  <FormHelperText id="one_time_discount" error>
                                    {t('validation.required.update', {field: t('business_plan.one_time_discount')})}
                                    <br />
                                  </FormHelperText>
                                )}
                              {this.state.oneTimeDiscountStatus === 'ENABLE' &&
                                (this.state.isSubmitModalDiscount || this.state.isSubmitForm) &&
                                this.handleCheckDataUserDiscount() && (
                                  <FormHelperText id="one_time_discount" error>
                                    {t('business_plan.validate_user')}
                                  </FormHelperText>
                                )}
                            </Grid>
                          </Grid>
                          {/* One Time Discount Icon */}
                          {this.state.oneTimeDiscountStatus === 'ENABLE' && (
                            <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                              <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                                {t('business_plan.one_time_discount_icon')} <span className="font_color_red">＊</span>
                              </Grid>
                              <Grid container alignItems="center" className="grid_title_padding" item xs={3} lg={2}>
                                {this.state.image_master_url && (
                                  <div className="box_image" style={{width: '50px', height: '50px'}}>
                                    <img className="cropped_image" style={{width: 'inherit', height: 'inherit'}} src={this.state.image_master_url} alt="cropped" />
                                  </div>
                                )}
                                {/* TODO Select Image From Image Master */}
                                {/* <Button color="primary" variant="contained" className="margin-left-24" onClick={() => this.openImageModal()}>
                                  {t('business_plan.icon')}
                                </Button> */}
                                {this.validator.message('image_master_url', this.state.image_master_url, 'required')}
                                {this.state.isSubmitForm && !this.validator.check(this.state.image_master_url, 'required') && (
                                  <FormHelperText id="image_master_url" error>
                                    {t('validation.required.choose', {field: t('business_plan.one_time_discount_icon')})}
                                  </FormHelperText>
                                )}
                              </Grid>
                            </Grid>
                          )}
                          {/* One Time Discount Description */}
                          {this.state.oneTimeDiscountStatus === 'ENABLE' && (
                            <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                              <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                                {t('business_plan.one_time_discount_description')} <span className="font_color_red">＊</span>
                              </Grid>
                              <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                                <FormControl fullWidth>
                                  <Box display="flex" flexDirection="column">
                                    <TextField
                                      label={<span>({t('common.ja')})</span>}
                                      fullWidth
                                      margin="dense"
                                      placeholder={t('placeholder.required', {field: t('business_plan.one_time_discount_description')})}
                                      rows={7}
                                      multiline
                                      variant="outlined"
                                      inputProps={{maxLength: 100}}
                                      onChange={(event) => this.setState({one_time_discount_description_jp: event.target.value})}
                                      value={this.state.one_time_discount_description_jp || ''}
                                    />
                                    <div style={{fontSize: 14, marginBottom: 5}}>{t('validation.max.label', {value: 100})}</div>
                                  </Box>
                                  {this.validator.message('one_time_discount_description_jp', this.state.one_time_discount_description_jp.trim(), 'required|max:100')}
                                  {this.state.isSubmitForm && !this.validator.check(this.state.one_time_discount_description_jp.trim(), 'required') && (
                                    <FormHelperText id="one_time_discount_description_jp" error>
                                      {t('validation.required', {field: `${t('business_plan.one_time_discount_description')} (${t('common.ja')})`})}
                                    </FormHelperText>
                                  )}
                                  {this.state.isSubmitForm &&
                                    this.validator.check(this.state.one_time_discount_description_jp.trim(), 'required') &&
                                    !this.validator.check(this.state.one_time_discount_description_jp.trim(), 'max:100') && (
                                      <FormHelperText id="one_time_discount_description_jp" error>
                                        {t('errorFields.textLength', {value: 100})}
                                      </FormHelperText>
                                    )}
                                </FormControl>
                              </Grid>
                              <Grid container justify="center" alignItems="center" item xs={1}>
                                <CompareArrowsIcon className="compare_arrow_icon"></CompareArrowsIcon>
                              </Grid>
                              <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                                <FormControl fullWidth>
                                  <Box display="flex" flexDirection="column">
                                    <TextField
                                      label={<span>({t('common.vi')})</span>}
                                      fullWidth
                                      margin="dense"
                                      placeholder={t('placeholder.required', {field: t('business_plan.one_time_discount_description')})}
                                      rows={7}
                                      multiline
                                      variant="outlined"
                                      inputProps={{maxLength: 100}}
                                      onChange={(event) => this.setState({one_time_discount_description_vi: event.target.value})}
                                      value={this.state.one_time_discount_description_vi || ''}
                                    />
                                    <div style={{fontSize: 14, marginBottom: 5}}>{t('validation.max.label', {value: 100})}</div>
                                  </Box>
                                  {this.validator.message('one_time_discount_description_vi', this.state.one_time_discount_description_vi.trim(), 'required|max:100')}
                                  {this.state.isSubmitForm && !this.validator.check(this.state.one_time_discount_description_vi.trim(), 'required') && (
                                    <FormHelperText id="one_time_discount_description_vi" error>
                                      {t('validation.required', {field: `${t('business_plan.one_time_discount_description')} (${t('common.vi')})`})}
                                    </FormHelperText>
                                  )}
                                  {this.state.isSubmitForm &&
                                    this.validator.check(this.state.one_time_discount_description_vi.trim(), 'required') &&
                                    !this.validator.check(this.state.one_time_discount_description_vi.trim(), 'max:100') && (
                                      <FormHelperText id="one_time_discount_description_vi" error>
                                        {t('errorFields.textLength', {value: 100})}
                                      </FormHelperText>
                                    )}
                                </FormControl>
                              </Grid>
                              <Grid container justify="center" alignItems="center" item xs={1}>
                                <CompareArrowsIcon className="compare_arrow_icon"></CompareArrowsIcon>
                              </Grid>
                              <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                                <FormControl fullWidth>
                                  <Box display="flex" flexDirection="column">
                                    <TextField
                                      label={<span>({t('common.en')})</span>}
                                      fullWidth
                                      margin="dense"
                                      placeholder={t('placeholder.required', {field: t('business_plan.one_time_discount_description')})}
                                      rows={7}
                                      multiline
                                      variant="outlined"
                                      inputProps={{maxLength: 100}}
                                      onChange={(event) => this.setState({one_time_discount_description_en: event.target.value})}
                                      value={this.state.one_time_discount_description_en || ''}
                                    />
                                    <div style={{fontSize: 14, marginBottom: 5}}>{t('validation.max.label', {value: 100})}</div>
                                  </Box>
                                  {this.validator.message('one_time_discount_description_en', this.state.one_time_discount_description_en.trim(), 'required|max:100')}
                                  {this.state.isSubmitForm && !this.validator.check(this.state.one_time_discount_description_en.trim(), 'required') && (
                                    <FormHelperText id="one_time_discount_description_en" error>
                                      {t('validation.required', {field: `${t('business_plan.one_time_discount_description')} (${t('common.en')})`})}
                                    </FormHelperText>
                                  )}
                                  {this.state.isSubmitForm &&
                                    this.validator.check(this.state.one_time_discount_description_en.trim(), 'required') &&
                                    !this.validator.check(this.state.one_time_discount_description_en.trim(), 'max:100') && (
                                      <FormHelperText id="one_time_discount_description_en" error>
                                        {t('errorFields.textLength', {value: 100})}
                                      </FormHelperText>
                                    )}
                                </FormControl>
                              </Grid>
                            </Grid>
                          )}
                        </>
                      )}

                      <Grid container spacing={1} className="row_form_item">
                        <Grid
                          container
                          alignItems="center"
                          item
                          xs={12}
                          className="product_group_table_header_info font_color_white"
                          style={{
                            fontSize: 16,
                            paddingTop: 15,
                            paddingBottom: 15,
                            paddingLeft: 20,
                            paddingRight: 20,
                          }}
                        >
                          {t('business_plan.operation_information')}
                        </Grid>
                      </Grid>

                      {/* Operating hours */}
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue" style={{paddingTop: 12}}>
                        <Grid container alignItems="center" item xs={2} className="grid_title_padding">
                          {t('business_plan.operation_time')} <span className="font_color_red">＊</span>
                        </Grid>
                        <Grid container alignItems="center" item xs={6} className="grid_title_padding">
                          <TableContainer>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell>{t('common.from')}</TableCell>
                                  <TableCell>{t('common.to')}</TableCell>
                                  <TableCell align="right">
                                    <Button
                                      color="primary"
                                      variant="contained"
                                      onClick={() => this.addItem()}
                                      disabled={listPlanTime?.length > 4 || (isEditForm && !permission.canUpdate)}
                                    >
                                      <AddIcon />
                                    </Button>
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {listPlanTime?.map((item, index) => {
                                  const isOverlap = this.state.overlapList.has(index);
                                  return (
                                    <TableRow key={index}>
                                      <TableCell align="left">
                                        <FormControl>
                                          <CustomTimePicker
                                            name="start_time"
                                            className="field_min_size_70 table_background_color_aliceblue"
                                            value={item.start_time || null}
                                            showSecond={false}
                                            allowEmpty
                                            onChange={(value) => this.changeOperatingHour(index, value, true)}
                                            format={TIME_FORMAT}
                                            use12Hours={false}
                                            autoComplete="off"
                                          />
                                          {this.validator.message('start_time', item.start_time, 'required')}
                                          {this.state.isSubmitForm &&
                                            ((!this.validator.check(item.start_time, 'required') && (
                                              <FormHelperText id="time" style={{color: 'red'}}>
                                                {t('validation.required', {field: t('vehicles.from')})}
                                              </FormHelperText>
                                            )) ||
                                              (!!!this.checkHoursUnit(item.start_time, item.end_time) && (
                                                <FormHelperText id={'time_range' + index} style={{color: 'red'}}>
                                                  {t('validation.invalid.timeRange')}
                                                </FormHelperText>
                                              )) ||
                                              (isOverlap && (
                                                <div>
                                                  <FormHelperText id="time" style={{color: 'red'}}>
                                                    {t('jit.overlap')}
                                                  </FormHelperText>
                                                </div>
                                              )))}
                                        </FormControl>
                                      </TableCell>
                                      <TableCell align="left">
                                        <FormControl>
                                          <CustomTimePicker
                                            name="end_time"
                                            className="field_min_size_70 table_background_color_aliceblue"
                                            value={item.end_time || null}
                                            showSecond={false}
                                            allowEmpty
                                            onChange={(value) => this.changeOperatingHour(index, value, false)}
                                            format={TIME_FORMAT}
                                            use12Hours={false}
                                            autoComplete="off"
                                          />
                                          {this.validator.message('end_time', item.end_time, 'required')}
                                          {this.state.isSubmitForm &&
                                            ((!this.validator.check(item.end_time, 'required') && (
                                              <FormHelperText id="time" style={{color: 'red'}}>
                                                {t('validation.required', {field: t('vehicles.to')})}
                                              </FormHelperText>
                                            )) ||
                                              (!!!this.checkHoursUnit(item.start_time, item.end_time) && (
                                                <FormHelperText id="time" style={{color: 'red'}}>
                                                  {t('validation.invalid.timeRange')}
                                                </FormHelperText>
                                              )))}
                                        </FormControl>
                                      </TableCell>
                                      <TableCell align="right">
                                        <Button
                                          color="secondary"
                                          variant="contained"
                                          onClick={() => this.deleteRows(index)}
                                          disabled={listPlanTime?.length === 1 || (isEditForm && !permission.canUpdate)}
                                        >
                                          <CancelIcon />
                                        </Button>
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                              </TableBody>
                            </Table>
                          </TableContainer>
                          {this.state.isSubmitForm && !this.validateOperatingTimeWithGeofenceTime() && (
                            <FormHelperText id="time_geofence" error>
                              {t('business_plan.validate_time')}
                            </FormHelperText>
                          )}
                        </Grid>
                      </Grid>

                      {/* Display Time */}
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue ">
                        <Grid container alignItems="center" item xs={12} lg={2} className="grid_title_padding">
                          {t('business_plan.display_times')}
                          <span className="font_color_red">＊</span>
                        </Grid>
                        <Grid container alignItems="center" item xs={6} className="grid_title_padding">
                          <TableContainer>
                            <Table>
                              <TableBody>
                                {display_times?.map((item, index) => {
                                  const isOverlap = this.state.overlapListDate.has(index);
                                  return (
                                    <TableRow key={index}>
                                      <TableCell>
                                        <p style={{lineHeight: '6px'}}>{t('common.from')}</p>
                                      </TableCell>
                                      <TableCell align="left">
                                        <FormControl>
                                          <KeyboardDatePicker
                                            KeyboardButtonProps={{
                                              'aria-label': 'change time',
                                            }}
                                            disableToolbar
                                            variant="inline"
                                            inputVariant="outlined"
                                            autoOk
                                            value={item.start_time || null}
                                            onChange={(value) => this.changeDisplayTime(index, value, true)}
                                            format={DATE_FORMAT}
                                            invalidDateMessage={t('errorFields.invalidDateMessage')}
                                            maxDateMessage={t('errorFields.maxDateMessage')}
                                            minDateMessage={t('errorFields.minDateMessage')}
                                          />
                                          {this.validator.message('start_time', item.start_time, 'required')}
                                          {this.state.isSubmitForm &&
                                            ((!this.validator.check(item.start_time, 'required') && (
                                              <FormHelperText id="time" style={{color: 'red'}}>
                                                {t('validation.required', {field: t('business_plan.start_time')})}
                                              </FormHelperText>
                                            )) ||
                                              (!!!compareDateTimeRange(item.start_time, item.end_time, true) && (
                                                <FormHelperText id="time" style={{color: 'red'}}>
                                                  {t('validation.invalid.timeRange')}
                                                </FormHelperText>
                                              )) ||
                                              (isOverlap && (
                                                <div>
                                                  <FormHelperText id="time" style={{color: 'red'}}>
                                                    {t('jit.overlap')}
                                                  </FormHelperText>
                                                </div>
                                              )))}
                                        </FormControl>
                                      </TableCell>
                                      <TableCell>
                                        <p style={{lineHeight: '6px'}}>{t('common.to')}</p>
                                      </TableCell>
                                      <TableCell align="left">
                                        <FormControl>
                                          <KeyboardDatePicker
                                            KeyboardButtonProps={{
                                              'aria-label': 'change time',
                                            }}
                                            disableToolbar
                                            variant="inline"
                                            inputVariant="outlined"
                                            autoOk
                                            value={item.end_time || null}
                                            onChange={(value) => this.changeDisplayTime(index, value, false)}
                                            format={DATE_FORMAT}
                                            invalidDateMessage={t('errorFields.invalidDateMessage')}
                                            maxDateMessage={t('errorFields.maxDateMessage')}
                                            minDateMessage={t('errorFields.minDateMessage')}
                                          />
                                          {this.validator.message('end_time', item.end_time, 'required')}
                                          {this.state.isSubmitForm &&
                                            ((!this.validator.check(item.end_time, 'required') && (
                                              <FormHelperText id="time" style={{color: 'red'}}>
                                                {t('validation.required', {field: t('business_plan.end_time')})}
                                              </FormHelperText>
                                            )) ||
                                              (!!!compareDateTimeRange(item.start_time, item.end_time, true) && (
                                                <FormHelperText id="time" style={{color: 'red'}}>
                                                  {t('validation.invalid.timeRange')}
                                                </FormHelperText>
                                              )) ||
                                              (isOverlap && <div style={{height: 48}}></div>))}
                                        </FormControl>
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Grid>
                      </Grid>

                      {/* Use Time */}
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue" style={{paddingBottom: 12}}>
                        <Grid container alignItems="center" item xs={12} lg={2} className="grid_title_padding">
                          {t('business_plan.use_time')}
                          <span className="font_color_red">＊</span>
                        </Grid>
                        <Grid container alignItems="center" item xs={6} className="grid_title_padding">
                          <TableContainer>
                            <Table>
                              <TableBody>
                                <TableRow>
                                  <TableCell>
                                    <p style={{lineHeight: '6px'}}>{t('common.from')}</p>
                                  </TableCell>
                                  <TableCell align="left">
                                    <FormControl>
                                      <KeyboardDatePicker
                                        KeyboardButtonProps={{
                                          'aria-label': 'change time',
                                        }}
                                        error={referrer_err}
                                        disableToolbar
                                        variant="inline"
                                        inputVariant="outlined"
                                        autoOk
                                        ampm={false}
                                        value={this.state.use_time.start_time || null}
                                        onChange={(value) => this.changeUseTime(value, 'start_time')}
                                        format={DATE_FORMAT}
                                        invalidDateMessage={t('errorFields.invalidDateMessage')}
                                        maxDateMessage={t('errorFields.maxDateMessage')}
                                        minDateMessage={t('errorFields.minDateMessage')}
                                      />

                                      {this.validator.message('start_time', this.state.use_time.start_time, 'required')}
                                      {this.state.isSubmitForm &&
                                        ((!this.validator.check(this.state.use_time.start_time, 'required') && (
                                          <FormHelperText id="time" style={{color: 'red'}}>
                                            {t('validation.required', {field: t('business_plan.start_time')})}
                                          </FormHelperText>
                                        )) ||
                                          (!compareDateTimeRange(this.state.use_time.start_time, this.state.use_time.end_time, true) && (
                                            <FormHelperText id="time" style={{color: 'red'}}>
                                              {t('validation.invalid.timeRange')}
                                            </FormHelperText>
                                          )))}
                                      {this.state.use_time.end_time && display_times[0].end_time && !this.checkEndTime() && (
                                        <>
                                          <br />
                                          <br />
                                          <br />
                                        </>
                                      )}
                                    </FormControl>
                                  </TableCell>
                                  <TableCell>
                                    <p style={{lineHeight: '6px'}}>{t('common.to')}</p>
                                  </TableCell>
                                  <TableCell align="left">
                                    <FormControl>
                                      <KeyboardDatePicker
                                        KeyboardButtonProps={{
                                          'aria-label': 'change time',
                                        }}
                                        error={referrer_err}
                                        disableToolbar
                                        variant="inline"
                                        autoOk
                                        inputVariant="outlined"
                                        ampm={false}
                                        value={this.state.use_time.end_time || null}
                                        onChange={(value) => this.changeUseTime(value, 'end_time')}
                                        format={DATE_FORMAT}
                                        invalidDateMessage={t('errorFields.invalidDateMessage')}
                                        maxDateMessage={t('errorFields.maxDateMessage')}
                                        minDateMessage={t('errorFields.minDateMessage')}
                                      />
                                      {this.validator.message('end_time', this.state.use_time.end_time, 'required')}
                                      {this.state.isSubmitForm &&
                                        ((!this.validator.check(this.state.use_time.end_time, 'required') && (
                                          <FormHelperText id="time" style={{color: 'red'}}>
                                            {t('validation.required', {field: t('business_plan.end_time')})}
                                          </FormHelperText>
                                        )) ||
                                          (!compareDateTimeRange(this.state.use_time.start_time, this.state.use_time.end_time, true) && (
                                            <FormHelperText id="time" style={{color: 'red'}}>
                                              {t('validation.invalid.timeRange')}
                                            </FormHelperText>
                                          )))}
                                      {this.state.use_time.end_time && display_times[0].end_time && !this.checkEndTime() && (
                                        <>
                                          <br />
                                          <FormHelperText style={{color: 'red'}}>
                                            {t('validation.time_equal_greater', {
                                              field1: `${t('business_plan.use_time')} ${t('businessVehicle.end_date')}`,
                                              field2: `${t('business_plan.display_times')} ${t('businessVehicle.end_date')}`,
                                            })}
                                          </FormHelperText>
                                        </>
                                      )}
                                    </FormControl>
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Grid>
                        <Grid container alignItems="center" item xs={4}>
                          {referrer_err && (
                            <FormHelperText id="time" style={{color: 'red'}}>
                              <div>
                                <div>{t('business_plan.error_referral_time')}</div>
                                <div>
                                  {plan_referrers
                                    .map((referrer) => (
                                      <Link target="_blank" to={'/maas/referrer/detail/' + referrer.id}>
                                        {referrer.referral_code}
                                      </Link>
                                    ))
                                    .reduce(
                                      (previousValue, currentValue) =>
                                        previousValue === null ? (
                                          currentValue
                                        ) : (
                                          <>
                                            {previousValue} &nbsp; {currentValue}
                                          </>
                                        ),
                                      null,
                                    )}
                                  {/* join components separated by &nbsp; */}
                                </div>
                              </div>
                            </FormHelperText>
                          )}
                        </Grid>
                      </Grid>

                      <Grid container spacing={1} className="row_form_item">
                        <Grid
                          container
                          alignItems="center"
                          item
                          xs={12}
                          className="product_group_table_header_info font_color_white"
                          style={{
                            fontSize: 16,
                            paddingTop: 15,
                            paddingBottom: 15,
                            paddingLeft: 20,
                            paddingRight: 20,
                          }}
                        >
                          {t('business_plan.applicable_object')}
                        </Grid>
                      </Grid>

                      {/* Payment Method */}
                      {this.state.for_gift === false && (
                        <>
                          <Grid container spacing={1} className="row_form_item table_background_color_aliceblue" style={{paddingTop: 12}}>
                            <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                              {t('common.paymentMethod')} <span className="font_color_red">＊</span>
                            </Grid>
                            <Grid container alignItems="center" item xs={6} lg={3} className="grid_title_padding">
                              <FormControl error className="item_display_box">
                                <FormControlLabel
                                  labelPlacement="end"
                                  disabled={this.checkCountry() || this.state.for_gift}
                                  control={
                                    <Checkbox
                                      checked={this.state.payment_method?.indexOf(PAYMENT_METHOD_TRANSFER[0].id) > -1}
                                      onChange={(event) => this.changePaymentMethod(event.target.checked, PAYMENT_METHOD_TRANSFER[0].id)}
                                      className="checkbox_radio"
                                    />
                                  }
                                  label={t(PAYMENT_METHOD_TRANSFER[0].i18n)}
                                />
                                <FormControlLabel
                                  labelPlacement="end"
                                  disabled={this.state.listBankAccount.length > 0 || this.state.for_gift}
                                  control={
                                    <Checkbox
                                      checked={this.state.payment_method?.indexOf(PAYMENT_METHOD_TRANSFER[1].id) > -1}
                                      onChange={(event) => this.changePaymentMethod(event.target.checked, PAYMENT_METHOD_TRANSFER[1].id)}
                                      className="checkbox_radio"
                                    />
                                  }
                                  label={t(PAYMENT_METHOD_TRANSFER[1].i18n)}
                                />
                              </FormControl>
                              {this.state.payment_method.length === 0 && this.state.isSubmitForm && (
                                <FormHelperText id="paymentMethods" error>
                                  {t('validation.required.choose', {field: t('common.paymentMethod')})}
                                </FormHelperText>
                              )}
                            </Grid>
                          </Grid>

                          {/* Banking */}
                          <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                            <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                              <Button
                                disabled={
                                  (this.state.payment_method.find((item) => item === 1) && this.state.payment_method.length === 1) ||
                                  this.state.payment_method.length === 0 ||
                                  (isEditForm && !permission.canUpdate)
                                }
                                variant="contained"
                                color="primary"
                                component="span"
                                onClick={() =>
                                  this.setState({
                                    isModalBanking: true,
                                  })
                                }
                              >
                                {t('business_plan.banking')}
                              </Button>{' '}
                              {!((this.state.payment_method.find((item) => item === 1) && this.state.payment_method.length === 1) || this.state.payment_method.length === 0) && (
                                <span className="font_color_red">＊</span>
                              )}
                            </Grid>
                            <Grid container alignItems="center" item xs={6} lg={8}>
                              {this.state.listBankAccount?.length > 0 && (
                                <TableContainer component={Paper}>
                                  <Table size="small">
                                    <TableHead>
                                      <TableRow>
                                        <TableCell align="right">{t('bankManagement.code')}</TableCell>
                                        <TableCell align="right">{t('bank.account_number')}</TableCell>
                                        <TableCell align="right">{t('bank.bank_name')}</TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {this.state.listBankAccount?.map((bankAccount, index) => {
                                        return (
                                          <TableRow key={index}>
                                            <TableCell align="right">{bankAccount?.code}</TableCell>
                                            <TableCell align="right">{bankAccount?.account_number}</TableCell>
                                            <TableCell align="right">{bankAccount?.bank_name}</TableCell>
                                          </TableRow>
                                        );
                                      })}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              )}
                              {!this.isCheckPaymentMethodTransfer() && this.state.isSubmitForm && !this.validator.check(this.state.listBankAccount, 'required') && (
                                <FormHelperText id="banking" error>
                                  {t('validation.required.choose', {field: t('business_plan.banking')})}
                                </FormHelperText>
                              )}
                            </Grid>
                          </Grid>
                        </>
                      )}


                      {isBanking && (
                        <>
                          {/* Max Transfer Days */}
                          <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                            <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                              {t('business_plan.max_transfer_days')} <span className="font_color_red">＊</span>
                            </Grid>
                            <Grid container alignItems="center" item xs={6} lg={10} className="grid_title_padding">
                              <FormControl fullWidth>
                                <Box display="flex" flexDirection="row">
                                  <TextField
                                    className="field_min_size_150"
                                    margin="dense"
                                    type="number"
                                    inputProps={{
                                      name: 'max_transfer_days',
                                      min: 1,
                                      max: this.state.number_of_unit || 0,
                                    }}
                                    disabled={!this.state.number_of_unit}
                                    placeholder={t('placeholder.required', {field: t('business_plan.max_transfer_days')})}
                                    variant="outlined"
                                    onChange={(event) => Number(event.target.value) <= this.state.number_of_unit && onChangeTextFieldNumber(this, event)}
                                    onKeyDown={preventInvalidChars}
                                    value={this.state.max_transfer_days}
                                  />
                                  <span style={{position: 'relative', top: 12, left: 8}}>{t('business_plan.days')}</span>
                                </Box>
                                {this.validator.message('max_transfer_days', this.state.max_transfer_days, isBanking ? 'required' : '')}
                                {this.state.isSubmitForm && isBanking && !this.validator.check(this.state.max_transfer_days, 'required') && (
                                  <FormHelperText id="max_transfer_days" error>
                                    {t('validation.required', {field: t('business_plan.max_transfer_days')})}
                                  </FormHelperText>
                                )}
                                {this.state.isSubmitForm && !this.isCheckMaxTransferAndReminder() && (
                                  <FormHelperText id="max_transfer_days" error>
                                    {t('business_plan.max_transfer_days_validate')}
                                  </FormHelperText>
                                )}
                                {this.state.isSubmitForm && !this.isCheckMaxTransferAndNumberOfUnit() && (
                                  <FormHelperText id="max_transfer_days_days" error>
                                    {t('number_of_unit.must.less.than.max_transfer_days')}
                                  </FormHelperText>
                                )}
                              </FormControl>
                            </Grid>
                          </Grid>

                          {/* Reminder Days */}
                          <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                            <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                              {t('business_plan.reminder_days')} <span className="font_color_red">＊</span>
                            </Grid>
                            <Grid container alignItems="center" item xs={6} lg={10} className="grid_title_padding">
                              <FormControl fullWidth>
                                <Box display="flex" flexDirection="row">
                                  <TextField
                                    className="field_min_size_150"
                                    margin="dense"
                                    type="number"
                                    inputProps={{
                                      name: 'reminder_days',
                                      min: 0,
                                      max: this.state.max_transfer_days ? Number(this.state.max_transfer_days) - 1 : 0,
                                    }}
                                    disabled={!this.state.max_transfer_days}
                                    placeholder={t('placeholder.required', {field: t('business_plan.reminder_days')})}
                                    variant="outlined"
                                    onChange={(event) => Number(event.target.value) < this.state.max_transfer_days && onChangeTextFieldNumber(this, event)}
                                    onKeyDown={preventInvalidChars}
                                    value={this.state.reminder_days}
                                  />
                                  <span style={{position: 'relative', top: 12, left: 8}}>{t('business_plan.days')}</span>
                                </Box>
                                {this.validator.message('reminder_days', this.state.reminder_days, 'required')}
                                {this.state.isSubmitForm && isBanking && !this.validator.check(this.state.reminder_days, 'required') && (
                                  <FormHelperText id="reminder_days" error>
                                    {t('validation.required', {field: t('business_plan.reminder_days')})}
                                  </FormHelperText>
                                )}
                              </FormControl>
                            </Grid>
                          </Grid>
                        </>
                      )}

                      {/* Show for app */}
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('business_plan.enable_for_landing_page')}
                          <span className="font_color_red">＊</span>
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          <FormControl variant="outlined" margin="dense">
                            <Select
                              name="show_for_app"
                              renderValue={
                                this.state.show_for_app ?
                                  undefined :
                                  () => (
                                      <div className="font-12 color-disabled">
                                        {t('placeholder.required_select', {
                                          field: t('business_plan.enable_for_landing_page'),
                                        })}
                                      </div>
                                    )
                              }
                              variant="outlined"
                              disabled={this.state.for_gift}
                              margin="dense"
                              className="field_size_20 field_min_size_300"
                              displayEmpty
                              value={this.state.show_for_app}
                              onChange={(event) => onChangeSelect(this, event)}
                            >
                              {HAS_FAMILY_OPTIONS.map(({id, i18n}) => (
                                <MenuItem value={id} key={id}>
                                  {t(i18n)}
                                </MenuItem>
                              ))}
                            </Select>
                            {this.validator.message('show_for_app', this.state.show_for_app, 'required')}
                            {this.state.isSubmitForm && !this.validator.check(this.state.show_for_app, 'required') && (
                              <FormHelperText id="show_for_app" error>
                                {t('validation.required.choose', {field: t('business_plan.enable_for_landing_page')})}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                      </Grid>

                      {/* Main plan */}
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('business_plan.main_plan')}
                          <span className="font_color_red">＊</span>
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          <FormControl variant="outlined" margin="dense">
                            <Select
                              name="main_plan"
                              renderValue={
                                this.state.main_plan ?
                                  undefined :
                                  () => (
                                      <div className="font-12 color-disabled">
                                        {t('placeholder.required_select', {
                                          field: t('business_plan.main_plan'),
                                        })}
                                      </div>
                                    )
                              }
                              variant="outlined"
                              disabled={this.state.for_gift}
                              margin="dense"
                              className="field_size_20 field_min_size_300"
                              displayEmpty
                              value={this.state.main_plan}
                              onChange={this.handleChangeMainPlan}
                            >
                              {HAS_FAMILY_OPTIONS.map(({id, i18n}) => (
                                <MenuItem value={id} key={id}>
                                  {t(i18n)}
                                </MenuItem>
                              ))}
                            </Select>
                            {this.validator.message('main_plan', this.state.main_plan, 'required')}
                            {this.state.isSubmitForm && !this.validator.check(this.state.main_plan, 'required') && (
                              <FormHelperText id="main_plan" error>
                                {t('validation.required.choose', {field: t('business_plan.main_plan')})}
                              </FormHelperText>
                            )}
                            {this.state.isMessageShowPlan &&
                              (isEditForm ? (
                                <FormHelperText error>{t('business_plan.msg_show_main')}</FormHelperText>
                              ) : (
                                <FormHelperText error>{t('business_plan.msg_show_main_create')}</FormHelperText>
                              ))}
                          </FormControl>
                        </Grid>
                      </Grid>

                      {/* Automatic Updates */}
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('ticket.automatic_update')} <span className="font_color_red">＊</span>
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={10} className="grid_title_padding">
                          <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                            <Switch
                              checked={this.state.automatic_update}
                              onChange={(event) => this.setState({automatic_update: event.target.checked})}
                              name="automatic_update"
                              disabled={this.state.for_gift}
                              color="primary"
                            />
                          </FormControl>
                        </Grid>
                      </Grid>

                      {isEditForm && (
                        <Grid container alignItems="center" item xs={12} lg={9} style={{marginTop: 30}}>
                          <Memo
                            memos={this.state.memos}
                            onChangeContent={(value) => onChangeListData(this, this.state.memos, value.field, value.index, value.newValue)}
                            note={this.state.note}
                            onChangeNote={(value) => this.setState({note: value})}
                            parentType="medium"
                            InputProps={{
                              readOnly: true,
                            }}
                            countryCode={this.state.country_id}
                          />
                        </Grid>
                      )}
                    </>
                  )}
                </Paper>
                <br></br>

                <Grid container spacing={1}>
                  <Grid container alignItems="center" justify="flex-start" item xs={3}></Grid>
                  <Grid container alignItems="center" justify="flex-end" item xs={9}>
                    {!isEditForm && (
                      <Button color="primary" variant="contained" className="button_magin button_color_green" endIcon={<AddIcon />} onClick={() => this.configPlan()}>
                        {t('common.btnRegister')}
                      </Button>
                    )}
                    {isEditForm && permission.canUpdate && !(isRoleBusiness() && this.state.checkDisable) && (
                      <Button
                        color="primary" variant="contained"
                        className="button_magin"
                        endIcon={<CloudUploadIcon />}
                        onClick={() => this.configPlan('update')}
                      >
                        {t('common.btnUpdate')}
                      </Button>
                    )}
                    <Button color="primary" variant="contained" className="button_magin button_color" style={{marginLeft: 5}} onClick={this.props.history.goBack}>
                      {t('common.btnReturn')}
                    </Button>
                  </Grid>
                </Grid>
                <Dialog
                  open={this.state.flg}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: '1020',
                  }}
                >
                  <SelectModal
                    okButtonText={`${t('common.btnSend')}`}
                    cancelButtonText={`${t('common.btnReturn')}`}
                    onClickOk={this.handleButtonModalOk}
                    onClickCancel={this.handleButtonModalCancel}
                    message={this.state.message}
                    isDisableCreate={this.state.isDisableCreate}
                  ></SelectModal>
                </Dialog>
              </Container>

              {/* Modal List Discount */}
              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={this.state.flagListDiscount}
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  zIndex: '1000',
                }}
              >
                <OneTimeDiscount
                  onClickOk={(data) => this.handleSubmitFormDiscount(data)}
                  onClickCancel={this.closeListDiscountModal}
                  geofence_ids={this.state.list_area_id}
                  dataDiscount={this.state.dataDiscount}
                  flgDiscountErrorRef={this.state.isSubmitForm}
                ></OneTimeDiscount>
              </Modal>

              {/* Modal Select Image */}
              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={this.state.isOpenModalImage}
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  zIndex: '1000',
                }}
              >
                <>
                  <ImageSelect
                    onClickOk={this.handleButtonOkImageModal}
                    onClickClose={this.handleButtonCloseImageModal}
                    selectImage={(value) => this.handleSelectImage(value)}
                  ></ImageSelect>
                </>
              </Modal>
              <Container>
                <Modal
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  open={this.state.isModalBanking}
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500,
                  }}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: '1000',
                  }}
                >
                  <SelectBanking
                    props={this.props}
                    bankIds={this.state.listBankAccount.map(({id}) => id)}
                    onClickOk={(listBankAccountId, listDataView) => {
                      this.setState({
                        isModalBanking: false,
                        listBankAccount: listDataView,
                        bank_account_ids: listBankAccountId,
                        max_transfer_days: listDataView.length > 0 ? 1 : '',
                        reminder_days: listDataView.length > 0 ? 0 : '',
                      });
                    }}
                    onClickClose={() => this.setState({isModalBanking: false})}
                  />
                </Modal>
              </Container>
              <br></br>
            </Card>
            <br></br>
          </Container>
          <br></br>
        </Card>
      </LoadingOverlay>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    business: state.business,
    common: state.common,
    geofence: state.geofence,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllCountryCode: () => dispatch(getAllCountryCode()),
    getDetailPlan: (params) => dispatch(getDetailPlan(params)),
    createPlan: (payload, props) => dispatch(createPlan(payload, props)),
    updatePlan: (payload, props) => dispatch(updatePlan(payload, props)),
    getListGeofenceByCountry: (id) => dispatch(getListGeofenceByCountry(id)),
    getServiceTypes: () => dispatch(getServiceTypes()),
    setMessageModal: (payload) => dispatch(setMessageModal(payload)),
    searchListImage: (params) => dispatch(searchListImage(params)),
    getAllGeofences: (payload) => dispatch(getAllGeofences(payload)),
    getListGeofenceDetailsByIds: (params) => dispatch(getListGeofenceDetailsByIds(params)),
  };
};

export default withPermissionGateway(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(BusinessPlanInformation)));
