import React, {Component} from 'react';

import {Container, FormControl, FormHelperText, Grid, Paper, TextField, Dialog, Select, MenuItem, Box, Button} from '@material-ui/core';
import {green, grey, red} from '@material-ui/core/colors';
// import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import _ from 'lodash';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';

import {ROUTE} from '../../../common/constant';
import SelectModal from '../../../components/selectModal';
import {updateGeofenceApi} from '../../../services/geofences';
import {checkGeofenceApi, checkSimulationRenewApi, createRoutingProfileApi} from '../../../services/newGeofenceService';
import {updateServiceGroupApi} from '../../../services/serviceGroupService';
import {applySimulationApi, enableSimulationApi} from '../../../services/simulationService';
import {syncWillerStopApi} from '../../../services/stopMasterServices';
import {getListVehicleMode} from '../../../stores/business_vehicles/action';
import {getAllCountryCode} from '../../../stores/common/actions';
import {getAllServiceGroups, getServiceGroup, updateServiceGroup} from '../../../stores/service_group/actions';
import {onChangeTextField} from '../../../utils/common';
import {error} from '../../../utils/logger';
import withPermissionData from '../../../withPermissionData/hoc';
import './styles.css';

const DEFAULT_BG_COLOR = '#ff0000';
const DEFAULT_MY_PAGE_BG_COLOR = '#ffffff';
const DEFAULT_MY_PAGE_BORDER_COLOR = '#FD6BA3';
/**
 * Service Group Details
 */
class ServiceGroupForm extends Component {
  /**
   * constructor
   * @param {*} props
   */
  constructor(props) {
    super(props);
    this.state = {
      service_group: {
        id: null,
        enable: true,
        dataset_id: '',
        routing_profile_id: '',
        walking_profile_id: '',
        transit_stop_set_id: '',
        route_network: '',
        name: '',
        display_name: '',
        description: '',
        road_network: '',
      },
      status: [
        {
          isSuccess: false,
          isFail: false,
          isLoading: false,
        },
        {
          isSuccess: false,
          isFail: false,
          isLoading: false,
        },
        {
          isSuccess: false,
          isFail: false,
          isLoading: false,
        },
        {
          isSuccess: false,
          isFail: false,
          isLoading: false,
        },
        {
          isSuccess: false,
          isFail: false,
          isLoading: false,
        },
        {
          isSuccess: false,
          isFail: false,
          isLoading: false,
        },
      ],
      contentList: [
        'Sync Waypoints from SWAT.',
        'Check Geofence information on Mobi system.',
        'Create Routing Profile on Mobi system.',
        'Create new Geofence on Mobi System.',
        'Create Simulation for ODM service.',
        'Setup daily Simulation renew process and support for advance booking.',
      ],
      service_groups: [],
      service_group_flag: false,
      routing_profile_name: '',
      simulation: {
        id: '',
        simulation_id: '',
        transportation_type: 'SHUTTLE_BUS',
        vehicle_group_type: 'SHUTTLE_BUS_ON_DEMAND',
        name: '',
        start_time: new Date().toISOString(),
        end_time: new Date(new Date().getTime() + 2 * 24 * 60 * 60 * 1000).toISOString(), // next day
        vehicle_capacity: 20,
        acceptable_waiting_time: 1800,
        waiting_time_cost_factor: 0.5,
        max_additional_journey_time: 3600,
        max_additional_journey_time_percent: 30,
        max_walking_distance: 500,
        pickup_service_time: 60,
        dropoff_service_time: 60,
        max_pool_size: 1,
        trip_cost: 1.1,
        time_factor: '',
        shrink_time_delta: 300,
        reverse_shrink_time_delta: 1,
        offer_auto_cancellation_timeout: 120,
        start_time_end_time_limitations: true,
        nearest_distance: 0,
        geofence_id: '',
        transit_stop_set_id: '',
        stop_set_id: '',
        enable: true,
        limit_user_booking: 40,
        manual_manage_transit_stop: true,
        door_to_door_mode: false,
        allow_vehicle_late: false,
        enable_waypoints_cache: true,
        enable_curb: true,
        currency_code: '',
        payment_method: [],
        payment_support: ['ONE_TIME', 'SUBSCRIPTION'],
        description: '',
        performance_tracker_enabled: false,
        driving_side: 'LEFT',
        send_vehicle_cancellation_updates: true,
        tracking_driver: true,
        vehicle_mode: '',
        show_search_walking_result: false,
        country_code: '',
        service_id: '',
        show_waypoint_in_map: true,
        use_path_equalizer: true,
      },
      geofence: {
        id: null,
        name: '',
        enable: true,
        enable_for_admin: true,
        enable_for_app: true,
        display_for_app: ['TOP_MAP', 'MY_PAGE'],
        in_hole: false,
        bg_color: DEFAULT_BG_COLOR,
        zone_id: '',
        vehicle_modes: '',
        border_color: DEFAULT_BG_COLOR,
        operating_start_time: '00:00',
        operating_end_time: '23:59',
        min_walking_distance_show: 200,
        max_walking_distance_show: 1200,
        mapbox_path_segment: 'tdroute',
        service_group_id: '',
        mypage_bg_color: DEFAULT_MY_PAGE_BG_COLOR,
        mypage_bg_opacity: 0,
        mypage_border_color: DEFAULT_MY_PAGE_BORDER_COLOR,
        mypage_border_opacity: 100,
        project_id: null,
        allow_infants: false,
        offer_estimation_timeout: 4,
        enableForAdminInfosPrevious: {},
        enable_frame_time_price: false,
        enable_waypoint_association: false,
        enable_last_order_time: false,
        last_order_time: null,
        external_id: '',
        swat_geofence_key: '',
        geometry: {},
        opacity: 0,
        show_vehicle_additional_info: false,
        ad_setting_screen_display: true,
        ad_history_screen_display: true,
        any_assigned_bookings: false,
        sync_geofence: false,
        need_merge: false,
      },
      new_service_group: {
        id: null,
        enable: true,
        dataset_id: '',
        routing_profile_id: '',
        walking_profile_id: '',
        transit_stop_set_id: '',
        route_network: '',
        name: '',
        display_name: '',
        description: '',
        road_network: '',
      },
      countries: [],
      country_id: null,
      currency_code: '',
      // checkGeofenceModal: false,
      id: '',
      external_id: '',
      waypoint_id: '',
      enable: true,
      dataset_id: '',
      routing_profile_id: '',
      walking_profile_id: '',
      transit_stop_set_id: '',
      route_network: '',
      name: '',
      display_name: '',
      description: '',
      isSubmitForm: false,
      showModal: false,
      modalMessage: '',
      central_point: [],
      errorMessage: '',
      success: false,
      isSubmitGroupForm: false,
    };
    this.validator = new SimpleReactValidator();
    this.validatorGroupService = new SimpleReactValidator();
  }

  /**
   * handleUpdate
   */
  handleUpdate = async () => {
    this.setState({isSubmitForm: true, errorMessage: ''});
    if (this.validator.allValid()) {
      this.setStatus(0, true, false, false);
      await syncWillerStopApi(this.state.waypoint_id).then((res) => {
        if (res) {
          const simulation = {...this.state.simulation};
          simulation.stop_set_id = res?.result?.stop_set_id;
          this.setStatus(0, false, true, false);
          this.setState(() => ({simulation}), () => this.processCreateGeofence());
        } else {
          this.setStatus(0, false, false, true);
          this.setState({errorMessage: res?.message_code ? res?.message_code : 'Internal Server Error'});
          throw error(res?.message_code);
        }
      });
    }
  };

  /**
   * handleUpdateTryAgain
   */
  handleUpdateTryAgain = async () => {
    this.setState(
      () => ({
        errorMessage: '',
        status: [
          {
            isSuccess: false,
            isFail: false,
            isLoading: false,
          },
          {
            isSuccess: false,
            isFail: false,
            isLoading: false,
          },
          {
            isSuccess: false,
            isFail: false,
            isLoading: false,
          },
          {
            isSuccess: false,
            isFail: false,
            isLoading: false,
          },
          {
            isSuccess: false,
            isFail: false,
            isLoading: false,
          },
          {
            isSuccess: false,
            isFail: false,
            isLoading: false,
          },
        ],
      }),
      () => this.handleUpdate(),
    );
  };

  /**
   * processCreateGeofence
   */
  processCreateGeofence = async () => {
    try {
      const body = {
        country_id: this.state.country_id,
        swat_stop_set_id: this.state.waypoint_id,
        routing_profile_name: this.state.routing_profile_name,
        external_id: this.state.external_id, // Geofence ID
      };
      let external_id = '';
      let description = '';
      this.setStatus(1, true, false, false);
      await checkGeofenceApi(body).then((res) => {
        if (!_.isEmpty(res?.result)) {
          external_id = res?.result?.swat_geofence_data.routing_profile_id; // id routing profile of swat
          description = res?.result?.swat_geofence_data.routing_profile_name;
          const geofence = {...this.state.geofence};
          // set data for geofence
          const simulation = {...this.state.simulation};
          geofence.project_id = res?.result?.swat_geofence_data.project_id;
          geofence.geometry = res?.result?.swat_geofence_data.geometry;
          geofence.need_merge = res?.result?.swat_geofence_data.need_merge;
          geofence.vehicle_modes = [res?.result?.swat_geofence_data.routing_profile_name];
          geofence.external_id = res?.result.external_id; // external_id of swat
          simulation.vehicle_mode = res?.result?.swat_geofence_data.routing_profile_name;
          simulation.transit_stop_set_id = this.state.waypoint_id;
          this.setState({geofence, simulation});
          if (res?.result?.service_group) {
            const service_group = res?.result?.service_group;
            this.setState(
              () => ({service_group}),
            );
          } else {
            const service_group = {...this.state.service_group};
            service_group.name = geofence.name;
            service_group.description = geofence.name;
            this.setState(
              () => ({service_group}),
            );
          }
        } else {
          this.setStatus(1, false, false, true);
          this.setState({errorMessage: res?.message_code ? res?.message_code : 'Internal Server Error'});
          throw error(res?.message_code);
        }
      });
      await syncWillerStopApi(this.state.waypoint_id).then((res) => {
        if (res) {
          const simulation = {...this.state.simulation};
          simulation.stop_set_id = res?.result?.stop_set_id;
          this.setStatus(1, false, true, false);
          this.setState({simulation});
        } else {
          this.setStatus(1, false, false, true);
          this.setState({errorMessage: res?.message_code});
          throw error(res?.message_code);
        }
      });
      this.setStatus(2, true, false, false);
      await createRoutingProfileApi({external_id, description}).then((res) => {
        if (res) {
          this.setStatus(2, false, true, false);
          const service_group = {...this.state.service_group};
          service_group.road_network = res?.result?.road_network;
          this.setState({service_group});
        } else {
          this.setStatus(2, false, false, true);
          this.setState({errorMessage: res?.message_code});
          throw error(res?.message_code);
        }
      });
      this.setStatus(3, true, false, false);
      await updateServiceGroupApi(this.state.service_group).then((res) => {
        if (res) {
          const service_group = {...this.state.service_group};
          const geofence = {...this.state.geofence};
          geofence.service_group_id = res?.result?.id;
          service_group.id = res?.result?.id;
          service_group.enable = res?.result?.enable;
          service_group.dataset_id = res?.result?.dataset_id;
          service_group.routing_profile_id = res?.result?.routing_profile_id;
          service_group.walking_profile_id = res?.result?.walking_profile_id;
          service_group.transit_stop_set_id = res?.result?.transit_stop_set_id;
          service_group.route_network = res?.result?.route_network;
          service_group.name = res?.result?.name;
          service_group.display_name = res?.result?.display_name;
          service_group.description = res?.result?.description;
          // this.props.getAllServiceGroups({routingProfileName: this.state.routing_profile_name, page: 0, size: 9999}).then((res) => {
          //   if (res) {
          //     this.setState({service_groups: res?.content});
          //   }
          // });
          this.setState({service_group, geofence});
        } else {
          this.setStatus(3, false, false, true);
          this.setState({errorMessage: res?.message_code});
          throw error(res?.message_code);
        }
      });

      await updateGeofenceApi(this.state.geofence).then((res) => {
        if (res) {
          let geofence = {...this.state.geofence};
          const simulation = {...this.state.simulation};
          geofence = res?.result;
          simulation.geofence_id = res?.result?.geofence_id;
          this.setStatus(3, false, true, false);
          this.setState({geofence, simulation});
        } else {
          this.setStatus(3, false, false, true);
          this.setState({errorMessage: res?.message_code});
          throw error(res?.message_code);
        }
      });
      this.setStatus(4, true, false, false);
      await enableSimulationApi(this.state.simulation).then((res) => {
        if (res) {
          let simulation = {...this.state.simulation};
          simulation = res?.result;
          this.setState({simulation});
          applySimulationApi(res?.result?.id).then((res) => {
            if (res) {
              this.setStatus(4, false, true, false);
            } else {
              this.setStatus(4, false, false, true);
              this.setState({errorMessage: res?.message_code});
              throw error(res?.message_code);
            }
          });
          this.setStatus(4, false, true, false);
        } else {
          this.setStatus(4, false, false, true);
          this.setState({errorMessage: res?.message_code});
          throw error(res?.message_code);
        }
      });
      const payload = {geofence_id: this.state.geofence.geofence_id, simulation_id: this.state.simulation.id};
      this.setStatus(5, true, false, false);
      await checkSimulationRenewApi(payload).then((res) => {
        if (res) {
          this.setStatus(5, false, true, false);
        } else {
          this.setStatus(5, false, false, true);
          this.setState({errorMessage: res?.message_code});
          throw error(res?.message_code);
        }
      });
    } catch (error) {
      return error;
    }
  };

  /**
   * setStatus
   * @param {*} index
   * @param {boolean} isLoading
   * @param {boolean} isSuccess
   * @param {boolean} isFail
   */
  setStatus = (index, isLoading, isSuccess, isFail) => {
    const status = [...this.state.status];
    status[index].isLoading = isLoading;
    status[index].isSuccess = isSuccess;
    status[index].isFail = isFail;
    this.setState({status});
  };

  /**
   * componentDidMount
   */
  async componentDidMount() {
    this.props.getAllCountryCode().then((res) => {
      if (res) {
        this.setState({countries: res});
      }
    });
  }

  /**
   * onChangeCountry
   * @param {*} event
   */
  onChangeCountry = (event) => {
    if (event.target.value) {
      const geofence = {...this.state.geofence};
      const simulation = {...this.state.simulation};
      const country_id = event.target.value;
      const country = this.state.countries.find((country) => country.id === country_id);
      const currency_code = country?.payment_config?.currency_code;
      geofence.zone_id = country?.zone_id;
      geofence.country_id = country_id;
      simulation.country_code = country?.country_code;
      simulation.currency_code = currency_code;
      this.setState({country_id, currency_code, geofence, simulation});
    }
  };

  /**
   * redirectDetailGeofence
   */
  redirectDetailGeofence = () => {
    window.open(ROUTE.LAYOUT + ROUTE.GEOFENCE_MANAGEMENT_DETAIL + `/${this.state.geofence.geofence_id}`, '_blank');
  };

  /**
   * onChangeDrivingSide
   * @param {*} event
   */
  onChangeDrivingSide = (event) => {
    const simulation = {...this.state.simulation};
    simulation.driving_side = event.target.value;
    this.setState({simulation});
  };

  /**
   * onChangeServiceGroup
   * @param {*} event
   */
  onChangeServiceGroup = (event) => {
    if (event.target.value) {
      const service_group = event.target.value;
      this.setState({service_group});
    }
  };

  /**
   * resetForm
   */
  resetForm = () => {
    this.setState({
      service_group: {
        id: null,
        enable: true,
        dataset_id: '',
        routing_profile_id: '',
        walking_profile_id: '',
        transit_stop_set_id: '',
        route_network: '',
        name: '',
        display_name: '',
        description: '',
        road_network: '',
      },
      status: [
        {
          isSuccess: false,
          isFail: false,
          isLoading: false,
        },
        {
          isSuccess: false,
          isFail: false,
          isLoading: false,
        },
        {
          isSuccess: false,
          isFail: false,
          isLoading: false,
        },
        {
          isSuccess: false,
          isFail: false,
          isLoading: false,
        },
        {
          isSuccess: false,
          isFail: false,
          isLoading: false,
        },
        {
          isSuccess: false,
          isFail: false,
          isLoading: false,
        },
      ],
      contentList: [
        'Sync Waypoints from SWAT.',
        'Check Geofence information on Mobi system.',
        'Create Routing Profile on Mobi system.',
        'Create new Geofence on Mobi System.',
        'Create Simulation for ODM service.',
        'Setup daily Simulation renew process and support for advance booking.',
      ],
      service_groups: [],
      service_group_flag: false,
      routing_profile_name: '',
      simulation: {
        id: '',
        simulation_id: '',
        transportation_type: 'SHUTTLE_BUS',
        vehicle_group_type: 'SHUTTLE_BUS_ON_DEMAND',
        name: '',
        start_time: new Date().toISOString(),
        end_time: new Date(new Date().getTime() + 2 * 24 * 60 * 60 * 1000).toISOString(), // next day
        vehicle_capacity: 20,
        acceptable_waiting_time: 1800,
        waiting_time_cost_factor: 0.5,
        max_additional_journey_time: 3600,
        max_additional_journey_time_percent: 30,
        max_walking_distance: 500,
        pickup_service_time: 60,
        dropoff_service_time: 60,
        max_pool_size: 1,
        trip_cost: 1.1,
        time_factor: '',
        shrink_time_delta: 300,
        reverse_shrink_time_delta: 1,
        offer_auto_cancellation_timeout: 120,
        start_time_end_time_limitations: true,
        nearest_distance: 0,
        geofence_id: '',
        transit_stop_set_id: '',
        stop_set_id: '',
        enable: true,
        limit_user_booking: 40,
        manual_manage_transit_stop: true,
        door_to_door_mode: false,
        allow_vehicle_late: false,
        enable_waypoints_cache: true,
        enable_curb: true,
        currency_code: '',
        payment_method: [],
        payment_support: ['ONE_TIME', 'SUBSCRIPTION'],
        description: '',
        performance_tracker_enabled: false,
        driving_side: 'LEFT',
        send_vehicle_cancellation_updates: true,
        tracking_driver: true,
        vehicle_mode: '',
        show_search_walking_result: false,
        country_code: '',
        service_id: '',
        show_waypoint_in_map: true,
        use_path_equalizer: true,
      },
      geofence: {
        id: null,
        name: '',
        enable: true,
        enable_for_admin: true,
        enable_for_app: true,
        display_for_app: ['TOP_MAP', 'MY_PAGE'],
        in_hole: false,
        bg_color: DEFAULT_BG_COLOR,
        zone_id: '',
        vehicle_mode: '',
        border_color: DEFAULT_BG_COLOR,
        operating_start_time: '00:00',
        operating_end_time: '23:59',
        min_walking_distance_show: 200,
        max_walking_distance_show: 1200,
        mapbox_path_segment: 'tdroute',
        service_group_id: '',
        mypage_bg_color: DEFAULT_MY_PAGE_BG_COLOR,
        mypage_bg_opacity: 0,
        mypage_border_color: DEFAULT_MY_PAGE_BORDER_COLOR,
        mypage_border_opacity: 100,
        project_id: null,
        allow_infants: false,
        offer_estimation_timeout: 4,
        enableForAdminInfosPrevious: {},
        enable_frame_time_price: false,
        enable_waypoint_association: false,
        enable_last_order_time: false,
        last_order_time: null,
        external_id: '',
        swat_geofence_key: '',
        geometry: {},
        opacity: 0,
        show_vehicle_additional_info: false,
        ad_setting_screen_display: true,
        ad_history_screen_display: true,
        any_assigned_bookings: false,
        sync_geofence: false,
        need_merge: false,
      },
      new_service_group: {
        id: null,
        enable: true,
        dataset_id: '',
        routing_profile_id: '',
        walking_profile_id: '',
        transit_stop_set_id: '',
        route_network: '',
        name: '',
        display_name: '',
        description: '',
        road_network: '',
      },
      country_id: null,
      currency_code: '',
      // checkGeofenceModal: false,
      id: '',
      external_id: '',
      waypoint_id: '',
      enable: true,
      dataset_id: '',
      routing_profile_id: '',
      walking_profile_id: '',
      transit_stop_set_id: '',
      route_network: '',
      name: '',
      display_name: '',
      description: '',
      isSubmitForm: false,
      showModal: false,
      modalMessage: '',
      central_point: [],
      errorMessage: '',
      success: false,
      isSubmitGroupForm: false,
    });
  };

  /**
   * displayInProgress
   * @param {*} content
   * @param {*} index
   * @return {*}
   */
  displayInProgress = (content, index) => {
    return (
      <>
        <Grid container alignItems="center" style={{marginBottom: '10px'}}>
          {this.state.status[index].isLoading === false && (
            <>
              {this.state.status[index].isSuccess === false && this.state.status[index].isFail === false && (
                <CheckBoxOutlineBlankIcon style={{color: grey[500], marginRight: '10px'}}></CheckBoxOutlineBlankIcon>
              )}
              {this.state.status[index].isFail === true && <ErrorIcon style={{color: red[500], marginRight: '10px'}}></ErrorIcon>}
              {this.state.status[index].isSuccess === true && this.state.status[index].isFail === false && (
                <CheckBoxIcon style={{color: green[500], marginRight: '10px'}}></CheckBoxIcon>
              )}
            </>
          )}
          {this.state.status[index].isLoading === true && <img src="/images/loading.gif" alt="loading" style={{width: '20px', height: '20px', marginRight: '10px'}} />}
          <Box
            className={this.state.status[index].isFail ? 'error' : this.state.status[index].isSuccess ? 'text_success' : this.state.status[index].isLoading ? 'text_pending' : ''}
          >
            {index + 1}. {content}
          </Box>
        </Grid>
      </>
    );
  };

  /**
   * openModalCreateServiceGroup
   */
  openModalCreateServiceGroup = () => {
    this.setState({service_group_flag: true});
  };

  /**
   * createNewServiceGroup
   */
  createNewServiceGroup = () => {
    this.setState({isSubmitGroupForm: true});
    if (this.validatorGroupService.allValid()) {
      const service_group = this.state.new_service_group;
      const service_groups = [...this.state.service_groups];
      service_groups.push(service_group);
      this.setState({service_group, service_groups, service_group_flag: false});
    }
  };
  /**
   * onChangeSimulationName
   * @param {*} event
   */
  // onChangeSimulationName = (event) => {
  //   const simulation = {...this.state.simulation};
  //   simulation.name = event.target.value;
  //   simulation.description = event.target.value;
  //   this.setState({simulation});
  // };

  /**
   * onChangeGeofenceName
   * @param {*} event
   */
  onChangeGeofenceName = (event) => {
    const geofence = {...this.state.geofence};
    const simulation = {...this.state.simulation};
    const value = event.target.value;
    geofence.name = value;
    simulation.name = value;
    simulation.description = value;
    this.setState(() => ({geofence, simulation}));
  };

  /**
   * onChangeNewServiceGroup
   * @param {*} event
   * @param {*} key
   */
  onChangeNewServiceGroup = (event, key) => {
    const new_service_group = {...this.state.new_service_group};
    if (key === 'name') {
      new_service_group.name = event.target.value;
    } else {
      new_service_group.description = event.target.value;
    }
    this.setState({new_service_group});
  };

  /**
   * onChangeRoutingProfileName
   * @param {*} event
   */
  onChangeRoutingProfileName = (event) => {
    // if (event.target.value === '') {
    //   this.setState({service_groups: []});
    // } else {
    //   this.props.getAllServiceGroups({routingProfileName: event.target.value, page: 0, size: 9999}).then((res) => {
    //     if (res) {
    //       this.setState({service_groups: res?.content});
    //     }
    //   });
    // }
    this.setState({
      routing_profile_name: event.target.value,
      service_group: {
        id: '',
        enable: true,
        dataset_id: '',
        routing_profile_id: '',
        walking_profile_id: '',
        transit_stop_set_id: '',
        route_network: '',
        name: '',
        display_name: '',
        description: '',
      },
    });
  };

  /**
   * onChangeGeofenceId
   * @param {*} event
   */
  onChangeGeofenceId = (event) => {
    if (event.target.value.length <= 10) {
      const geofence = {...this.state.geofence};
      geofence.swat_geofence_key = event.target.value;
      this.setState({external_id: event.target.value, geofence});
    }
  };

  /**
   * Render component
   * @return {*}
   */
  render() {
    const {t} = this.props;
    const isDisableCreate = this.state.status.some((item) => (item.isLoading === true || item.isSuccess === true));
    return (
      <Container maxWidth="xl">
        <br></br>
        <Paper className="search_table">
          <Grid container spacing={1} className="row_form_item">
            <Grid container alignItems="center" item xs={12} lg={12} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
              {t('New Geofence Information')}
            </Grid>
          </Grid>
          <Grid className="geofence_form">
            <Grid container>
              <Grid xs={6} lg={6}>
                <Grid>
                  <Box fontWeight="fontWeightMedium">
                    Geofence ID <span className="font_color_red">＊</span>
                  </Box>
                </Grid>
                <Grid>
                  <FormControl variant="outlined" margin="dense" fullWidth>
                    <TextField
                      placeholder={t('validation.required', {field: t('Geofence ID')})}
                      name="external_id"
                      className="field_size_10 field_min_size_300"
                      margin="dense"
                      value={this.state.external_id}
                      onChange={(event) => this.onChangeGeofenceId(event)}
                      variant="outlined"
                    />
                    {this.validator.message('external_id', this.state.external_id, 'required')}
                    {this.state.isSubmitForm && !this.validator.check(this.state.external_id, 'required') && (
                      <FormHelperText id="external_id" error>
                        {t('validation.required.choose', {field: t('serviceGroup.external_id')})}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid>
                  <Box fontWeight="fontWeightMedium">
                    Waypoint ID (Transit stop set ID) <span className="font_color_red">＊</span>
                  </Box>
                </Grid>
                <Grid>
                  <FormControl variant="outlined" margin="dense" fullWidth>
                    <TextField
                      placeholder={t('validation.required', {field: t('Waypoint ID')})}
                      name="waypoint_id"
                      className="field_size_10 field_min_size_300"
                      margin="dense"
                      value={this.state.waypoint_id}
                      onChange={(event) => onChangeTextField(this, event)}
                      variant="outlined"
                    />
                    {this.validator.message('waypoint_id', this.state.waypoint_id, 'required')}
                    {this.state.isSubmitForm && !this.validator.check(this.state.waypoint_id, 'required') && (
                      <FormHelperText id="waypoint_id" error>
                        {t('validation.required.choose', {field: t('serviceGroup.waypoint_id')})}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid>
                  <Box fontWeight="fontWeightMedium">
                    Country <span className="font_color_red">＊</span>
                  </Box>
                </Grid>
                <Grid>
                  <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_300">
                    <Select
                      margin="dense"
                      inputProps={{
                        name: 'country',
                      }}
                      value={this.state.country_id}
                      onChange={(event) => this.onChangeCountry(event)}
                      displayEmpty
                      renderValue={
                        this.state.country_id ?
                          undefined :
                          () => (
                              <div className="font-12 color-disabled">
                                {t('placeholder.required_select', {
                                  field: t('common.country'),
                                })}
                              </div>
                            )
                      }
                    >
                      {this.state.countries.map((country, idx) => (
                        <MenuItem value={country.id} key={idx}>
                          {t(`${country.country_code}`)}
                        </MenuItem>
                      ))}
                    </Select>
                    {this.validator.message('country_id', this.state.country_id, 'required')}
                    {this.state.isSubmitForm && !this.validator.check(this.state.country_id, 'required') && (
                      <FormHelperText id="country_id" error>
                        {t('validation.required.choose', {field: t('common.country')})}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                {/* <Grid>
                  <Box fontWeight="fontWeightMedium">
                    Simulation Name <span className="font_color_red">＊</span>
                  </Box>
                </Grid>
                <Grid>
                  <FormControl variant="outlined" margin="dense" fullWidth>
                    <TextField
                      placeholder={t('validation.required', {field: t('Simulation Name')})}
                      name="simulation_name"
                      className="field_size_10 field_min_size_300"
                      margin="dense"
                      value={this.state.simulation?.name}
                      onChange={(event) => this.onChangeSimulationName(event)}
                      variant="outlined"
                    />
                    {this.validator.message('simulation_name', this.state.simulation?.name, 'required')}
                    {this.state.isSubmitForm && !this.validator.check(this.state.simulation?.name, 'required') && (
                      <FormHelperText id="simulation_name" error>
                        {t('validation.required.choose', {field: t('serviceGroup.waypoint_id')})}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid> */}
              </Grid>
              <Grid xs={6} lg={6}>
                <Grid>
                  <Box fontWeight="fontWeightMedium">
                    Routing Profile Name <span className="font_color_red">＊</span>
                  </Box>
                </Grid>
                <Grid>
                  <FormControl variant="outlined" margin="dense" fullWidth>
                    <TextField
                      placeholder={t('validation.required', {field: t('Routing Profile Name')})}
                      name="routing_profile_name"
                      className="field_size_10 field_min_size_300"
                      margin="dense"
                      value={this.state.routing_profile_name}
                      onChange={(event) => this.onChangeRoutingProfileName(event)}
                      variant="outlined"
                    />
                    {this.validator.message('routing_profile_name', this.state.routing_profile_name, 'required')}
                    {this.state.isSubmitForm && !this.validator.check(this.state.routing_profile_name, 'required') && (
                      <FormHelperText id="routing_profile_name" error>
                        {t('validation.required.choose', {field: t('serviceGroup.routing_profile_name')})}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid>
                  <Box fontWeight="fontWeightMedium">
                    Geofence Name <span className="font_color_red">＊</span>
                  </Box>
                </Grid>
                <Grid>
                  <FormControl variant="outlined" margin="dense" fullWidth>
                    <TextField
                      placeholder={t('validation.required', {field: t('Geofence Name')})}
                      name="geofence_name"
                      className="field_size_10 field_min_size_300"
                      margin="dense"
                      value={this.state.geofence?.name}
                      onChange={(event) => this.onChangeGeofenceName(event)}
                      variant="outlined"
                    />
                    {this.validator.message('geofence_name', this.state.geofence?.name, 'required')}
                    {this.state.isSubmitForm && !this.validator.check(this.state.geofence?.name, 'required') && (
                      <FormHelperText id="geofence_name" error>
                        {t('validation.required.choose', {field: t('serviceGroup.geofence_name')})}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                {/* <Grid>
                  <Box fontWeight="fontWeightMedium">
                    Service Group <span className="font_color_red">＊</span>
                  </Box>
                </Grid>
                <Grid className="service_group_box">
                  <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_300">
                    <Select
                      margin="dense"
                      inputProps={{
                        name: 'service_group',
                      }}
                      value={this.state.service_group}
                      onChange={(e) => this.onChangeServiceGroup(e)}
                      displayEmpty
                      renderValue={
                        this.state.service_group?.name
                          ? undefined
                          : () => (
                              <div className="font-12 color-disabled">
                                {t('placeholder.required_select', {
                                  field: t('Service Group'),
                                })}
                              </div>
                            )
                      }
                    >
                      {this.state.service_groups.map((service, idx) => (
                        <MenuItem value={service} key={idx}>
                          {service.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {this.validator.message('service_group', this.state.service_group?.name, 'required')}
                    {this.state.isSubmitForm && !this.validator.check(this.state.service_group?.name, 'required') && (
                      <FormHelperText id="service_group" error>
                        {t('validation.required.choose', {field: t('Service Group')})}
                      </FormHelperText>
                    )}
                  </FormControl>
                  <Button disabled={!this.state.routing_profile_name} onClick={() => this.openModalCreateServiceGroup()}>
                    <AddCircleOutlineIcon fontSize="large" style={{color: !this.state.routing_profile_name ? grey[500] : green[500]}} />
                  </Button>
                </Grid> */}
                <Grid>
                  <Box fontWeight="fontWeightMedium">Driving Side</Box>
                </Grid>
                <Grid style={{marginTop: '8px'}}>
                  <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_300">
                    <Select
                      inputProps={{
                        name: 'driving_side',
                      }}
                      value={this.state.simulation?.driving_side}
                      defaultValue={this.state.simulation?.driving_side}
                      onChange={(event) => this.onChangeDrivingSide(event)}
                    >
                      <MenuItem value="LEFT">LEFT</MenuItem>
                      <MenuItem value="RIGHT">RIGHT</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <br></br>
            <Grid container>
              {!this.state.errorMessage ? (
                <Button disabled={isDisableCreate} className={isDisableCreate ? '' : 'success_color'} variant="contained" onClick={() => this.handleUpdate()}>
                  Create
                </Button>
              ) : (
                <Button className="success_color" variant="contained" onClick={() => this.handleUpdateTryAgain()}>
                  Try again
                </Button>
              )}
            </Grid>
            <br></br>
            <Grid className="progress_box radius_box">
              <Box className="font-weight-600">Geofence creation process on Mobi system.</Box>
              <br></br>
              {this.state.contentList.map((content, index) => {
                return this.displayInProgress(content, index);
              })}
            </Grid>
            <br></br>
            {this.state.errorMessage && (
              <Grid className="error_box">
                <h3 style={{color: 'red'}}>ERROR!</h3>
                <Grid>
                  {this.state.statusCode} {this.state.errorMessage}
                </Grid>
              </Grid>
            )}
            <br></br>
            {this.state.status.every((item) => item.isSuccess === true) && (
              <Grid className="success_box">
                <Grid>
                  <img src="../images/check_circle.svg" alt="check-circle"></img>
                </Grid>
                <br></br>
                <Grid className="font-25">Geofence implementation completed!</Grid>
                <br></br>
                <Grid>
                  <Button
                    className="success_color"
                    style={{paddingLeft: '70px', paddingRight: '70px'}}
                    variant="contained"
                    onClick={() => this.redirectDetailGeofence()}
                  >
                    Detail
                  </Button>
                  <Button style={{paddingLeft: '50px', paddingRight: '50px', marginLeft: '20px'}} variant="contained" color="primary" onClick={() => this.resetForm()}>
                    Create New
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Paper>
        <Dialog
          open={this.state.showModal}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: '1020',
          }}
        >
          <SelectModal onClickOk={this.handleUpdate} onClickCancel={this.closeModal} message={this.state.modalMessage}></SelectModal>
        </Dialog>
        <Dialog
          open={this.state.checkGeofenceModal}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: '1020',
          }}
        >
          <Paper>
            <Container maxWidth="xl">
              <br />
              <Grid container spacing={6}>
                <Grid container alignItems="center" justifyContent="flex-end" item xs={2}>
                  <InfoIcon className="errorinfo" fontSize="large" />
                </Grid>
                <Grid container alignItems="center" justifyContent="flex-start" item xs={10}>
                  This geofence overlaps with another existing geofence, so the service group needs to be configured the same way as that geofence. We will automatically apply the
                  appropriate service group for this geofence.
                </Grid>
                <Grid container alignItems="center" justifyContent="center" xs={12} style={{marginBottom: '20px'}}>
                  <Button
                    variant="contained"
                    className=""
                    color="primary"
                    onClick={() => {
                      this.processCreateGeofence();
                      this.setState({checkGeofenceModal: false});
                    }}
                  >
                    {t('common.btnAgree')}
                  </Button>
                </Grid>
              </Grid>
            </Container>
            <br />
          </Paper>
        </Dialog>
        <Dialog
          open={this.state.service_group_flag}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: '1020',
          }}
          maxWidth="xl"
        >
          <Paper>
            <Container maxWidth="xl" style={{minWidth: '768px'}}>
              <br />
              <Grid container>
                <Grid spacing={6} xs={12} lg={6}>
                  <Grid>
                    <Box className="text-roboto" fontWeight="fontWeightMedium">
                      Group name <span className="font_color_red">＊</span>
                    </Box>
                  </Grid>
                  <Grid>
                    <FormControl variant="outlined" margin="dense" fullWidth>
                      <TextField
                        placeholder={t('validation.required', {field: t('Group name')})}
                        name="group_name"
                        className="field_size_10 field_min_size_350"
                        margin="dense"
                        value={this.state.new_service_group.name}
                        onChange={(event) => this.onChangeNewServiceGroup(event, 'name')}
                        variant="outlined"
                      />
                      {this.validatorGroupService.message('group_name', this.state.new_service_group.name, 'required')}
                      {this.state.isSubmitGroupForm && !this.validatorGroupService.check(this.state.new_service_group.name, 'required') && (
                        <FormHelperText id="group_name" error>
                          {t('validation.required.choose', {field: t('serviceGroup.group_name')})}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid spacing={6} xs={12} lg={6}>
                  <Grid>
                    <Box className="text-roboto" fontWeight="fontWeightMedium">
                      Description <span className="font_color_red">＊</span>
                    </Box>
                  </Grid>
                  <Grid>
                    <FormControl variant="outlined" margin="dense" fullWidth>
                      <TextField
                        placeholder={t('validation.required', {field: t('Description')})}
                        name="description"
                        className="field_size_10 field_min_size_350"
                        margin="dense"
                        value={this.state.new_service_group.description}
                        onChange={(event) => this.onChangeNewServiceGroup(event, 'description')}
                        variant="outlined"
                      />
                      {this.validatorGroupService.message('description', this.state.new_service_group.description, 'required')}
                      {this.state.isSubmitGroupForm && !this.validatorGroupService.check(this.state.new_service_group.description, 'required') && (
                        <FormHelperText id="description" error>
                          {t('validation.required.choose', {field: t('serviceGroup.description')})}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container alignItems="center" justifyContent="space-evenly" item xs={12}>
                <Grid item xs={5} lg={3}>
                  <Button fullWidth variant="contained" className="success_color" onClick={() => this.createNewServiceGroup()}>
                    {t('common.btnAgree')}
                  </Button>
                </Grid>
                <Grid item xs={5} lg={3}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      this.setState({
                        service_group_flag: false,
                      })
                    }
                  >
                    {t('common.btnCancel')}
                  </Button>
                </Grid>
              </Grid>
            </Container>
            <br />
          </Paper>
        </Dialog>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    listVehicleMode: state.businessVehicle.listVehicleMode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getServiceGroup: (payload) => dispatch(getServiceGroup(payload)),
    updateServiceGroup: (payload, props) => dispatch(updateServiceGroup(payload, props)),
    getListVehicleMode: () => dispatch(getListVehicleMode()),
    getAllServiceGroups: (payload) => dispatch(getAllServiceGroups(payload)),
    getAllCountryCode: () => dispatch(getAllCountryCode()),
  };
};

export default withPermissionData(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(ServiceGroupForm)));
