/* eslint-disable max-len */
import React, {Component} from 'react';

import DateFnsUtils from '@date-io/date-fns';
import {Container, Fade, Paper, Grid, TextField, InputLabel, Button, MenuItem, Select, FormControl, Dialog, FormHelperText, Modal, Backdrop} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import BlockIcon from '@material-ui/icons/Block';
import CancelIcon from '@material-ui/icons/Cancel';
import CloseIcon from '@material-ui/icons/Close';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import ExitIcon from '@material-ui/icons/ExitToApp';
import ReplayIcon from '@material-ui/icons/Replay';
import RoomIcon from '@material-ui/icons/Room';
import SupervisedUserIcon from '@material-ui/icons/SupervisedUserCircleOutlined';
import ForwardIcon from '@material-ui/icons/TrendingFlat';
import VerifyIcon from '@material-ui/icons/VerifiedUserOutlined';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import _ from 'lodash';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';

import {COUNTRY, VERIFY_MOBILE_STATUS, UPDATE_INFO_STATUS, VERIFY_EMAIL_STATUS, MEMBER_STATUS_NEW, REGISTRATION_PLATFORM, MEMBER_GENDER, CURRENCY, ROUTE, SIGN_UP_TYPE} from '../../../common/constant';
import Memo from '../../../components/memo';
import MuiPhoneNumberInput from '../../../components/mui-phone-number-input';
import SelectModal from '../../../components/selectModal';
import {trialAgainApi} from '../../../services/businessServices';
import {searchMemberApi, forceLogoutApi, checkUnsubscribeApi} from '../../../services/memberServices';
import {searchListSubScription} from '../../../stores/business/action';
import {checkOwnerCard, deleteCreditCard} from '../../../stores/business/action';
import {getAllCountryCode} from '../../../stores/common/actions';
import {getMemberDetail, updateMember, cancelMember} from '../../../stores/member/action';
import {setMessageModal} from '../../../stores/modal/actions';
import {onChangeTextField, onChangeListData, getRawPhoneNumber, customDisplayCurrency, isValidEmail, nricNumberFormat, getDialCodePhoneNumber} from '../../../utils/common';
import {displayDate, getClientTimezone} from '../../../utils/datetime';
import {modalObj} from '../../../utils/modal';
import ReferrerListModal from '../referrer_list';
import PreUnsubscribeModal from './pre_unsubscribe';

/**
 * Member Update view
 */
class MemberDetailComponent extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      uuid: '',
      userCode: '',
      status: '',
      enrollmentDate: '',
      birthday: null,
      gender: 'NONE',
      memberId: '',
      mobile: '',
      email: '',
      exists_ref_users: null,
      firstName: '',
      lastName: '',
      last_name_furigana: '',
      first_name_furigana: '',
      province: '',
      district: '',
      total_one_time: '',
      password: '',
      note: '',
      memos: [],
      start: {
        mobile: '',
        email: '',
      },
      preUnsubscribeStatus: {
        current_booking: {},
        current_subscriptions: [],
      },
      cardInfo: [],
      device_info: {},

      subscriptionStatus: false,
      checkEmail: true,
      loading: true,
      isSubmitForm: false,
      cardConfirm: false,
      hasTrial: false,
      openForceLogoutConfirmModal: false,
      openPreUnsubscribeModal: false,
      openUpdateConfirmModal: false,
      openContinueFreeTrialConfirmModal: false,
      openConfirmDeleteCreditCardModal: false,
      referrerListModalOpened: false,
      deleteCreditCardId: null,
      message: '',
      dataCountry: {
        countryCode: 'jp',
        dialCode: '81',
        name: 'Japan',
      },
      countries: COUNTRY,
      nricNumber: null,
      passportNumber: null,
    };
    this.handleButtonOk = this.handleButtonOk.bind(this);
    this.handleButtonCancel = this.handleButtonCancel.bind(this);
    this.handleClickClose = this.handleClickClose.bind(this);
    this.validator = new SimpleReactValidator({
      validators: {
        nric: {
          message: '',
          rule: (val, params, validator) => {
            return validator.helpers.testRegex(val, /^\d{6}-\d{2}-\d{4}$/) && params.indexOf(val) === -1;
          },
          messageReplace: (message, params) => message.replace(':values', this.helpers.toSentence(params)),
        },
        passportNumber: {
          message: '',
          rule: (val, params, validator) => {
            return validator.helpers.testRegex(val, /^(?!^0+$)[a-zA-Z0-9]{3,20}$/) && params.indexOf(val) === -1;
          },
          messageReplace: (message, params) => message.replace(':values', this.helpers.toSentence(params)),
        },
      },
    });
  }

  /**
   * handleButtonCancel
   */
  handleButtonCancel() {
    this.setState({
      openUpdateConfirmModal: false,
    });
  }

  /**
   * handleClickClose
   */
  handleClickClose() {
    this.props.onClickClose();
  }

  /**
   * componentWillUpdate
   * @param {props} nextProps
   */
  componentWillUpdate(nextProps) {
    if (this.props.member.memberDetail !== nextProps.member.memberDetail) {
      this.setState({
        loading: false,
      });
    }
  }

  /**
   * getUserDetail
   */
  getUserDetail = () => {
    const {detailId} = this.props;
    this.props.getMemberDetail(detailId).then(async (data) => {
      if (data) {
        if (data.mobile?.includes('+')) {
          this.setState({
            dataCountry: this.state.countries.find((item) => item.dialCode === getDialCodePhoneNumber(data.mobile) && item.prefix_number_id === data?.prefix_number?.id),
          });
        }
        this.setState({
          memos: data.memos,
          id: data.id,
          userCode: data.member_id,
          status: data.status,
          type: data.type,
          enrollmentDate: data.created_date ? displayDate(data.created_date, true) : '',
          birthday: data.birthday,
          gender: data.gender,
          memberId: data.member_id,
          mobile: data.mobile?.includes('+') ? data.mobile : '+' + this.state.dataCountry?.dialCode + data.raw_mobile,
          email: data.email,
          start: {
            mobile: data.mobile,
            email: data.email,
          },
          exists_ref_users: data.exists_ref_users,
          firstName: data.first_name,
          lastName: data.last_name,
          password: data.password,
          last_name_furigana: data.last_name_furigana,
          first_name_furigana: data.first_name_furigana,
          province: data?.province,
          district: data?.district,
          uuid: data?.user_uuid,
          total_one_time: data?.total_one_time,
          hasTrial: data.has_trial,
          device_info: data?.device_info ? data?.device_info : {},
          nricNumber: data.nric_number ? nricNumberFormat(data.nric_number, 6, 8) : null,
          passportNumber: data.passport || null,
        });
        this.props.searchListSubScription({user_code: data.member_id}, {page: 0, size: 1}).then((response) => {
          this.setState({subscriptionStatus: response.totalSize > 0});
        });
        this.confirmCreditCard(data.id);
      }
    });
  };

  /**
   * componentDidMount
   */
  componentDidMount = async () => {
    this.props.getAllCountryCode().then((res) => {
      if (res) {
        const countries = this.state.countries.map((country) => ({
          ...country,
          prefix_number_id: res.find((c) => c.country_code === country.countryCode.toUpperCase())?.prefix_number_config?.id,
        }));
        this.setState({countries});
        this.getUserDetail();
      }
    });
  };

  /**
   * convertFormatCurrency
   * @param {*} type
   * @param {*} amount
   * @return {String}
   */
  convertFormatCurrency = (type, amount) => {
    const currency = CURRENCY.find((item) => item.id === type);
    return currency?.getLabel(amount);
  };

  /**
   * checkExisted
   * @param {string} field
   * @return {boolean}
   */
  checkExisted = async (field) => {
    if (this.state[field] === '' || this.state[field] === null || this.state[field] === this.state.start[field]) return true;
    const queryParams = {page: 0, size: 5};
    const payload = {statuses: [], zone_id: getClientTimezone()};
    payload[field] = this.state[field];
    const response = await searchMemberApi(payload, queryParams);
    if (response.status === 200) {
      const data = response.result.content;
      if (data.length === 0 || !data.find((item) => item[field] === this.state[field])) return true;
      return false;
    }
  };

  /**
   * handleChangeUpdate
   */
  async handleChangeUpdate() {
    this.setState({
      isSubmitForm: true,
      checkEmail: await this.checkExisted('email'),
    });
    if (!this.state.checkEmail) return;
    if (
      this.validator.allValid() &&
      (!this.state.email || isValidEmail(this.state.email.trim())) &&
      (this.state.mobile && this.checkMobile())
    ) this.setState({openUpdateConfirmModal: true});
  }

  /**
   * checkMobile
   * @return {*}
   */
  checkMobile = () => {
    const {mobile, dataCountry} = this.state;
    const mobile_copy = mobile?.slice(dataCountry?.dialCode?.length + 1);
    return !(mobile_copy === 'null' || mobile_copy === '' || mobile_copy === null);
  }

  /**
   * handleButtonOk
   */
  handleButtonOk() {
    this.handleButtonCancel();
    this.updateMember();
  }

  /**
   * forceLogout
   */
  forceLogout = async () => {
    await forceLogoutApi(this.state.id).then(
      (response) => {
        this.props.setMessageModal(modalObj(true, response.message_code));
      },
      () => {
        this.props.setMessageModal(modalObj(true, this.props.t('Api.fail')));
      },
    );
    this.closeForceLogoutModal();
  };

  /**
   * closeForceLogoutModal
   */
  closeForceLogoutModal = () => {
    this.setState({openForceLogoutConfirmModal: false});
  };

  /**
   * checkUnsubscribe
   */
  checkUnsubscribe = async () => {
    await checkUnsubscribeApi(this.state.id).then(
      (response) => {
        if (response.status === 200) {
          this.setState({
            openPreUnsubscribeModal: true,
            preUnsubscribeStatus: response.result,
          });
        } else this.props.setMessageModal(modalObj(true, response.message_code));
      },
      () => this.props.setMessageModal(modalObj(true, this.props.t('Api.fail'))),
    );
  };

  /**
   * cancelMember
   * @param {Boolean} sendMail
   */
  cancelMember(sendMail) {
    const payload = {
      id: this.state.id,
      notify: sendMail,
    };
    this.closePreUnsubscribeModal();
    this.props.cancelMember(payload, this.props).then(() => this.getUserDetail());
  }

  /**
   * closePreUnsubscribeModal
   */
  closePreUnsubscribeModal = () => {
    this.setState({openPreUnsubscribeModal: false});
  };

  /**
   * updateMember
   */
  updateMember() {
    const {
      id,
      firstName,
      lastName,
      gender,
      birthday,
      note,
      memos,
      first_name_furigana,
      last_name_furigana,
      province,
      district,
      mobile,
      email,
      password,
      nricNumber,
      passportNumber,
    } = this.state;
    let date = null;
    if (birthday) {
      date = new Date(birthday);
      date = new Date(date.setDate(date.getDate() + 1)).setHours(0, 0, 0, 0);
      date = new Date(date).toISOString().slice(0, 10);
    }
    let nric_number = '';
    if (nricNumber) {
      const nricNumberArray = nricNumber.split('-');
      nricNumberArray.forEach((nric) => {
        nric_number += nric;
      });
    }
    const payload = {
      id,
      gender: gender === null ? 'NONE' : gender,
      birthday: date,
      first_name: firstName?.trim(),
      last_name: lastName?.trim(),
      first_name_furigana: first_name_furigana?.trim(),
      last_name_furigana: last_name_furigana?.trim(),
      province: province?.trim(),
      district: district?.trim(),
      mobile: getRawPhoneNumber(mobile, this.state.dataCountry),
      raw_mobile: mobile.includes('+') ? mobile.replace(/\s/g, '').slice(this.state.dataCountry?.dialCode.length + 1) : mobile,
      email: email?.trim(),
      password: password?.trim(),
      note: note?.trim(),
      memos,
      nric_number: nric_number || null,
      passport: passportNumber || null,
      prefix_number_id: this.state.dataCountry?.prefix_number_id,
    };
    this.props.updateMember(payload, this.props).then(() => this.props.onUpdated());
  }

  /**
   * confirmCreditCard
   * @param {number} id
   */
  async confirmCreditCard(id) {
    this.props.checkOwnerCard(id).then((response) => {
      this.setState({
        cardInfo: {
          'veritrans_cards': response?.veritrans_cards?.find((item) => Number(item.default_card) === 1) || {},
          '2c2p_cards': response['2c2p_cards']?.find((item) => Number(item.default_card) === 1) || {},
          'alepay_cards': response['alepay_cards']?.find((item) => Number(item.default_card) === 1) || {},
          '2c2p_my_cards': response['2c2p_my_cards']?.find((item) => Number(item.default_card) === 1) || {},
        },
        cardConfirm: true,
      });
    });
  }

  /**
   * continueFreeTrial
   */
  continueFreeTrial = async () => {
    this.closeContinueFreeTrialConfirmModal();
    const response = await trialAgainApi(this.state.id);
    this.props.setMessageModal(modalObj(true, response.message_code));
    if (response.status === 200) this.setState({hasTrial: false});
  };

  /**
   * closeContinueFreeTrialConfirmModal
   */
  closeContinueFreeTrialConfirmModal = () => {
    this.setState({openContinueFreeTrialConfirmModal: false});
  };

  /**
   * changeNricNumber
   * @param {object} event
   */
  onChangeNricNumber = (event) => {
    let value = event.target.value;
    if (value?.length > 14) {
      return;
    } else {
      if ((value.length === 6 || value.length === 9) && ((this.state.keyCode >= 48 && this.state.keyCode <= 57) || (this.state.keyCode >= 96 && this.state.keyCode <= 105))) {
        value = value + '-';
      }
      if (value.length > 6 && value.length < 9 && !value.includes('-')) {
        value = nricNumberFormat(value, 6, 0);
      }
      if (value.length > 9 && value.split('-').length === 1) {
        value = nricNumberFormat(value, 6, 8);
      }
      this.setState({nricNumber: value.trim()});
    }
  };

  /**
   * onChangePassportNumber
   * @param {object} event
   */
  onChangePassportNumber = (event) => {
    const value = event.target.value;
    if (value?.length > 20) {
      this.setState({passportNumber: value?.trim().toUpperCase().substring(0, 20)});
      return;
    } else {
      this.setState({passportNumber: value?.trim().toUpperCase()});
      return;
    }
  };

  /**
   * keyPressNricNumber
   * @param {object} event
   */
  keyPressNricNumber = (event) => {
    this.setState({keyCode: event.keyCode});
  };

  /**
   * Render component
   * @return {object}
   */
  render() {
    const {t, member, permission, business} = this.props;
    const {mobile, dataCountry} = this.state;
    const dialCode = dataCountry?.dialCode;
    if (this.state.loading) {
      return <LoadingOverlay active={member.loading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner></LoadingOverlay>;
    }
    return (
      <Fade in={true}>
        <Paper className="modal_size_mid">
          <LoadingOverlay active={business?.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
            <br></br>
            <Container maxWidth="xl">
              <Grid container spacing={2}>
                <Grid container alignItems="center" className="page_header" item xs={8}>
                  <img src={`${process.env.PUBLIC_URL}/favicon.ico`} alt="Logo" width="35" height="33"></img>
                  <span className="font_bold font_size_big">{t('route.updateMember')}</span>
                </Grid>
                <Grid container alignItems="center" justify="flex-end" className="page_header" item xs={4}>
                  <CancelIcon onClick={this.handleClickClose} className="cursor_pointer" />
                </Grid>
              </Grid>
            </Container>
            <br />

            <Container maxWidth="xl" minWidth="sm">
              <form autoComplete="off">
                <Grid container spacing={1}>
                  {/* member status */}
                  <Grid container alignItems="flex-start" item xs={4}>
                    <FormControl fullWidth margin="dense">
                      <InputLabel>{t('accountManagement.member_status')}</InputLabel>
                      <Select
                        labelWidth={165}
                        value={member?.memberDetail?.member_status || ''}
                        inputProps={{
                          name: 'member_status',
                          readOnly: true,
                        }}
                        disabled={true}
                      >
                        {MEMBER_STATUS_NEW.map(({name, i18n}, index) => {
                          return (
                            <MenuItem value={name} key={index}>
                              {t(i18n)}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* sign up type */}
                  <Grid container alignItems="flex-start" item xs={4}>
                    <FormControl fullWidth margin="dense">
                      <InputLabel>{t('accountManagement.signUpType')}</InputLabel>
                      <Select
                        labelWidth={165}
                        value={member?.memberDetail?.signup_types || []}
                        inputProps={{
                          name: 'signup_types',
                          readOnly: true,
                        }}
                        multiple
                        disabled={true}
                      >
                        {SIGN_UP_TYPE.map(({id, name}, index) => {
                          return (
                            <MenuItem value={id} key={index}>
                              {name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* registration platform */}
                  <Grid container alignItems="flex-start" item xs={4}>
                    <FormControl fullWidth margin="dense">
                      <InputLabel>{t('accountManagement.registration_platform')}</InputLabel>
                      <Select
                        labelWidth={165}
                        value={member?.memberDetail?.registration_platform || ''}
                        inputProps={{
                          name: 'registration_platform',
                          readOnly: true,
                        }}
                        disabled={true}
                      >
                        {REGISTRATION_PLATFORM.map(({name, i18n}, index) => {
                          return (
                            <MenuItem value={name} key={index}>
                              {t(i18n)}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>

                   {/* Verify mobile status */}
                   <Grid container alignItems="flex-start" item xs={4}>
                    <FormControl fullWidth margin="dense">
                      <InputLabel>{t('accountManagement.verify_mobile_status')}</InputLabel>
                      <Select
                        labelWidth={165}
                        value={member?.memberDetail?.mobile_status || ''}
                        inputProps={{
                          name: 'mobile_status',
                          readOnly: true,
                        }}
                        disabled={true}
                      >
                        {VERIFY_MOBILE_STATUS.map(({name, i18n}, index) => {
                          return (
                            <MenuItem value={name} key={index}>
                              {t(i18n)}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>

                   {/* Update info status */}
                   <Grid container alignItems="flex-start" item xs={4}>
                    <FormControl fullWidth margin="dense">
                      <InputLabel>{t('accountManagement.update_info_status')}</InputLabel>
                      <Select
                        labelWidth={165}
                        value={member?.memberDetail?.info_status || ''}
                        inputProps={{
                          name: 'info_status',
                          readOnly: true,
                        }}
                        disabled={true}
                      >
                        {UPDATE_INFO_STATUS.map(({name, i18n}, index) => {
                          return (
                            <MenuItem value={name} key={index}>
                              {t(i18n)}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>

                   {/* Verify email status */}
                   <Grid container alignItems="flex-start" item xs={4}>
                    <FormControl fullWidth margin="dense">
                      <InputLabel>{t('accountManagement.verify_email_status')}</InputLabel>
                      <Select
                        labelWidth={165}
                        value={member?.memberDetail?.email_status || ''}
                        inputProps={{
                          name: 'email_status',
                          readOnly: true,
                        }}
                        disabled={true}
                      >
                        {VERIFY_EMAIL_STATUS.map(({name, i18n}, index) => {
                          return (
                            <MenuItem value={name} key={index}>
                              {t(i18n)}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* date */}
                  <Grid container alignItems="flex-start" item xs={4}>
                    <TextField
                      margin="dense"
                      variant="outlined"
                      fullWidth
                      value={this.state.enrollmentDate || ''}
                      inputProps={{readOnly: true}}
                      disabled={true}
                      label={<span>{t('memberManagement.withdrawalDateTime')}</span>}
                    />
                  </Grid>

                  {/* birthday */}
                  <Grid container alignItems="flex-start" item xs={4}>
                    <FormControl className="width_100">
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          variant="inline"
                          views={['year', 'month', 'date']}
                          margin="dense"
                          autoOk
                          label={<span>{t('memberManagement.birthDay')}</span>}
                          inputVariant="outlined"
                          minDate="1900-01-01"
                          maxDate={new Date()}
                          format="yyyy-MM-dd"
                          value={this.state.birthday || null}
                          onChange={(birthday) => this.setState({birthday: birthday})}
                          KeyboardButtonProps={{
                            'aria-label': 'change time',
                          }}
                          invalidDateMessage={t('errorFields.invalidDateMessage')}
                          maxDateMessage={t('errorFields.maxDateMessage')}
                          minDateMessage={t('errorFields.minDateMessage')}
                        />
                      </MuiPickersUtilsProvider>
                    </FormControl>
                  </Grid>

                  {/* sex */}
                  <Grid container justify="center" alignItems="flex-start" item xs={4}>
                    <FormControl variant="outlined" fullWidth margin="dense">
                      <InputLabel>{t('memberManagement.sex')}</InputLabel>
                      <Select
                        labelWidth={165}
                        margin="dense"
                        inputProps={{
                          name: 'type',
                        }}
                        label={t('memberManagement.sex')}
                        value={this.state.gender || 'NONE'}
                        onChange={(gender) =>
                          this.setState({
                            gender: gender.target.value,
                          })
                        }
                      >
                        {MEMBER_GENDER.map(({name, i18n}, index) => {
                          return (
                            <MenuItem value={name} key={index}>
                              {t(`${i18n}`)}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* member id */}
                  <Grid container justify="center" alignItems="flex-start" item xs={4}>
                    <TextField
                      margin="dense"
                      variant="outlined"
                      fullWidth
                      inputProps={{maxLength: 256, readOnly: true}}
                      disabled={true}
                      value={this.state.memberId || ''}
                      label={
                        <span>
                          {t('memberManagement.memberId')}
                          <span className="font_color_red">＊</span>
                        </span>
                      }
                    />
                  </Grid>

                  {/* email */}
                  <Grid container justify="center" alignItems="flex-start" item xs={4}>
                    <FormControl className="width_100">
                      <TextField
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        name="email"
                        inputProps={{maxLength: 64}}
                        value={this.state.email || ''}
                        label={<span>{t('memberManagement.memberEmail')}</span>}
                        onChange={(event) => onChangeTextField(this, event)}
                      />
                      <div>{t('validation.max.label', {value: 64})}</div>
                      <Grid container spacing={1}>
                        {this.state.isSubmitForm && this.state.email && !isValidEmail(this.state.email.trim()) && (
                          <Grid container justify="flex-start" alignItems="flex-start" item xs={12}>
                            <FormHelperText error>{t('validation.email')}</FormHelperText>
                          </Grid>
                        )}
                        {this.state.isSubmitForm && !this.state.checkEmail && (
                          <Grid container justify="flex-start" alignItems="flex-start" item xs={12}>
                            <FormHelperText error>{t('validation.existed.field', {field: t('common.email')})}</FormHelperText>
                          </Grid>
                        )}
                      </Grid>
                    </FormControl>
                  </Grid>

                  {/* password */}
                  <Grid container justify="center" alignItems="flex-start" item xs={4}>
                    <FormControl className="width_100">
                      <TextField
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        value={this.state.password || ''}
                        name="password"
                        autoComplete="new-password"
                        onChange={(event) => onChangeTextField(this, event)}
                        type="password"
                        label={<span>{t('common.password')}</span>}
                      />
                      <div>{t('validation.max.label', {value: '8 - 32'})}</div>
                      {this.validator.message('password', this.state.password?.trim(), 'between:8,32,string')}
                      <Grid container spacing={1}>
                        {this.validator.check(this.state.password?.trim(), 'required') && this.state.password.length > this.state.password?.split(' ').join('').length && (
                          <Grid container justify="flex-start" alignItems="flex-start" item xs={12}>
                            <FormHelperText error>{t('validation.space.not_allowed')}</FormHelperText>
                          </Grid>
                        )}
                        {!this.validator.check(this.state.password?.trim(), 'between:8,32,string') && (
                          <Grid container justify="flex-start" alignItems="flex-start" item xs={12}>
                            <FormHelperText error>{t('validation.between', {field: t('common.password'), min: 8, max: 32})}</FormHelperText>
                          </Grid>
                        )}
                      </Grid>
                    </FormControl>
                  </Grid>

                  {/* last name */}
                  <Grid container justify="center" alignItems="flex-start" item xs={6}>
                    <FormControl className="width_100">
                      <TextField
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        value={this.state.lastName || ''}
                        name="lastName"
                        label={
                          <span>
                            {t('updateMember.lastName')}
                            <span className="font_color_red">＊</span>
                          </span>
                        }
                        onChange={(event) => onChangeTextField(this, event)}
                      />
                      <div>{t('validation.max.label', {value: 20})}</div>
                      {this.validator.message('lastName', this.state.lastName?.trim(), 'required|max:20')}
                      <Grid container spacing={1}>
                        {this.state.isSubmitForm && !this.validator.check(this.state.lastName?.trim(), 'required') && (
                          <Grid container justify="flex-start" alignItems="flex-start" item xs={12}>
                            <FormHelperText id="lastName" error>
                              {t('validation.required', {field: t('updateMember.lastName')})}
                            </FormHelperText>
                          </Grid>
                        )}
                        {this.state.isSubmitForm && !this.validator.check(this.state.lastName?.trim(), 'max:20') && (
                          <Grid container justify="flex-start" alignItems="flex-start" item xs={12}>
                            <FormHelperText error>{t('validation.maxString', {value: 20})}</FormHelperText>
                          </Grid>
                        )}
                      </Grid>
                    </FormControl>
                  </Grid>

                  {/* first name */}
                  <Grid container justify="center" alignItems="flex-start" item xs={6}>
                    <FormControl className="width_100">
                      <TextField
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        value={this.state.firstName || ''}
                        name="firstName"
                        label={
                          <span>
                            {t('updateMember.firstName')}
                            <span className="font_color_red">＊</span>
                          </span>
                        }
                        onChange={(event) => onChangeTextField(this, event)}
                      />
                      <div>{t('validation.max.label', {value: 20})}</div>
                      {this.validator.message('firstName', this.state.firstName?.trim(), 'required|max:20')}
                      <Grid container spacing={1}>
                        {this.state.isSubmitForm && !this.validator.check(this.state.firstName?.trim(), 'required') && (
                          <Grid container justify="flex-start" alignItems="flex-start" item xs={12}>
                            <FormHelperText id="firstName" error>
                              {t('validation.required', {field: t('updateMember.firstName')})}
                            </FormHelperText>
                          </Grid>
                        )}
                        {this.state.isSubmitForm && !this.validator.check(this.state.firstName?.trim(), 'max:20') && (
                          <Grid container justify="flex-start" alignItems="flex-start" item xs={12}>
                            <FormHelperText error>{t('validation.maxString', {value: 20})}</FormHelperText>
                          </Grid>
                        )}
                      </Grid>
                    </FormControl>
                  </Grid>

                  {/* last name furigana */}
                  <Grid container justify="center" alignItems="flex-start" item xs={6}>
                    <FormControl className="width_100">
                      <TextField
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        value={this.state.last_name_furigana || ''}
                        name="last_name_furigana"
                        label={
                          <span>
                            {t('updateMember.lastName_furigana')}
                            <span className="font_color_red">＊</span>
                          </span>
                        }
                        onChange={(event) => onChangeTextField(this, event)}
                      />
                      <div>{t('validation.max.label', {value: 20})}</div>
                      {this.validator.message('last_name_furigana', this.state.last_name_furigana?.trim(), 'required|max:20')}
                      <Grid container spacing={1}>
                        {this.state.isSubmitForm && !this.validator.check(this.state.last_name_furigana?.trim(), 'required') && (
                          <Grid container justify="flex-start" alignItems="flex-start" item xs={12}>
                            <FormHelperText id="last_name_furigana" error>
                              {t('validation.required', {field: t('updateMember.lastName_furigana')})}
                            </FormHelperText>
                          </Grid>
                        )}
                        {this.state.isSubmitForm && !this.validator.check(this.state.last_name_furigana?.trim(), 'max:20') && (
                          <Grid container justify="flex-start" alignItems="flex-start" item xs={12}>
                            <FormHelperText error>{t('validation.maxString', {value: 20})}</FormHelperText>
                          </Grid>
                        )}
                      </Grid>
                    </FormControl>
                  </Grid>

                  {/* first name furigana */}
                  <Grid container justify="center" alignItems="flex-start" item xs={6}>
                    <FormControl className="width_100">
                      <TextField
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        name="first_name_furigana"
                        value={this.state.first_name_furigana || ''}
                        label={
                          <span>
                            {t('updateMember.firstName_furigana')}
                            <span className="font_color_red">＊</span>
                          </span>
                        }
                        onChange={(event) => onChangeTextField(this, event)}
                      />
                      <div>{t('validation.max.label', {value: 20})}</div>
                      {this.validator.message('first_name_furigana', this.state.first_name_furigana?.trim(), 'required|max:20')}
                      <Grid container spacing={1}>
                        {this.state.isSubmitForm && !this.validator.check(this.state.first_name_furigana?.trim(), 'required') && (
                          <Grid container justify="flex-start" alignItems="flex-start" item xs={12}>
                            <FormHelperText id="firstName_furigana" error>
                              {t('validation.required', {field: t('updateMember.firstName_furigana')})}
                            </FormHelperText>
                          </Grid>
                        )}
                        {this.state.isSubmitForm && !this.validator.check(this.state.first_name_furigana?.trim(), 'max:20') && (
                          <Grid container justify="flex-start" alignItems="flex-start" item xs={12}>
                            <FormHelperText error>{t('validation.maxString', {value: 20})}</FormHelperText>
                          </Grid>
                        )}
                      </Grid>
                    </FormControl>
                  </Grid>

                  {/* NRIC Number */}
                  <Grid container justify="center" alignItems="flex-start" item xs={6}>
                    <FormControl className="width_100 letter-spacing-2">
                      <TextField
                        label={<span>{t('memberManagement.nricNumber')}</span>}
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        name="nricNumber"
                        value={this.state.nricNumber}
                        onKeyDown={(event) => this.keyPressNricNumber(event)}
                        onChange={(event) => this.onChangeNricNumber(event)}
                      />
                      <div>{t('validation.max.label', {value: 12})}</div>
                      {this.validator.message('nricNumber', this.state.nricNumber, 'nric')}
                      {this.state.isSubmitForm && !this.validator.check(this.state.nricNumber, 'nric') && (
                        <FormHelperText id="nricNumber" error>
                          {t('memberManagement.nircNumbericValidation')}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>

                  {/* Passport Number */}
                  <Grid container justify="center" alignItems="flex-start" item xs={6}>
                    <FormControl className="width_100 letter-spacing-2">
                      <TextField
                        label={<span>{t('memberManagement.passportNumber')}</span>}
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        name="passportNumber"
                        value={this.state.passportNumber}
                        onChange={(event) => this.onChangePassportNumber(event)}
                      />
                      <div>{t('validation.max.label', {value: '20'})}</div>
                      {this.validator.message('passportNumber', this.state.passportNumber, 'passportNumber')}
                      {this.state.isSubmitForm && !this.validator.check(this.state.passportNumber, 'passportNumber') && (
                        <FormHelperText id="passportNumber" error>
                          {t('memberManagement.passportNummberValidation')}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>

                  {/* uuid */}
                  <Grid container justify="center" alignItems="flex-start" item xs={12}>
                    <Grid container item xs={12}>
                      <TextField
                        label={<span>{t('memberManagement.uuid')}</span>}
                        variant="outlined"
                        margin="dense"
                        value={this.state.uuid || ''}
                        disabled={true}
                        fullWidth
                        inputProps={{maxLength: 256, readOnly: true}}
                      />
                    </Grid>
                  </Grid>

                  {/* Confirm credit card */}
                  {permission.canConfirmCreditCard && (
                    <Grid container justify="flex-start" alignItems="flex-start" item xs={12}>
                      <Button color="primary" variant="contained" className="button_margin" onClick={() => this.confirmCreditCard(this.state.id)} endIcon={<VerifyIcon />}>
                        {t('memberManagement.confirm_credit_card')}
                      </Button>
                    </Grid>
                  )}

                  {/* Credit card information */}
                  {this.state.cardConfirm && (
                    <>
                      <Grid container justify="flex-start" alignItems="flex-start" item xs={1}>
                        JP
                      </Grid>
                      <Grid container justify="flex-start" alignItems="flex-start" item xs={1}>
                        <ForwardIcon
                          style={{
                            position: 'relative',
                            fontSize: 30,
                            top: -6,
                            color: '#3f51b5',
                          }}
                        ></ForwardIcon>
                      </Grid>
                      {Object.keys(this.state.cardInfo?.veritrans_cards).length > 0 ? (
                        <>
                          <Grid container justify="flex-start" alignItems="flex-start" item xs={4}>
                            {t('memberManagement.cardNumber')}:{' '}
                            <span className="font_color_blue" style={{marginLeft: '10px'}}>
                              {this.state.cardInfo?.veritrans_cards?.card_number}
                            </span>
                          </Grid>
                          <Grid container justify="flex-start" alignItems="flex-start" item xs={6}>
                            {t('memberManagement.cardExpire')}:{' '}
                            <span className="font_color_blue" style={{marginLeft: '10px'}}>
                              {this.state.cardInfo?.veritrans_cards?.card_expire}
                            </span>
                          </Grid>
                        </>
                      ) : (
                        <Grid container justify="flex-start" alignItems="flex-start" item xs={10}>
                          <span className="font_color_red">{t('memberManagement.cantGetCardInfo')}</span>
                        </Grid>
                      )}
                      <Grid container justify="flex-start" alignItems="flex-start" item xs={1}>
                        SG
                      </Grid>
                      <Grid container justify="flex-start" alignItems="flex-start" item xs={1}>
                        <ForwardIcon
                          style={{
                            position: 'relative',
                            fontSize: 30,
                            top: -6,
                            color: '#3f51b5',
                          }}
                        ></ForwardIcon>
                      </Grid>
                      {Object.keys(this.state.cardInfo['2c2p_cards']).length > 0 ? (
                        <>
                          <Grid container justify="flex-start" alignItems="flex-start" item xs={4}>
                            {t('memberManagement.cardNumber')}:{' '}
                            <span className="font_color_blue" style={{marginLeft: '10px'}}>
                              {this.state.cardInfo['2c2p_cards']?.card_number}
                            </span>
                          </Grid>
                          <Grid container justify="flex-start" alignItems="flex-start" item xs={3}>
                            {t('memberManagement.cardExpire')}:{' '}
                            <span className="font_color_blue" style={{marginLeft: '10px'}}>
                              {this.state.cardInfo['2c2p_cards']?.card_expire}
                            </span>
                          </Grid>
                          <Grid container justify="flex-start" alignItems="flex-start" item xs={3}></Grid>
                        </>
                      ) : (
                        <Grid container justify="flex-start" alignItems="flex-start" item xs={10}>
                          <span className="font_color_red">{t('memberManagement.cantGetCardInfo')}</span>
                        </Grid>
                      )}
                      <Grid container justify="flex-start" alignItems="flex-start" item xs={1}>
                        VN
                      </Grid>
                      <Grid container justify="flex-start" alignItems="flex-start" item xs={1}>
                        <ForwardIcon
                          style={{
                            position: 'relative',
                            fontSize: 30,
                            top: -6,
                            color: '#3f51b5',
                          }}
                        ></ForwardIcon>
                      </Grid>
                      {Object.keys(this.state.cardInfo?.alepay_cards).length > 0 ? (
                        <>
                          <Grid container justify="flex-start" alignItems="flex-start" item xs={4}>
                            {t('memberManagement.cardNumber')}:{' '}
                            <span className="font_color_blue" style={{marginLeft: '10px'}}>
                              {this.state.cardInfo?.alepay_cards?.card_number}
                            </span>
                          </Grid>
                          <Grid container justify="flex-start" alignItems="flex-start" item xs={6}>
                            {t('memberManagement.cardExpire')}:{' '}
                            <span className="font_color_blue" style={{marginLeft: '10px'}}>
                              {this.state.cardInfo?.alepay_cards?.card_expire}
                            </span>
                          </Grid>
                        </>
                      ) : (
                        <Grid container justify="flex-start" alignItems="flex-start" item xs={10}>
                          <span className="font_color_red">{t('memberManagement.cantGetCardInfo')}</span>
                        </Grid>
                      )}
                      <Grid container alignItems='flex-start' item xs={1}>
                        MY
                      </Grid>
                      <Grid container alignItems='flex-start' item xs={1}>
                        <ForwardIcon
                          style={{
                            position: 'relative',
                            fontSize: 30,
                            top: -6,
                            color: '#3f51b5',
                          }}
                        />
                      </Grid>
                      {Object.keys(this.state.cardInfo['2c2p_my_cards']).length > 0 ? (
                        <>
                          <Grid container alignItems='flex-start' item xs={4}>
                            {t('memberManagement.cardNumber')}:{' '}
                            <span className='font_color_blue' style={{marginLeft: '10px'}}>
                              {this.state.cardInfo['2c2p_my_cards']?.card_number}
                            </span>
                          </Grid>
                          <Grid container alignItems='flex-start' item xs={6}>
                            {t('memberManagement.cardExpire')}:{' '}
                            <span className="font_color_blue" style={{marginLeft: '10px'}}>
                              {this.state.cardInfo['2c2p_my_cards']?.card_expire}
                            </span>
                          </Grid>
                        </>
                      ) : (
                        <Grid container alignItems='flex-start' item xs={10}>
                          <span className='font_color_red'>{t('memberManagement.cantGetCardInfo')}</span>
                        </Grid>
                      )}
                    </>
                  )}

                  {/* total_one_time */}
                  {(this.state.total_one_time?.JPY || this.state.total_one_time?.JPY === 0) && (
                    <Grid container justify="center" alignItems="flex-start" item xs={4}>
                      <Grid container item xs={12}>
                        <TextField
                          label={
                            <span>
                              {t('memberManagement.total_one_time')} ({t('JP')})
                            </span>
                          }
                          variant="outlined"
                          margin="dense"
                          value={customDisplayCurrency(this.state.total_one_time?.JPY, 'JPY')}
                          disabled={true}
                          fullWidth
                          inputProps={{readOnly: true}}
                        />
                      </Grid>
                    </Grid>
                  )}
                  {(this.state.total_one_time?.SGD || this.state.total_one_time?.SGD === 0) && (
                    <Grid container justify="center" alignItems="flex-start" item xs={4}>
                      <Grid container item xs={12}>
                        <TextField
                          label={
                            <span>
                              {t('memberManagement.total_one_time')} ({t('SG')})
                            </span>
                          }
                          variant="outlined"
                          margin="dense"
                          value={customDisplayCurrency(this.state.total_one_time?.SGD, 'SGD')}
                          disabled={true}
                          fullWidth
                          inputProps={{readOnly: true}}
                        />
                      </Grid>
                    </Grid>
                  )}
                  {(this.state.total_one_time?.VND || this.state.total_one_time?.VND === 0) && (
                    <Grid container justify="center" alignItems="flex-start" item xs={4}>
                      <Grid container item xs={12}>
                        <TextField
                          label={
                            <span>
                              {t('memberManagement.total_one_time')} ({t('VN')})
                            </span>
                          }
                          variant="outlined"
                          margin="dense"
                          value={customDisplayCurrency(this.state.total_one_time?.VND, 'VND')}
                          disabled={true}
                          fullWidth
                          inputProps={{readOnly: true}}
                        />
                      </Grid>
                    </Grid>
                  )}
                  {(this.state.total_one_time?.MYR || this.state.total_one_time?.MYR === 0) && (
                    <Grid container justify="center" alignItems="flex-start" item xs={4}>
                      <Grid container item xs={12}>
                        <TextField
                          label={<span>{t('memberManagement.total_one_time')} ({t('MY')})</span>}
                          variant="outlined"
                          margin="dense"
                          value={customDisplayCurrency(this.state.total_one_time?.MYR, 'MYR')}
                          disabled={true}
                          fullWidth
                          inputProps={{readOnly: true}}
                        />
                      </Grid>
                    </Grid>
                  )}

                  {/* province */}
                  <Grid container justify="center" alignItems="flex-start" item xs={4}>
                    <FormControl className="width_100">
                      <TextField
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        value={this.state.province || ''}
                        name="province"
                        label={<span>{t('updateMember.province')}</span>}
                        onChange={(event) => onChangeTextField(this, event)}
                      />
                      <div>{t('validation.max.label', {value: 20})}</div>
                      {this.validator.message('province', this.state.province?.trim(), 'max:20')}
                      {this.state.isSubmitForm && !this.validator.check(this.state.province?.trim(), 'max:20') && (
                        <FormHelperText error>{t('validation.maxString', {value: 20})}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>

                  {/* district */}
                  <Grid container justify="center" alignItems="flex-start" item xs={4}>
                    <FormControl className="width_100">
                      <TextField
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        value={this.state.district || ''}
                        name="district"
                        label={<span>{t('updateMember.district')}</span>}
                        onChange={(event) => onChangeTextField(this, event)}
                      />
                      <div>{t('validation.max.label', {value: 20})}</div>
                      {this.validator.message('district', this.state.district?.trim(), 'max:20')}
                      {this.state.isSubmitForm && !this.validator.check(this.state.district?.trim(), 'max:20') && (
                        <FormHelperText error>{t('validation.maxString', {value: 20})}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>

                  {/* phone number */}
                  <Grid container justify="center" alignItems="flex-start" item xs={4}>
                    <FormControl className="width_100">
                      <MuiPhoneNumberInput
                        ref="mobile"
                        defaultCountry={this.state.dataCountry?.countryCode}
                        onlyCountries={this.state.countries.map((c) => c.countryCode)}
                        label={
                          <span>
                            {t('common.phoneNumber')}
                            <span className="font_color_red">＊</span>
                          </span>
                        }
                        countryCodeEditable={false}
                        enableLongNumbers={true}
                        autoFormat={false}
                        margin="dense"
                        variant="outlined"
                        name="mobile"
                        value={this.state.mobile}
                        onChange={(value, country) => {
                          this.setState({dataCountry: this.state.countries.find((c) => c.countryCode === country?.countryCode)}, () => {
                            this.setState({mobile: value.trim()});
                          });
                        }}
                      />
                      <div>{t('validation.max.label', {value: 13})}</div>
                      {this.validator.message('mobile', mobile?.slice(dialCode?.length + 1)?.trim(), 'required')}
                      <Grid container spacing={1}>
                        {this.state.isSubmitForm && !(
                          this.state.mobile && this.checkMobile()
                          ) && (
                            <Grid container justify="flex-start" alignItems="flex-start" item xs={12}>
                              <FormHelperText id="mobile" error>
                                {t('validation.required', {field: t('common.phoneNumber')})}
                              </FormHelperText>
                            </Grid>
                          )}
                      </Grid>
                    </FormControl>
                  </Grid>

                  {/* Device information */}
                  {!_.isEmpty(this.state.device_info) && (
                    <>
                      <Grid container justify="flex-start" alignItems="flex-start" item xs={4}>
                        <FormControl className="width_100">
                          <TextField
                            margin="dense"
                            variant="outlined"
                            disabled
                            fullWidth
                            value={this.state.device_info.app_version || ''}
                            name="district"
                            label={<span>{t('updateMember.app_version')}</span>}
                          />
                        </FormControl>
                      </Grid>
                      <Grid container justify="flex-start" alignItems="flex-start" item xs={4}>
                        <FormControl className="width_100">
                          <TextField
                            margin="dense"
                            variant="outlined"
                            disabled
                            fullWidth
                            value={this.state.device_info.os || ''}
                            name="district"
                            label={<span>{t('updateMember.os')}</span>}
                          />
                        </FormControl>
                      </Grid>
                      <Grid container justify="flex-start" alignItems="flex-start" item xs={4}>
                        <FormControl className="width_100">
                          <TextField
                            margin="dense"
                            variant="outlined"
                            disabled
                            fullWidth
                            value={this.state.device_info.screen_size || ''}
                            name="district"
                            label={<span>{t('updateMember.screen_size')}</span>}
                          />
                        </FormControl>
                      </Grid>
                    </>
                  )}

                  {/* Subscriptions */}
                  {permission.canUserSubscriptionInformation && this.state.subscriptionStatus && (
                    <Grid container justify="flex-start" alignItems="flex-start" item xs={12}>
                      <Link style={{textDecoration: 'none'}} to={{pathname: ROUTE.LAYOUT + ROUTE.RESERVATION_SUBSCRIPTION, search: `?user_code=${this.state.userCode || ''}`}}>
                        <Button color="primary" variant="contained" className="button_margin button_color_yellow" endIcon={<SupervisedUserIcon />}>
                          {t('route.business_subscription')}
                        </Button>
                      </Link>
                    </Grid>
                  )}

                  {/* memo  */}
                  <Grid container justify="center" alignItems="center" item xs={12}>
                    <Container maxWidth="xl">
                      <br></br>
                      <Memo
                        memos={this.state.memos || ''}
                        onChangeContent={(value) => onChangeListData(this, this.state.memos, value.field, value.index, value.newValue)}
                        note={this.state.note}
                        onChangeNote={(value) => this.setState({note: value})}
                        parentType="medium"
                      />
                    </Container>
                  </Grid>
                </Grid>
              </form>
            </Container>

            <br />
            <Container maxWidth="xl">
              <Grid container>
                <Grid container justify="flex-start" alignItems="center" item xs={5}>
                  {this.state.status !== 'WITHDRAW' && (
                    <>
                      {permission.canUnsubscribe && (
                        <Button color="primary" variant="contained" className="button_margin button_color_red" endIcon={<BlockIcon />} onClick={() => this.checkUnsubscribe()}>
                          {t('common.btnUnsubscribe')}
                        </Button>
                      )}
                      {permission.canForceLogout && (
                        <Button
                          color="primary"
                          variant="contained"
                          className="button_margin button_color_red"
                          endIcon={<ExitIcon />}
                          onClick={() => this.setState({openForceLogoutConfirmModal: true})}
                        >
                          {t('memberManagement.force_logout')}
                        </Button>
                      )}
                    </>
                  )}
                </Grid>
                <Grid container justify="flex-end" alignItems="center" item xs={7}>
                  {this.state.cardConfirm && Object.keys(this.state.cardInfo?.veritrans_cards).length > 0 && (
                    <Link style={{textDecoration: 'none'}} to={{pathname: ROUTE.LAYOUT + ROUTE.MEMBER_SEARCH_TICKET + `/${this.props.detailId}`}}>
                      <Button style={{backgroundColor: '#ff9800'}} color="primary" variant="contained" className="button_margin">
                        {t('route.buyTrainTicket')}
                      </Button>
                    </Link>
                  )}
                  {this.state.exists_ref_users && (
                    <Button color="primary" variant="contained" className="button_margin" onClick={() => this.setState({referrerListModalOpened: true})}>
                      {t('route.memberManagementReferrer')}
                    </Button>
                  )}
                  {permission.canAccessWaypointFavorite && (
                    <Link style={{textDecoration: 'none'}} to={{pathname: ROUTE.LAYOUT + ROUTE.MEMBER_FAVORITES + `/${this.props.detailId}`}}>
                      <Button color="primary" variant="contained" className="button_margin" endIcon={<RoomIcon />}>
                        {t('route.memberFavoriteWaypoint')}
                      </Button>
                    </Link>
                  )}
                  {permission.canContinueFreeTrial && this.state.hasTrial && this.state.status !== 'WITHDRAW' && (
                    <Button
                      color="primary"
                      variant="contained"
                      className="button_margin button_color_info"
                      onClick={() => this.setState({openContinueFreeTrialConfirmModal: true})}
                      endIcon={<ReplayIcon />}
                    >
                      {t('sub.continue_free_trial')}
                    </Button>
                  )}
                  {permission.canRegisterSubscription && this.state.status === 'VERIFIED_EMAIL' && (
                    <Link style={{textDecoration: 'none'}} to={{pathname: ROUTE.LAYOUT + ROUTE.RESERVATION_SUBSCRIPTION_REGISTER, search: `?userId=${this.props.detailId}`}}>
                      <Button color="primary" variant="contained" className="button_margin button_color_green" endIcon={<AddIcon />}>
                        {t('sub.register_sub')}
                      </Button>
                    </Link>
                  )}
                  {permission.canUpdate && this.state.status !== 'WITHDRAW' && (
                    <Button color="primary" variant="contained" className="button_margin" onClick={() => this.handleChangeUpdate()} endIcon={<CloudUploadIcon />}>
                      {t('common.btnUpdate')}
                    </Button>
                  )}
                  <Button color="primary" variant="contained" className="button_margin button_color" onClick={this.handleClickClose} endIcon={<CloseIcon />}>
                    {t('common.btnClose')}
                  </Button>
                </Grid>
              </Grid>

              <br></br>
            </Container>
          </LoadingOverlay>

          {/* Modal confirm update */}
          <Dialog
            open={this.state.openUpdateConfirmModal}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: '1020',
            }}
          >
            <SelectModal onClickOk={this.handleButtonOk} onClickCancel={this.handleButtonCancel} message={t('updateMember.updateModal')}></SelectModal>
          </Dialog>

          {/* Modal confirm continue fre trial */}
          <Dialog
            open={this.state.openContinueFreeTrialConfirmModal}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: '1020',
            }}
          >
            <SelectModal
              onClickOk={this.continueFreeTrial}
              onClickCancel={this.closeContinueFreeTrialConfirmModal}
              message={t('memberManagement.confirm_free_trial')}
            ></SelectModal>
          </Dialog>

          {/* Modal confirm force logout */}
          <Dialog
            open={this.state.openForceLogoutConfirmModal}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: '1020',
            }}
          >
            <SelectModal onClickOk={this.forceLogout} onClickCancel={this.closeForceLogoutModal} message={t('memberManagement.confirm_force_logout')}></SelectModal>
          </Dialog>

          {/* Modal confirm delete credit card */}
          <Dialog
            open={this.state.openConfirmDeleteCreditCardModal}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: '1020',
            }}
          >
            <SelectModal
              onClickOk={() => {
                this.setState({openConfirmDeleteCreditCardModal: false});
              }}
              onClickCancel={() => this.setState({openConfirmDeleteCreditCardModal: false})}
              message={t('messageCode.confirm_delete_credit_card')}
            ></SelectModal>
          </Dialog>

          {/* Modal pre unsubscribe */}
          <Dialog
            open={this.state.openPreUnsubscribeModal}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: '1020',
            }}
          >
            <PreUnsubscribeModal
              preUnsubscribeStatus={this.state.preUnsubscribeStatus}
              confirmCancelMember={(sendMail) => this.cancelMember(sendMail)}
              onClickCancel={this.closePreUnsubscribeModal}
            ></PreUnsubscribeModal>
          </Dialog>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={this.state.referrerListModalOpened}
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: '1030',
            }}
          >
            <ReferrerListModal handleClose={() => this.setState({referrerListModalOpened: false})} memberId={this.props.detailId} memberCode={this.state.memberId} />
          </Modal>
        </Paper>
      </Fade>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    member: state.member,
    business: state.business,
    common: state.common,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getMemberDetail: (id) => dispatch(getMemberDetail(id)),
    updateMember: (payload, props) => dispatch(updateMember(payload, props)),
    cancelMember: (payload, props) => dispatch(cancelMember(payload, props)),
    setMessageModal: (payload) => dispatch(setMessageModal(payload)),
    searchListSubScription: (payload, queryParam) => dispatch(searchListSubScription(payload, queryParam)),
    checkOwnerCard: (userId) => dispatch(checkOwnerCard(userId)),
    deleteCreditCard: (cardId) => dispatch(deleteCreditCard(cardId)),
    getAllCountryCode: () => dispatch(getAllCountryCode()),
  };
};

export default withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(MemberDetailComponent));
