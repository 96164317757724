/* eslint-disable new-cap */
import {format, formatDistanceToNow, parseISO} from 'date-fns';
import {utcToZonedTime, zonedTimeToUtc} from 'date-fns-tz';
import moment from 'moment-timezone';

export const FORMAT_PATTERN_DATE_PICKER = 'yyyy-MM-dd\'T\'HH:mm';

/**
 * getTimeZoneByCountryCode
 * @param {*} code
 * @return {*}
 */
export const getTimeZoneByCountryCode = (code) => {
  switch (code) {
    case '1':
      return 'Asia/Saigon';
    case '2':
      return 'Asia/Tokyo';
    case '3':
      return 'Asia/Tokyo';
    case '4':
      return 'Asia/Singapore';
    case '5':
      return 'Asia/Kuala_Lumpur';
    case '6':
      return 'Pacific/Guam';
    case '7':
      return 'Pacific/Honolulu';
    case '8':
      return 'Asia/Makassar';
    default:
      return 'Asia/Tokyo';
  }
};

/**
 * Convert date (string or Date) into string with input format
 * @param {Date|String} dateTime
 * @param {String} formatPattern
 * @param {String} timeZone
 * @return {String}
 */
export const formatUtc = (dateTime, formatPattern, timeZone) => {
  if (!dateTime) return '';
  const tz = timeZone || localStorage.getItem('timeZone') || Intl.DateTimeFormat().resolvedOptions().timeZone;
  return format(utcToZonedTime(parseISO(dateTime), tz), formatPattern);
};

/**
 * Convert date (string or Date) into string with input format
 * @param {Date|String} dateTime
 * @return {String}
 */
export const localToUtc = (dateTime) => {
  const tz = localStorage.getItem('timeZone') || Intl.DateTimeFormat().resolvedOptions().timeZone;
  return zonedTimeToUtc(dateTime, tz).toJSON();
};

/**
 * get time ago as string
 * @param {String | Date} dateTime
 * @return {String}
 */
export const getTimeAgo = (dateTime) => {
  if (!dateTime) {
    return '';
  } else {
    if (!(dateTime instanceof Date)) {
      dateTime = new Date(dateTime);
    }
  }
  if (dateTime.toString() === 'Invalid Date') return '';
  return formatDistanceToNow(dateTime, {includeSeconds: true});
};

/**
 * formatLocalToHourMinute
 * @param {*} date_Time
 * @param {*} zone
 * @return {*}
 */
export const formatLocalToHourMinute = (date_Time, zone) => {
  const dateTime = convertDateUTCAndGeofence(date_Time, zone, false);
  return `${dateTime.substr(11, 2)}:${dateTime.substr(14, 2)}`;
};

/**
 * formatUTCToHourMinute
 * @param {*} date_Time
 * @param {*} zone
 * @return {*}
 */
 export const formatUTCToHourMinute = (date_Time, zone) => {
  const dateTime = convertDateUTCAndGeofence(date_Time, zone, true);
  return ('0' + dateTime.getHours()).slice(-2) + ':' + ('0' + dateTime.getMinutes()).slice(-2);
};

/**
 * formatHourMinuteToLocal
 * @param {*} time
 * @param {*} zone_id
 * @return {*}
 */
export const formatHourMinuteToLocal = (time, zone_id) => {
  const date = new Date();
  const timezoneOffset = moment.tz(zone_id).utcOffset();
  date.setHours(Number(time.substr(0, 2)) + timezoneOffset / 60);
  date.setMinutes(Number(time.substr(3, 2)));
  return parseISO(date.toISOString());
};

/**
 * parseISOString
 * @param {string} s
 * @return {*}
 */
export const parseISOString = (s) => {
  const b = s.split(/\D+/);
  return new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5], b[6]));
};

/**
 * convert to DateTime UTC with time now
 * @param {*} dateTime
 * @return {String}
 */
export const parseDateTimeUTC = (dateTime) => {
  if (!dateTime) return null;
  dateTime = new Date(dateTime);
  dateTime.setHours(new Date().getHours());
  dateTime.setMinutes(new Date().getMinutes());
  dateTime = dateTime.toISOString().substring(0, 10);
  return dateTime;
};

/**
 * convert DateTime by timezone
 * @param {*} date
 * @param {String} tzString
 * @return {Date}
 */
export const convertByTimeZone = (date, tzString) => {
  const dateUTC = moment.tz(date, tzString);
  const offset = dateUTC.utcOffset();
  return dateUTC.add(offset / 60, 'hours').format();
};

/**
 * convert between Datetime UTC +0 to Datetime by geofence zone
 * addition: convert UTC +0 to local by geofence
 * subtraction: convert local by geofence to UTC +0
 * @param {String} time
 * @param {String} zone_id
 * @param {Boolean} isUTCtoGeofence
 * @return {Date}
 */
export const convertDateUTCAndGeofence = (time, zone_id, isUTCtoGeofence) => {
  let timezoneOffset = 0;
  if (!time) return null;
  if (zone_id) {
    timezoneOffset = moment.tz(zone_id).utcOffset();
  } else {
    timezoneOffset = 540; // to JP time
  }
  const timeOffset = new Date().getTimezoneOffset() + timezoneOffset;
  const dateTime = new Date(time);
  isUTCtoGeofence ? dateTime.setHours(dateTime.getHours() + timeOffset / 60) : dateTime.setHours(dateTime.getHours() - timeOffset / 60);
  return isUTCtoGeofence ? dateTime : new Date(dateTime).toISOString();
};

/* =================================================================== */
/**
 * convert TimeUTC to Calendar by Geofence
 * @param {Date} time
 * @param {String} zone_id
 * @return {String}
 */
export const convertTimeUTCToCalendarLocal = (time, zone_id) => {
  let timezoneOffset = 0;
  if (!time) return null;
  if (zone_id) {
    timezoneOffset = moment.tz(zone_id).utcOffset();
  } else {
    timezoneOffset = 540; // to JP time
  }
  const timeOffset = new Date().getTimezoneOffset() + timezoneOffset;
  const dateTime = new Date(time);
  dateTime.setHours(dateTime.getHours() + timeOffset / 60);
  return ('0' + dateTime.getHours()).slice(-2) + ':' + ('0' + dateTime.getMinutes()).slice(-2);
};

export const convertToDateWithTimeNow = (time) => {
  if (typeof time !== 'string') {
    const now = new Date();
    time.setHours(now.getHours());
    time.setMinutes(now.getMinutes());
  }
  return new Date(time).toISOString().substr(0, 10);
};

export const getTimeDefault = (time) => {
  const dateTime = new Date(time);
  return ('0' + dateTime.getHours()).slice(-2) + ':' + ('0' + dateTime.getMinutes()).slice(-2);
};

/**
 * Convert hour:minute to current Datetime
 * @param {String} hourMinute
 * @return {Date}
 */
export const convertHourMinuteToDate = (hourMinute) => {
  const date = new Date();
  date.setHours(Number(hourMinute.substring(0, 2)));
  date.setMinutes(Number(hourMinute.substring(3, 5)));
  return parseISO(date.toISOString());
};

/**
 * formatyyyyMMDD
 * @param {*} dateTime
 * @return {DateTime}
 */
export const formatyyyyMMDD = (dateTime) => {
  return moment(new Date(dateTime)).format('yyyy-MM-DD');
};
