import {api} from '../services/api';

const checkGeofenceUrl = 'mass/geofence-tool/check-data-swat';
const createRoutingProfileUrl = 'mass/geofence-tool/routing-profile';
const createRenewSimultionUrl = 'mass/geofence-tool/renew-simulation-daily';
const checkSimulationRenewUrl = 'mass/geofence-tool/renew-simulation-daily';

export const checkGeofenceApi = (payload) => {
  return api.post(checkGeofenceUrl, payload);
};

export const createRoutingProfileApi = (payload) => {
  return api.post(createRoutingProfileUrl, payload);
};

export const createRenewSimultionApi = (payload) => {
  return api.post(createRenewSimultionUrl, payload);
};

export const checkSimulationRenewApi = (payload) => {
  return api.post(checkSimulationRenewUrl, payload);
};
